<template>
<!--hendebanner---->
<div class="kmzp_headerbanner2" style="background-color: #ffffff;background-image:url(/images/banner_bg3.jpg);background-position:center bottom;">
    <el-row :gutter="20" class="bannerbox" style="margin-top: 16px;">
      <el-col :span="2"></el-col>
        <el-col :span="20">
            <!--------------------------->
            <el-row :gutter="20">
                <el-col :span="4"></el-col>
                <el-col :span="16">
                  <div style="margin-top:2px;margin-bottom: 2px;border-radius: 12px;background:rgba(255,255,255,0.6);padding: 12px;">{{ ctinfo }}
                      <el-row :gutter="1" style="width: 88%;margin-left: 4%;">
                     <el-col :span="22">
                        <el-input placeholder="搜索关键词"  v-model="searchkey" size="large" style="border: #e00303 0px solid;border-radius: 8px;" />
                      </el-col>
                      <el-col :span="2"><el-button color="#e00303" size="large" @click="searchsend" style="background-color: #e00303; color: #ffffff;height: 42px;">搜索</el-button></el-col>
                      </el-row>
                      </div>
                </el-col>
            </el-row>
            <!--------------------------->
            <el-row :gutter="20">
              <el-col :span="24" class="searchkey">
                    <a href="/jobs?key=会计">会计</a>
                    <a href="/jobs?key=销售">销售</a>
                    <a href="/jobs?key=设计">设计</a>
                    <a href="/jobs?key=驾驶">驾驶</a>
                    <a href="/jobs?key=文员">文员</a>
                    <a href="/jobs?key=助理">助理</a>
                    <a href="/jobs?key=行政">行政</a>
                    <a href="/jobs?key=出纳">出纳</a>
                    <a href="/jobs?key=工程">工程</a>
                    <a href="/jobs?key=营销">营销</a>
                    <a href="/jobs?key=客服">客服</a>
                    <a href="/jobs?key=计算机">计算机</a>
              </el-col>
            </el-row>
            <!--------------------------->
        </el-col>
    </el-row>
</div>
<!--hendebanner---->

<!--list---->
<div class="kmzp_content">
  <el-row :gutter="10" class="contentbox">
    <el-col :span="6">
      <h3 class="left_title1"><el-icon><Menu /></el-icon>岗位类别</h3>
      <div  class="contentboxleft">
        <el-row :gutter="6">
            <el-col :span="12" style="padding-top: 8px;" v-for="item in categorylistroot1" :key="item.categoryid">
              <el-card shadow="hover" :body-style="{ padding: '0px',height:'25px' }">
                <div class="categorylist">
                  <el-link :underline="false" :href="'jobssearch?cid='+item.categoryid" target="_blank" class="title1">{{item.categoryname}}</el-link>
                </div>
              </el-card>
            </el-col>
        </el-row>
      </div>
      <h3 class="left_title1"><el-icon><Menu /></el-icon>工作年限</h3>
      <div  class="contentboxleft">
        <el-row :gutter="6" v-if="categorylistroot1.length>0">
            <el-col :span="12" style="padding-top: 8px;" v-for="item in categorylistroot2" :key="item.categoryid">
              <el-card shadow="hover" :body-style="{ padding: '0px',height:'25px' }">
                <div class="categorylist">
                  <el-link :underline="false" @click="jyck(item.categoryname)" target="_blank" class="title1">{{item.categoryname}}</el-link>
                </div>
              </el-card>
            </el-col>
        </el-row>
      </div>
      <h3 class="left_title1"><el-icon><Menu /></el-icon>学历要求</h3>
      <div  class="contentboxleft">
        <el-row :gutter="6">
            <el-col :span="12" style="padding-top: 8px;" v-for="item in categorylistroot3" :key="item.categoryid">
              <el-card shadow="hover" :body-style="{ padding: '0px',height:'25px' }">
                <div class="categorylist">
                  <el-link :underline="false" @click="xlck(item.categoryname)" target="_blank" class="title1">{{item.categoryname}}</el-link>
                </div>
              </el-card>
            </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="18">
      <div class="listbox contentboxright">
        <div style="margin-left: 10%;width: 80%;">
        <el-button size="small" round @click="remkey('jy')" color="#FFCCCC" style="margin-bottom: 20px;margin-bottom: 20px;" v-if="jykey!=''">经验：{{ jykey }} <el-icon style="margin-left: 3px;"><CircleCloseFilled /></el-icon></el-button>
        <el-button size="small" round @click="remkey('xl')" color="#FFCCCC" style="margin-bottom: 12px;margin-bottom: 20px;" v-if="xlkey!=''">学历：{{ xlkey }} <el-icon style="margin-left: 3px;"><CircleCloseFilled /></el-icon></el-button>
        <el-button size="small" round @click="remkey('searchkey')" color="#FFCCCC" style="margin-bottom: 12px;margin-bottom: 20px;" v-if="searchkey!=''">关键词：{{ searchkey }} <el-icon style="margin-left: 3px;"><CircleCloseFilled /></el-icon></el-button>
        <el-button size="small" round @click="remkey('cdl')" color="#FFCCCC" style="margin-bottom: 12px;margin-bottom: 20px;" v-if="cids>0">大类别：{{ cids }} {{ ckeys }} <el-icon style="margin-left: 3px;"><CircleCloseFilled /></el-icon></el-button>
        <el-button size="small" round @click="remkey('cxl')" color="#FFCCCC" style="margin-bottom: 12px;margin-bottom: 20px;" v-if="czids>0">小类别： {{ czids }} {{ czkeys }} <el-icon style="margin-left: 3px;"><CircleCloseFilled /></el-icon></el-button>
      </div>
        <el-row :gutter="6" v-if="categorylistz1.length>0">
            <el-col :span="4" v-for="item in categorylistz1" :key="item.categoryid">
                <div class="categorylist">
                  <el-link :underline="false" :href="'jobssearch?czid='+item.categoryid+'&cid='+item.categoryroot" target="_blank" class="title1">{{item.categoryname}}</el-link>
                </div>
            </el-col>
            <el-col :span="24">
             <el-divider />
            </el-col>
        </el-row>

        <el-row class="el-row" gutter="12" v-for="item in joblist" :key="item.jobid" style="width:100%;text-align: left;margin-top: 18px;">
          <el-col :span="16">
          <div><el-link class="el-link" :underline="false" :href="'/jobitem?id='+item.jobid" target="_blank">{{item.jobname}}</el-link>
            <span style="margin-left: 28px;">{{ item.jobdates.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}</span>
          </div>
          <div>
             <span class="xinzi" v-if="item.jobsalarys!=''">￥{{ item.jobsalarys }}<el-divider direction="vertical" /></span>
             <span class="xinzi" v-else>￥{{ item.jobsalary }}<el-divider direction="vertical" /></span>
            <el-divider direction="vertical" />
            <span>经验:{{ item.jobexperience }}</span>
            <el-divider direction="vertical" />
            <span>{{ item.jobeducation }}</span>
            <el-divider direction="vertical" />
            <span>{{ item.jobarea }}</span><br />
            <span>{{ item.jobcategory }}</span>
            <span v-if="item.jobcategorys.length>0"> / {{ item.jobcategorys }}</span>
          </div>
          <div style="padding-top:8px;" v-if="item.jobwelfare.length>0">
            <el-button v-for="bitem in item.jobwelfare.substring(0,item.jobwelfare.length-1).split(',')" :key="bitem" text bg size="small">{{ bitem }}</el-button>
          </div>
          </el-col>
          <el-col :span="(users.usersid>0&&users.userstype==1)?5:8">
            <div>
              <el-link class="el-link2" :underline="false" :href="'/companyitem?id='+item.jobusersid" target="_blank">{{item.jobcompanyname }}</el-link>
            </div>
            <div>
            <span v-if="item.JobIndustry!=''">{{item.JobIndustry}}</span><br />
            <span v-if="item.JobCompanyType!=''">{{item.JobCompanyType}}</span>
            </div>
          </el-col>
          <el-col :span="3" v-if="users.usersid>0&&users.userstype==1">
            <div style="text-align: center;">
              <el-button  color="#333333" size="small" round style="font-size:12px; margin-bottom: 8px;" @click="jobsq(item)"><el-icon style="margin-right:2px;"><Position /></el-icon>申请</el-button><br />
              <el-button  color="#333333" size="small" round style="font-size:12px; margin-bottom: 8px;" @click="scsend(item)"><el-icon style="margin-right:2px;"><Collection /></el-icon>收藏</el-button><br />
              <el-button  color="#333333" size="small" round style="font-size:12px;" @click="chatopen(item.jobusersid,item.jobid,item.jobname)"><el-icon style="margin-right:2px;"><ChatDotRound /></el-icon>沟通</el-button>
            </div>
          </el-col>
          <el-divider />
        </el-row>
        <!-- pagers -------------------------------------------- -->
        <el-row class="el-row" gutter="12">
          <el-col :span="24">
            <el-pagination style="display: flex;justify-content: center; " small :current-page="currentpage" background :total="total" :page-size="pageSize" pager-count="5" layout="prev, next" @current-change="pagechange" prev-text="上一页" next-text="下一页"/>
          </el-col>
        </el-row>
        <!-- pagers -------------------------------------------- -->
      </div>
    </el-col>
    <!-- -------------------------------------- -->
  </el-row>
</div>
<!--list---->

<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:9999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>

import { jobsearchs, applysq, collectyonsc, getjsons, getuserprofile } from '@/api/user'
import store from '@/store/index'
import token from '@/utils/token'

export default {
  name: 'searchjobsIndex',
  components: {},
  props: {},
  data () {
    return {
      users: { usersid: 0 },
      cids: 0,
      ckeys: '',
      czids: 0,
      czkeys: '',
      jykey: '',
      xlkey: '',
      loading: false,
      resumeface: '',
      boxheight: 518,
      drawer: 'none',
      iframeSrc: '',
      searchkey: '',
      activeName: 'first',
      statusvalue: '1',
      currentpage: 1,
      joblist: [],
      total: 6000,
      pageSize: 12,
      baseurl: store.state.baseUrl,
      categorylist1: [],
      categorylistroot1: [],
      categorylistroot2: [],
      categorylistroot3: [],
      categorylistz1: []
    }
  },
  computed: {
  },
  watch: {},
  created () {
    this.$parent.$parent.hendersetshow(true)
    this.$parent.$parent.footsetshow(true)
    this.getusers()
    this.getlist()
    this.getcategorys()
  },
  mounted () {},
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.users = res.data.resultbody
          if (this.users.usersstatus > 0) {
            token.settoken('utoken', '')
            this.users = { usersid: 0 }
          }
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    getlist () {
      this.joblist = []
      jobsearchs(this.currentpage, this.pageSize, 'GJ', this.jykey, this.xlkey, this.cids, this.czkeys).then(res => {
        if (res.data.resultbody != null) {
          for (let i = 0; i < res.data.resultbody.length; i++) {
            this.joblist.push(res.data.resultbody[i])
          }
        }
      })
    },
    getcategorys () {
      getjsons('category', 1).then(res => {
        if (res.data != null) {
          this.categorylistroot1 = []
          for (let i = 0; i < res.data.length; i++) {
            this.categorylist1.push(res.data[i])
            if (res.data[i].categoryroot === 0) {
              this.categorylistroot1.push(res.data[i])
            }
          }
        }
      })
      getjsons('category', 5).then(res => {
        if (res.data != null) {
          this.categorylistroot2 = []
          for (let i = 0; i < res.data.length; i++) {
            this.categorylistroot2.push(res.data[i])
          }
        }
      })
      getjsons('category', 7).then(res => {
        if (res.data != null) {
          for (let i = 0; i < res.data.length; i++) {
            this.categorylistroot3.push(res.data[i])
          }
        }
      })
    },
    searchsend () {
      this.joblist = []
      this.currentpage = 1
      this.getlist()
    },
    pagechange (p) {
      this.currentpage = p
      this.getlist()
    },
    jobsq (item) {
      const companyid = item.jobusersid
      const jobids = item.jobid
      if (companyid > 0 && jobids > 0) {
        applysq(jobids, companyid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '::>_<:: 请选择邀请面试的岗位！',
          type: 'error'
        })
      }
    },
    scsend (item) {
      collectyonsc(item.jobid).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: ' + res.data.message + '',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    chatopen (id, jobid, jobname) {
      this.drawer = 'block'
      this.iframeSrc = '/chats?id=' + id + '&jobid=' + jobid + '&jobname=' + jobname
    },
    xlck (itemv) {
      this.xlkey = itemv
      this.currentpage = 1
      this.getlist()
    },
    jyck (itemv) {
      this.jykey = itemv
      this.currentpage = 1
      this.getlist()
    },
    remkey (st) {
      if (st === 'jy') {
        this.jykey = ''
        this.currentpage = 1
        this.getlist()
      } else if (st === 'xl') {
        this.xlkey = ''
        this.jykey = ''
        this.currentpage = 1
      } else if (st === 'searchkey') {
        this.searchkey = ''
        this.jykey = ''
        this.currentpage = 1
      } else if (st === 'cdl') {
        this.cids = 0
        this.ckeys = ''
        this.jykey = ''
        this.currentpage = 1
      } else if (st === 'cxl') {
        this.czids = 0
        this.czkeys = ''
        this.jykey = ''
        this.currentpage = 1
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
}
.bg-purple {
background-color: #ffffff;
}
.bg-purple-light {
  background-color: bisque;
}
.count_a {
  text-align: center;
  line-height: 22px;
  .el-card{
  color: #909399;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px;
  border-radius: 0px;
  margin-bottom: 6px;
  margin-right: 5px;
  .countstyle {
    font-size: 16px;
    color: #000000;
  }
  }}
.rightcontainer {
  border-left-width:1px;
  border-left-style: solid;
  border-radius: 0px;
  border-left-color:  #dddfe5;

  .rightyx{
    font-size: 13px;
    .el-tag{margin-bottom: 5px; margin-right: 3px;}
  }
}

.listbox
{
  font-size: 14px;line-height:22px;
  .idbox{margin-right: 12px;}
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
.el-link{font-size: 14px;font-weight: bold; color: #333333;}
.el-link2{font-size: 12px;font-weight:normal;color:#666666;}
.el-divider {
    margin-top:8px;
    margin-bottom: 12px;
  }
  .xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
}
</style>
