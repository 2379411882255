<template>
<!-- ----------------------------------------- -->
  <div :style="stylebox">
  <div class="regcontain">
    <div style="text-align: right;border-top-left-radius: 28px;">
        <el-badge value="App扫码登陆" v-if="login_type=='accounts'" type="info">
        <img src="/images/erweima.png" @click="logintype('code')" onmouseover="this.src='/images/erweima2.png'" onmouseout="this.src='/images/erweima.png';" style="width:42px;" />
      </el-badge>
        <el-badge value="帐号密码登陆" v-if="login_type=='code'" type="info">
        <img src="/images/zhanghao.png" @click="logintype('accounts')" onmouseover="this.src='/images/zhanghao2.png'" onmouseout="this.src='/images/zhanghao.png'" style="width:42px;" />
      </el-badge>
    </div>
    <h2 class="regtitle" v-if="login_type=='accounts'">用户登陆</h2>
    <h2 class="regtitle" v-if="login_type=='code'">扫码登陆</h2>
    <div class="regtitle1">找工作啦！快上昆明招聘网！</div>
    <div class="regbox0" v-if="login_type=='accounts'">
      <el-form label-width="68px" size="default" ref="users" label-position="top"  :model="users" :rules="rules">
        <el-form-item label="用户名" prop="usersphone">
          <el-input type="text" v-model="users.usersphone" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="密码" prop="userspassword">
          <el-input type="password" v-model="users.userspassword" placeholder="请输入密码" />
        </el-form-item>
        <el-form-item>
          <el-button color="#dc3023" style="width:100%;" :loading="loading" @click="sendlogin('users')">登陆</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="regbox" v-if="login_type=='code'" style="text-align: center;">
    <img v-if="loginpiccode.length>0" @click="getcode" :src="loginpiccode" style="width:188px;" />
    <div v-if="logininfo.length>0" style="background-color: rgba(0, 0, 0, 0.9);padding: 10px;position: absolute;color: #ffffff;margin-top: -120px;margin-left: 80px;">{{ logininfo }}</div>
    </div>
    <div class="regbox1" style="color:#666666;" v-if="login_type=='accounts'">
      <el-form-item label="">
          <div style="font-size: 16px; text-align: center;width:100%;margin-bottom: 0px;margin-top: 0px;">
          <el-checkbox v-model="checkpassword" style="margin-right: 6px;"></el-checkbox> 记住密码
          </div>
        </el-form-item>
      <a href="/reg">还没有帐号，马上注册</a><br />
      <span style="cursor: pointer;" @click="getpassworddiao=true">找回密码</span>
    </div>
    <div class="regbox1" style="font-size: 14px; color:#666666;" v-if="login_type=='code'">
      使用昆明招聘网App扫码登陆<br />
  <el-popover placement="right" :width="90" trigger="click">
      <template #reference>
        <el-button color="#888888" size="small" plain style="margin-top: 16px;">还没有安装App？</el-button>
      </template>
      <div style="font-size:12px;color:#666666;line-height: 22px;">
        1、苹果打开App store <br />搜索"昆明招聘网"安装<br />
        2、Android 扫码下载<br />
      <img src="images/pma.png" style="width:88px;margin-left:8px;" />
      </div>
    </el-popover>
    </div>
    <div class="regbox2">
        注册代表您已同意<a href="#"><span style="color:#dc3023;">「求职会员注册协议」</span></a><br />
      客服电话 0871-67165818  67287888 工作时间：9:30-18:30
    </div>
  </div>
  </div>
  <!-- 密码找回 ------------------------------------- -->
<el-dialog v-model="getpassworddiao" title="找回密码" width="418px">
    <el-form style="padding-right: 20px;">
      <el-form-item label="输入手机号码" label-width="108px">
        <el-input v-model="getpasswordphone" type="text"/>
      </el-form-item>
      <el-form-item label="" label-width="108px">
        <el-button color="#333333" @click="getpassword">提交找回密码</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import { login, getuserprofile, getsaoma, passwordget } from '@/api/user'
import token from '@/utils/token'
import { ElMessageBox } from 'element-plus'
import store from '@/store/index'

export default {
  name: 'loginIndex',
  components: {},
  props: {},
  data () {
    return {
      getpasswordphone: '',
      getpassworddiao: false,
      loading: false,
      checkpassword: false,
      users: {},
      userinfo: {},
      login_type: 'accounts',
      rules: {
        usersphone: [
          { required: true, message: '请输入用户名手机号码', trigger: 'blur' }
          // { min: 11, max: 11, message: '请输入正确的11位手机号码', trigger: 'blur' }
        ],
        userspassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '请输入6-12位密码', trigger: 'blur' }
        ]
      },
      stylebox: 'width:100%;height: 800px;background-color: #f0f0f0;text-align: center; display:flex;',
      loginpiccode: '',
      loginid: '',
      logininfo: '',
      logincontent: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getcode()
    if (this.$route.query.tk != null) {
      token.settoken('utoken', this.$route.query.tk)
      this.getusers()
    }

    this.$parent.$parent.hendersetshow(true)
    this.$parent.$parent.footsetshow(true)
    if (token.getCookie('name').length !== 'undefined') {
      if (token.getCookie('name').length > 0) {
        this.users.usersphone = token.getCookie('name')
        if (token.getCookie('password').length > 0) {
          this.users.userspassword = token.getCookie('password')
          this.checkpassword = true
        }
      }
    }
    const listheight = document.documentElement.clientHeight - 60
    this.stylebox = 'width:100%;height: ' + listheight + 'px;background-color: #f0f0f0;text-align: center; display:flex;'
  },
  mounted () {},
  methods: {
    sendlogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.checkpassword === true) {
            token.setCookie('name', this.users.usersphone)
            token.setCookie('password', this.users.userspassword)
          } else {
            token.setCookie('name', '')
            token.setCookie('password', '')
          }
          login(this.users).then(res => {
            if (res.data.status === 200) {
              token.settoken('utoken', res.data.resultbody.Token)
              this.$message({
                message: '登陆成功！',
                type: 'success'
              })
              this.loading = false
              this.getusers()
            } else {
              this.loading = false
              ElMessageBox.alert(res.data.message, '', {
                confirmButtonText: 'OK',
                icon: 'WarningFilled'
              })
            }
          }).catch(res => {
            this.loading = false
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！',
              type: 'error'
            })
          })
        } else {
          return false
        }
      })
    },
    getusers () {
      getuserprofile().then(res => {
        // alert('------>' + JSON.stringify(res.data))
        if (res.data.resultbody != null) {
          this.userinfo = res.data.resultbody
          if (this.userinfo.usersstatus > 0) {
            alert('您好！帐号锁定了！')
            token.settoken('utoken', '')
            this.$router.push('/login')
          } else {
            if (this.userinfo.userstype === 1) {
              top.location.href = '/resumes/home'
              // this.$router.push('/resumes/home')
            } else {
              // this.$router.push('/companys/home')
              top.location.href = '/companys/home'
            }
          }
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    logintype (v) {
      this.login_type = v
    },
    // 获取二维码
    getcode () {
      const currentDate = new Date()
      this.loginid = currentDate.getFullYear() + '' + currentDate.getMonth() + '' + currentDate.getDate() + '' + currentDate.getHours() + '' + currentDate.getMinutes() + '' + currentDate.getSeconds()
      this.loginpiccode = store.state.baseUrl + 'user/saoma?logint=0&loginid=' + this.loginid
      // alert('-11111--->>>' + this.loginpiccode)
      this.timer = setInterval(this.getcodeinfo, 2000)
    },
    // 定时获取登陆状态
    getcodeinfo () {
      getsaoma(this.loginid).then(res => {
        const logininfos = res.data
        // console.log(logininfos)
        // alert(logininfos)
        if (logininfos.split('|').length === 2) {
          if (logininfos.split('|')[0] === '1') {
            this.logininfo = '请稍等已扫码'
          }
          if (logininfos.split('|')[1].length > 50) {
            this.logincontent = logininfos.split('|')[1]
            token.settoken('utoken', this.logincontent)
            this.$message({
              message: '登陆成功！',
              type: 'success'
            })
            this.loading = false
            clearInterval(this.timer)
            this.getusers()
          }
        }
      })
    },
    beforeDestroy () {
      // 在组件销毁之前清除定时器
      clearInterval(this.timer)
    },
    getpassword () {
      if (this.getpasswordphone.length === 11) {
        passwordget(this.getpasswordphone).then(res => {
          if (res.data.states === 200) {
            alert('找回密码操作成功！请注意查收密码短信！')
          } else {
            alert(res.data.message)
          }
          this.getpasswordphone = ''
          this.getpassworddiao = false
        })
      } else {
        alert('请检查手机号码输入是否正确！')
      }
    }
  }
}
</script>
<style scoped lang="less">
.hender_a{
  height:60px;
  display: flex;
  justify-content: center;
  align-items: top;
  width:100%;
  background-color: #ffffff;
  margin-top: 0px;
.henderbox{border-radius: 12px;background-color: #ffffff;height:46px;line-height: 46px;margin-top: 8px;width:1288px;max-width: 1288px; text-align: left;align-items: left;}
}
.regcontain{
  background-color: #ffffff;
  width: 388px;
  border-radius: 28px;
  padding-top: 0px;
  padding-bottom: 30px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}
.regcontainc{
  background-color: #ffffff;
  width: 518px;
  border-radius: 28px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-right: 20px;
}
.regtitle{text-align: center; font-size: 25px;}
.regtitle1{text-align: center;line-height: 32px;color:#666666;}
.regbox{
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.regbox0{
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 12px;
  padding-bottom: 0px;
}
.regbox1{text-align: center; padding-bottom: 12px;
  line-height: 28px;
a{color: #333333;}
}
.regbox2{text-align: center;font-size: 12px;line-height: 22px; color: #666666;
a{text-decoration:none;}
}

</style>
