<template>
<div style="width: 100%; background-color: #ffffff;padding-top:60px;padding-bottom: 100px;" v-if="resumes.resumeid>0" >
<el-row :gutter="20" style="width:100%; max-width: 888px;margin-left: auto; margin-right: auto; background-color: #ffffff;line-height: 30px; font-size: 14px;">
  <el-col :span="24">
        <el-row style="text-align:center;padding:10px;">
            <el-col :span="18" style="background-color: #dc3023;color:#ffffff;text-align: right;padding-right: 20px;">PERSONAL RESUME</el-col>
            <el-col :span="4" style="color:#dc3023;font-size: 22px;"><b>个人简历</b></el-col>
            <el-col :span="2" style="background-color: #dc3023;"></el-col>
        </el-row>
        <el-row :gutter="30" style="text-align:center;">
            <el-col :span="5" style="text-align: center;padding-top: 12px;">
                <el-avatar shape="square" :size="98" src="https://img.bosszhipin.com/beijin/upload/avatar/20220422/607f1f3d68754fd0618b56ff9fb10366ccd62a1222b2ba82781d0c91412b6b4514bade54a84dab55_s.jpg" />
                <h3 style="margin-top: 12px;font-size: 22px;">{{ resumes.resumename }}</h3>
            </el-col>
            <el-col :span="15" class="exitem">
              <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;margin-top: 20px;">
                <el-col :span="4" style="text-align: right; ">年龄：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{ getAges(resumes.resumebirthday) }}</b></el-col>
                <el-col :span="4" style="text-align: right;">性别：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumegender}}</b></el-col>
                </el-row>
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;">
                <el-col :span="4" style="text-align: right;">学校：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumeschool}}</b></el-col>
                <el-col :span="4" style="text-align: right;">专业：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumespecialty}}</b></el-col>
                </el-row>
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;">
                <el-col :span="4" style="text-align: right;">经验：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumeworkage}}</b></el-col>
                <el-col :span="4" style="text-align: right;">居住地：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumearea}}</b></el-col>
                </el-row>
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;margin-top: 12px;">
                    <el-col :span="2"></el-col>
                    <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Phone /></el-icon></el-col>
                    <el-col :span="6" style="text-align: left;"><b>{{resumes.resumeusersphone}}</b></el-col>
                    <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Message /></el-icon></el-col>
                    <el-col :span="8" style="text-align: left;"><b>{{resumes.resumemail}}</b></el-col>
                </el-row>
            </el-col>
            <el-col :span="4">
                <div style="text-align: right;font-size: 12px;line-height: 13px;padding-right: 20px;color:#888888;">
                    <img src="/images/pma.png" style="width:52px;height:52px" />
                    <br />扫码查看
                </div>
            </el-col>
        </el-row>
    </el-col>
    <el-col :span="24">
        <el-row :gutter="30" style="text-align:center;padding:20px;">
        <!--求职意向----------------------------->
        <el-col :span="24" style="text-align: left;">
            <h2><img src="/images/yixiang.png" style="width:18px; margin-right: 12px;" />求职意向</h2>
            <el-divider style="margin-top: 2px;" />
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">求职类型：</el-col>
                <el-col :span="21" style="text-align: left;"><b>{{resumes.resumejobtype}}</b></el-col>
            </el-row>
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">期望行业：</el-col>
                <el-col :span="21" style="text-align: left;">
                    <b style="margin-right: 20px;" v-for="citem in resumeindustry" :key="citem.categoryid">{{citem.categoryname}}</b>
                </el-col>
            </el-row>
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">期望职位：</el-col>
                <el-col :span="21" style="text-align: left;">
                    <b style="margin-right: 20px;" v-for="citem in resumejobposition" :key="citem.categoryid">{{citem.categoryname}}</b>
                </el-col>
            </el-row>
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">工作地区：</el-col>
                <el-col :span="21" style="text-align: left;">
                    <b style="margin-right: 20px;" v-for="citem in resumejobcity" :key="citem.categoryid">{{citem.categoryname}}</b>
                </el-col>
            </el-row>
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">期望薪资：</el-col>
                <el-col :span="21" style="text-align: left;">
                    <b style="margin-right: 20px;">{{resumes.resumesalary}}</b>
                </el-col>
            </el-row>
        </el-col>
        <!--个人优势----------------------------->
        <el-col :span="24" style="text-align: left;">
                <h2><img src="/images/youshi1.png" style="width:18px; margin-right: 12px;" />个人优势</h2>
                <el-divider style="color: #ffffff; margin-top: 2px;" />
                <div style="text-indent:2em;">{{ resumes.resumepersonaladvantages }}</div>
            </el-col>
        <!--工作经历----------------------------->
        <el-col :span="24" style="text-align: left;">
            <h2><img src="/images/gongzuojingli.png" style="width:18px; margin-right: 12px;" />工作经历</h2>
            <el-divider style="margin-top: 2px;" />
            <el-row :gutter="20" v-for="item in resumeworkexperience" :key="item.workexid">
                  <el-col :span="1"></el-col>
                  <el-col :span="22">
                    <div class="exitem">时间：<b>{{ item.workextime }}</b></div>
                    <div class="exitem">行业：<b>{{ item.workextrade }}</b></div>
                    <div class="exitem">公司：<b>{{ item.workexinc }}</b></div>
                    <div class="exitem">部门：<b>{{ item.workexdepartment }}</b></div>
                    <div class="exitem">职位：<b>{{ item.workexposition }}</b></div>
                    <div class="exitem">说明：<b>{{ item.workexcontent }}</b></div>
                  </el-col>
            </el-row>
        </el-col>
        <!--教育经历----------------------------->
        <el-col :span="24" style="text-align: left;">
            <h2><img src="/images/jiaoyujingli.png" style="width:18px; margin-right: 12px;" />教育经历</h2>
            <el-divider style="margin-top: 2px;" />
            <el-row :gutter="20" v-for="item in resumeexperienceeducation" :key="item.educateexid">
                  <el-col :span="1"></el-col>
                  <el-col :span="22">
                    <div class="exitem">时间：<b>{{ item.educateextime }}</b></div>
                    <div class="exitem">学校：<b>{{ item.educateexschool }}</b></div>
                    <div class="exitem">学历：<b>{{ item.educateexeducation }}</b></div>
                    <div class="exitem">专业：<b>{{ item.educateexfield }}</b></div>
                    <div class="exitem">说明：<b>{{ item.educateexcontent }}</b></div>
                  </el-col>
            </el-row>
        </el-col>
        </el-row>
    </el-col>
    <el-col :span="24" style="text-align: right;font-size: 12px; color: #888888;">
    找工作啦就上昆明招出网！www.kmzp.com
    </el-col>
</el-row>
</div>
<div v-if="resumes.resumeid==0" style="text-align: center; padding: 260px; font-size: 14px; line-height:42px; color: #333333;">
  <img src="/images/4043.png" style="height:72px;" />
  <br /> 求职者可能屏蔽了简历 <a href="/" style="font-size: 16px; color: red;text-decoration:none;">返回</a> 再找找看
</div>
</template>
<script>

import { getuserprofile, rgetresume } from '@/api/user'
import token from '@/utils/token'
export default {
  name: 'printIndex',
  components: {
  },
  props: {},
  data () {
    return {
      users: {},
      resumes: { resumeid: 0 },
      resumejobcity: [],
      resumejobposition: [],
      resumeindustry: [],
      resumeworkexperience: [],
      resumeexperienceeducation: []
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getusers()
  },
  mounted () {},
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.users = res.data.resultbody
          if (this.users.usersstatus > 0) {
            alert('您好！帐号锁定了！')
            token.settoken('utoken', '')
            this.$router.push('/login')
          }
          this.getresumes()
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    getresumes () {
      rgetresume().then(res => {
        if (res.data.status === 200) {
          this.resumes = res.data.resultbody
          if (this.resumes.resumejobcity.length > 0) {
            this.resumejobcity = JSON.parse(this.resumes.resumejobcity.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resumes.resumejobposition.length > 0) {
            this.resumejobposition = JSON.parse(this.resumes.resumejobposition.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resumes.resumeindustry.length > 0) {
            this.resumeindustry = JSON.parse(this.resumes.resumeindustry.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resumes.resumeworkexperience.length > 2) {
            this.resumeworkexperience = JSON.parse(this.resumes.resumeworkexperience.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resumes.resumeexperienceeducation.length > 2) {
            this.resumeexperienceeducation = JSON.parse(this.resumes.resumeexperienceeducation.replace(/\n/g, '').replace(/\r/g, ''))
          }
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    getAges (val) {
      let valinfo = ''
      if (val !== undefined) {
        let vy = '2006'
        if (val.split('-')[0] != null) {
          vy = val.split('-')[0]
        }

        let vm = '01'
        if (val.split('-')[1] != null) {
          if (vm.length < 2) {
            vm = '0' + vm
          }
        }
        let vd = '01'
        if (val.split('-')[2] != null) {
          if (vd.length < 2) {
            vd = '0' + vd
          }
        }
        valinfo = vy + '-' + vm + '-' + vd
        const currentYear = new Date().getFullYear() // 当前的年份
        const calculationYear = new Date(valinfo).getFullYear() // 计算的年份
        const wholeTime = currentYear + valinfo.substring(4) // 周岁时间
        const calculationAge = currentYear - calculationYear // 按照年份计算的年龄
        // 判断是否过了生日
        if (new Date().getTime() > new Date(wholeTime).getTime()) {
          return calculationAge + '岁'
        } else {
          return calculationAge - 1 + '岁'
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
h2{margin-top: 30px;margin-bottom: 6px;}
.exitem{color: #888888;
b{color:#333333;font-weight: normal;}
}
</style>
