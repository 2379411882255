function isJsonString (str) {
  try {
    JSON.parse(str.replace(/\n/g, '').replace(/\r/g, ''))
  } catch (e) {
    return false
  }
  return true
}

export default {
  isJsonString
}
