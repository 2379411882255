
function settoken (key, value) {
  localStorage.setItem(key, value)
}
function gettoken (key) {
  return localStorage.getItem(key)
}

// 获取cookie
function getCookie (key) {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(key + '=')
    if (start !== -1) {
      start = start + key.length + 1
      let end = document.cookie.indexOf(';', start)
      if (end === -1) end = document.cookie.length
      return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}
// 保存cookie
function setCookie (cName, value, expiredays) {
  const exdate = new Date()
  exdate.setDate(exdate.getDate() + expiredays)
  document.cookie = cName + '=' + decodeURIComponent(value) +
  ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
}

export default {
  settoken,
  gettoken,
  getCookie,
  setCookie
}
