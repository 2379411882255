<template>
<!--hendebanner---->
<div class="kmzp_headerbanner2" style="background-color: #ffffff;background-image:url(/images/banner_bg3.jpg);background-position:center bottom;">
    <el-row :gutter="2" class="bannerbox" style="margin-top: 28px;">
      <el-col :span="2"></el-col>
        <el-col :span="20">
            <!--------------------------->
            <el-row :gutter="2">
                <el-col :span="4"></el-col>
                <el-col :span="16">
                    <div style="margin-top:2px;margin-bottom: 2px;border-radius: 12px;background:rgba(255,255,255,0.8);padding: 12px;">
                      <el-row :gutter="1" style="width: 88%;margin-left: 4%;">
                      <!-- <el-col :span="6" style="display: none;"><el-cascader clearable size="large" v-model="ctinfo" :options="lblistinfo" placeholder="选择类别" style="border-top-right-radius: 0px;" /></el-col> -->
                      <el-col :span="22">
                        <el-input placeholder="搜索关键词"  v-model="searchkey" size="large" style="border: #e00303 0px solid;border-radius: 8px;" />
                      </el-col>
                      <el-col :span="2"><el-button color="#e00303" size="large" @click="searchsend" style="background-color: #e00303; color: #ffffff;height: 42px;">搜索</el-button></el-col>
                      </el-row>
                      </div>
                </el-col>
            </el-row>
            <!--------------------------->
            <el-row :gutter="20">
                <el-col :span="24" class="searchkey">
                    <a href="/resumessearch?key=会计">会计</a>
                    <a href="/resumessearch?key=销售">销售</a>
                    <a href="/resumessearch?key=设计">设计</a>
                    <a href="/resumessearch?key=行政">行政</a>
                    <a href="/resumessearch?key=出纳">出纳</a>
                    <a href="/resumessearch?key=客服">客服</a>
                    <a href="/resumessearch?key=计算机">计算机</a>
                    <a href="/resumessearch?key=驾驶员">驾驶员</a>
                </el-col>
            </el-row>
            <!--------------------------->
        </el-col>
    </el-row>
</div>
<!--hendebanner---->

  <!--list---->
  <div class="kmzp_content">
    <el-row :gutter="30" class="contentbox">
      <el-col :span="24" style="padding-left: 42px;">
            <div label="简历搜索" name="first" class="resumelist contentboxright">
      <div style="margin-left: 10%;width: 80%;">
        <el-button size="small" round @click="remkey('lb')" color="#FFCCCC" style="margin-bottom: 22px;margin-top: 22px;" v-if="ctinfo!=''">类别：{{ ctinfo[1] }} <el-icon style="margin-left: 3px;"><CircleCloseFilled /></el-icon></el-button>
        <el-button size="small" round @click="remkey('gjc')" color="#FFCCCC" style="margin-bottom: 22px;margin-top: 22px;" v-if="searchkey!=''">关键词：{{ searchkey }} <el-icon style="margin-left: 3px;"><CircleCloseFilled /></el-icon></el-button>
      </div>
      <div v-for="item in resumelist" :key="item.resumeid">
                <el-row gutter="12"  style="width:98%;margin-left: 1%;margin-top: 6px;" v-if="Number(getAge(item.resumebirthday).replace('岁',''))>10">
                  <el-col :span="2" class="resumecompanylogo">
                    <span :class="item.resumegender==='男'?'resumegendera':'resumegenderb'">{{ item.resumegender }}</span>
                      <img v-if="users.usersid==0" :class="item.resumegender==='男'?'bordera':'borderb'" :src="'/images/avatar1.png'" onerror="this.src='/images/avatar1.png'" />
                      <img v-else :class="item.resumegender==='男'?'bordera':'borderb'" :src="baseurl+'static/resume/'+item.resumeusersid+'/photo/'+item.resumeusersid+'.jpg'" onerror="this.src='/images/avatar1.png'" />
                  </el-col>
                  <el-col :span="(users.usersid>0&&users.userstype==2)?19:22" class="resumeinfo">
                  <div>
                    <el-link :underline="false" :href="'/resumeitem?id='+item.resumeusersid" target="_blank" v-if="users.usersid==0">{{item.resumename.charAt(0)}}***</el-link>
                    <el-link :underline="false" :href="'/resumeitem?id='+item.resumeusersid" target="_blank" v-else>{{item.resumename}}</el-link>
                      <span style="margin-left: 20px;">{{ getAge(item.resumebirthday) }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeworkage!=''">{{ item.resumeworkage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschool!=''">{{ item.resumeschool }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumespecialty!=''">{{item.resumespecialty}}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschoolage!=''">{{ item.resumeschoolage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumesalary!=''" style="color: crimson;">{{ item.resumesalary }}</span>
                      <span style="margin-left: 12px; margin-right: 12px;">{{ item.resumelogintime.replace(/T/g,' ').replace(/Z/g,' ').substring(0,10) }}</span>
                  </div>
                  <div>
                    <span v-if="item.resumeindustry.length>20&&isJsonString(item.resumeindustry)" style="display: none;">期望行业：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumeindustry.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumejobposition.length>20&&isJsonString(item.resumejobposition)">期望岗位：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobposition.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><el-tag type="info" color="#ffffff" v-if="item.resumejobpositions!=''" >{{ item.resumejobpositions }}</el-tag><br /></span>
                    <span v-else>期望岗位：<el-tag type="info" color="#ffffff" v-if="item.resumejobpositions!=''" >{{ item.resumejobpositions }}</el-tag><br /></span>
                    <span v-if="item.resumejobcity.length>20&&isJsonString(item.resumejobcity)">期望地区：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobcity.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumepersonaladvantages!=''" style="display: none;">特长优势：
                      <span class="infovv" v-if="item.resumepersonaladvantages.length<80">{{ item.resumepersonaladvantages }}</span>
                      <span class="infovv" v-else>{{ item.resumepersonaladvantages.substring(0,80) }}...</span>
                    </span>
                  </div>
                  </el-col>
                  <el-col :span="3" v-if="users.usersid>0&&users.userstype==2">
                    <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqedit(item)"><el-icon style="margin-right:6px;"><Position /></el-icon>邀请</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px;margin-top: 12px;" @click="scsend(item)"><el-icon style="margin-right:6px;"><Collection /></el-icon>收藏</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px; margin-top: 12px;" @click="chatopen(item.resumeusersid)"><el-icon style="margin-right:6px;"><ChatDotRound /></el-icon>沟通</el-button>
                  </el-col>
                  <el-divider style="margin-top: 2px;margin-bottom: 2px;" />
                </el-row>
                </div>
                <div v-if="resumelist.length==0" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><el-icon><WarningFilled /></el-icon><br />没有了...</div>
                <!-- pagers -------------------------------------------- -->
                <el-row class="el-row" gutter="12">
                  <el-col :span="24">
                    <el-pagination style="display: flex;justify-content: center; " small :current-page="currentpage" background :total="total" :page-size="pageSize" pager-count="5" layout="prev, next" @current-change="pagechange" prev-text="上一页" next-text="下一页"/>
                    <!-- <el-pagination small :current-page="currentpage" background :total="total" :page-size="pageSize" pager-count="5" layout="prev, pager, next" @current-change="pagechange"/> -->
                  </el-col>
                </el-row>
                <!-- pagers -------------------------------------------- -->
              </div>
      </el-col>
    </el-row>
  </div>

<!-- -------------------------------------- -->
<el-dialog v-model="yq_dialogVisible" title="邀请面试" width="418px">
      <el-row gutter="60">
        <el-col :span="24">
          <el-form-item label="" style="width: 100%;">
            <el-select  v-model="yq_job" placeholder="选择邀请面试岗位" style="width: 100%;">
                <el-option v-for="item in joblist" :key="item.jobid" :value="item.jobname">{{item.jobname}}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div style="text-align: right;padding-top: 18px;padding-bottom: 18px;">
            <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqsend()"><el-icon style="margin-right:6px;"><Position /></el-icon>发送邀请</el-button>
          </div>
        </el-col>
      </el-row>
  </el-dialog>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;">关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>
import { resumesearchs, inviteyq, collectyonsc, getjsons, cjobmanage, getuserprofile } from '@/api/user'
import store from '@/store/index'
import timehelp from '@/utils/timehelp'
import token from '@/utils/token'
export default {
  name: 'searchresumeIndex',
  components: {},
  data () {
    return {
      users: { usersid: 0 },
      ctinfo: '',
      drawer: 'none',
      yq_dialogVisible: false,
      yq_item: {},
      yq_job: '选择邀请面试岗位',
      loading: false,
      searchkey: '',
      baseurl: store.state.baseUrl,
      activeName: 'first',
      total: 6000,
      currentpage: 1,
      pageSize: 12,
      resumelist: [],
      joblist: [],
      categorylist1: [],
      lblistinfo: []
    }
  },
  computed: {
  },
  created () {
    this.$parent.$parent.hendersetshow(true)
    this.$parent.$parent.footsetshow(true)
    const searchkeyinfo = this.$route.query.key
    if (searchkeyinfo !== undefined) {
      this.searchkey = searchkeyinfo
    }
    this.getusers()
    this.getlist()
  },
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.users = res.data.resultbody
          if (this.users.usersstatus > 0) {
            token.settoken('utoken', '')
            this.users = { usersid: 0 }
          }
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    getlist () {
      this.resumelist = []
      resumesearchs(this.currentpage, this.pageSize, this.searchkey, this.ctinfo).then(res => {
        if (res.data.resultbody != null) {
          for (let i = 0; i < res.data.resultbody.length; i++) {
            const iteminfo = res.data.resultbody[i]
            if (iteminfo.resumejobpositions.length > 0) {
              this.resumelist.push(iteminfo)
            }
          }
        }
        this.getcategorys()
      })
    },
    getcategorys () {
      this.categorylist = []
      this.categorylistz1 = []
      this.categorylistroot1 = []
      getjsons('category', 1).then(res => {
        if (res.data != null) {
          for (let i = 0; i < res.data.length; i++) {
            const citem = res.data[i]
            if (citem.categoryroot === 0) {
              const ccitem = { value: citem.categoryname, label: citem.categoryname, children: [] }
              for (let ii = 0; ii < res.data.length; ii++) {
                const cccitem = res.data[ii]
                if (cccitem.categoryroot === citem.categoryid) {
                  const ccccitem = { value: cccitem.categoryname, label: cccitem.categoryname, children: [] }
                  ccitem.children.push(ccccitem)
                }
              }
              this.lblistinfo.push(ccitem)
            }
          }
        }
      })
    },
    getAge (vv) {
      return timehelp.getAge(vv)
    },
    searchsend () {
      this.resumelist = []
      this.currentpage = 1
      this.getlist()
    },
    yqedit (item) {
      this.yq_dialogVisible = true
      this.yq_item = item
      this.getjoblist()
    },
    yqsend () {
      const resumeid = this.yq_item.resumeusersid
      let jobid = 0
      this.getjoblist()
      for (let i = 0; i < this.joblist.length; i++) {
        if (this.joblist[i].jobname === this.yq_job) {
          jobid = this.joblist[i].jobid
        }
      }
      if (resumeid > 0 && jobid > 0) {
        inviteyq(jobid, resumeid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
            this.yq_job = '选择邀请面试岗位'
            this.yq_dialogVisible = false
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '::>_<:: 请选择邀请面试的岗位！',
          type: 'error'
        })
      }
    },
    getjoblist () {
      if (this.users.usersid > 0) {
        cjobmanage(0, 1, '', 50, 0).then(res => {
          if (res.data.resultbody.resultlist != null) {
            this.joblist = res.data.resultbody.resultlist
          }
        })
      }
    },
    scsend (item) {
      collectyonsc(item.resumeusersid).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: ' + res.data.message + '',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    chatopen (id) {
      this.drawer = true
      this.iframeSrc = '/chats?id=' + id
    },
    isJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    pagechange (p) {
      this.currentpage = p
      this.getlist()
    },
    remkey (ct) {
      if (ct === 'lb') {
        this.ctinfo = ''
      } else if (ct === 'gjc') {
        this.searchkey = ''
      }
      this.getlist()
    }
  }
}
</script>

<style lang="less" scoped>
.resumegendera{position:absolute;top:2px;left:52px;width:16px;height: 16px;background-color: #003399; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.resumegenderb{position:absolute;top:2px;left:52px;width:16px;height: 16px;background-color: #FF0066; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.bordera{border-width: 2px;border-color: #003399; border-style: solid;width:48px;height:48px;border-radius: 48px;}
.borderb{border-width: 2px;border-color: #FF0066; border-style: solid;width:48px;height:48px;border-radius: 48px;}
.titlea{
  text-align: left;padding-left: 12px;font-size: 16px;margin-top: 2px; margin-bottom: 12px;
}

.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
}
.bg-purple {
background-color: #ffffff;
}
.bg-purple-light {
  background-color: bisque;
}
.count_a {
  text-align: center;
  line-height: 22px;
  .el-card{
  color: #909399;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px;
  border-radius: 0px;
  margin-bottom: 6px;
  margin-right: 5px;
  .countstyle {
    font-size: 16px;
    color: #000000;
  }
  }

  .infostyle{
    font-size: 14px;
    color:#909399;
  }
  .infostylevv{font-size: 14px; color:#333333;}
}
.rightcontainer {
  border-left-width:1px;
  border-left-style: solid;
  border-radius: 0px;
  border-left-color:  #dddfe5;
}
.resumeinfo {
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;}
}
.resumecompany {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
  color: #888888;
  .el-link {
    font-size: 14px;
    color: #333333;
  }
}
.resumecompanylogo {
  text-align: left;
  margin-top: 6px;
}
.resumelist
{
  .el-row{ margin-bottom: 8px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
}
.rightyx {
  margin-left: 12px;
  margin-right:12px;
  text-align: left;
  font-size: 14px;
  color: #777777;
  line-height: 28px;
  .el-row {
    border-bottom-width:1px;
    border-bottom-style: solid;
    border-bottom-color:  #dddfe5;
    padding-top: 2px;
    padding-bottom: 8px;
  }
  .el-tab-pane {
    padding-left: 20px;
    .el-tag { margin-right: 6px; color:#222222;}
  }
}
</style>
