<template>
  <h2 class="regtitle1"></h2>
  <div class="readingcontainer" v-loading="loadingmain">
    <el-row :gutter="30">
      <el-col :span="24" style="padding-left: 42px;">
          <el-tabs v-model="activeName" type="card"  @tab-click="tabsclick">
            <el-tab-pane label="查看记录" name="first" class="retainlist">

<!-- pagers -------------------------------------------- -->
<el-row gutter="2" style="margin-bottom: 8px;">
  <el-col :span="18">
    <div style="text-align:left; ">
    </div>
  </el-col>
  <el-col :span="6">
    <div style="text-align:right;">
      <el-pagination style="float: right;" small :current-page="currentpage" background :total="total" :page-size="12" pager-count="5" layout="prev, pager, next" @current-change="pagechange"/>
    </div>
  </el-col>
</el-row>
<!-- pagers -------------------------------------------- -->
              <!-- -------------------------------------------- -->
              <div class="scrollbarbox" :style="'height:'+(boxheight-242)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;">
              <el-row gutter="12" v-for="item,index in listinfo" :key="index">
                <el-col :span="3" class="retaincompanylogo" v-if="item.readingresume!=''">
                    <span :class="JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumegender==='男'?'resumegendera':'resumegenderb'">{{ JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumegender }}</span>
                    <img :class="JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumegender==='男'?'bordera':'borderb'" :src="baseurl+'static/resume/'+JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumeusersid+'/photo/'+JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumeusersid+'.jpg'" onerror="this.src='/images/avatar1.png'" />
                </el-col>
                  <el-col :span="18" class="retaininfo" v-if="item.readingresume!=''">
                    <div v-if="item.readingresume!=''">
                      <el-link v-if="JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumejobstatus>-1" :underline="false" :href="'/resumeitem?id='+item.readingresumeid" target="_blank">{{JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumename}}</el-link>
                      <el-link v-else :underline="false" style="color: red;">{{JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumename}}=======> 已屏蔽</el-link>
                      <apan style="margin-left: 20px;">查看时间：{{ item.readingdate.replace("T"," ").replace("Z","").substring(0,10) }}</apan>
                    </div>

                  <div v-if="item.readingresume!=''">
                    <span class="infovv">
                      <span>{{ getAge(JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumebirthday) }}<el-divider direction="vertical" /></span>
                      <span v-if="JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumeworkage!=''">{{ JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumeworkage }}<el-divider direction="vertical" /></span>
                      <span v-if="JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumeschool!=''">{{ JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumeschool }}<el-divider direction="vertical" /></span>
                      <span v-if="JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumespecialty!=''">{{JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumespecialty}}<el-divider direction="vertical" /></span>
                      <span v-if="JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumeschoolage!=''">{{ JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumeschoolage }}<el-divider direction="vertical" /></span>
                      <span v-if="JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumesalary!=''" style="color: crimson;">{{ JSON.parse(item.readingresume.replace(/\n/g, '').replace(/\r/g, '')).resumesalary }}</span>
                    </span>
                  </div>
                </el-col>
                  <el-col :span="3" v-if="item.readingresume!=''">
                    <el-button  color="#000000" size="small" round style="font-size:12px; margin-top: 12px;" @click="chatopen(item.readingresumeid)"><el-icon style="margin-right:6px;"><ChatDotRound /></el-icon>沟通</el-button>
                  </el-col>
                  <el-col :span="24" v-if="getlogitem(item.readingresumeid)!=undefined" style="padding: 12px;background-color: #f8f8f8;border-radius: 12px;">
                    <h5 style="margin-bottom: 2px;margin-top: 2px;padding: 2px;">备注：</h5>
                   <div v-for="litem in getlogitem(item.readingresumeid)" :key="litem" style="font-size: 12px;padding-left: 38px;line-height: 22px;">
                    {{ litem.rlogdate }}：{{ litem.rlogcontent }}
                  </div>
                  </el-col>
                  <el-divider />
              </el-row>
              </div>
              </div>
              <!-- -------------------------------------------- -->
        </el-tab-pane>
      </el-tabs>
    </el-col>
    </el-row>
  </div>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>
import { rreadings, resumelog } from '@/api/user'
import timehelp from '@/utils/timehelp'
export default {
  name: 'readingIndex',
  components: {},
  data () {
    return {
      loadingmain: true,
      boxheight: 518,
      drawer: 'none',
      iframeSrc: '',
      activeName: 'first',
      total: 0,
      currentpage: 1,
      listinfo: [],
      resumeloglist: []
    }
  },
  created () {
    this.boxheight = document.documentElement.clientHeight
    this.getlist(this.currentpage)
  },
  methods: {
    getresumelog (rid) {
      let resumelogs = []
      resumelog(rid, 0, null).then(res => {
        if (res.data.resultbody != null) {
          resumelogs = res.data.resultbody
          const itemlogs = '{"rid":' + rid + ', "loginfo":' + JSON.stringify(resumelogs) + '}'
          this.resumeloglist.push(itemlogs)
        }
      })
    },
    getlogitem (rids) {
      for (let i = 0; i < this.resumeloglist.length; i++) {
        if (this.resumeloglist[i].length > 10) {
          const iiitem = JSON.parse(this.resumeloglist[i].replace(/\n/g, '').replace(/\r/g, ''))
          if (iiitem.rid === rids) {
            return iiitem.loginfo
          }
        }
      }
    },
    getlist () {
      this.loadingmain = true
      rreadings(this.currentpage, 0).then(res => {
        if (res.data.resultbody.resultlist != null) {
          if (this.currentpage === 1) {
            this.total = res.data.resultbody.total
          }
          this.listinfo = res.data.resultbody.resultlist
          for (let i = 0; i < this.listinfo.length; i++) {
            this.getresumelog(this.listinfo[i].readingresumeid)
          }
        }
        setTimeout(() => { this.loadingmain = false }, 300)
      })
    },
    pagechange (p) {
      if (this.page !== p) {
        this.currentpage = p
        this.getlist()
      }
    },
    getAge (vv) {
      return timehelp.getAge(vv)
    },
    chatopen (id) {
      this.drawer = true
      this.iframeSrc = '/chats?id=' + id
    }
  }
}
</script>

<style lang="less" scoped>

.pidbox{margin-right: 8px;}
.resumegendera{position:absolute;top:2px;left:62px;width:16px;height: 16px;background-color: #003399; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.resumegenderb{position:absolute;top:2px;left:62px;width:16px;height: 16px;background-color: #FF0066; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.bordera{border-width: 2px;border-color: #003399; border-style: solid;width:58px;height:58px;border-radius: 58px;}
.borderb{border-width: 2px;border-color: #FF0066; border-style: solid;width:58px;height:58px;border-radius: 58px;}
.titlea{
  text-align: left;padding-left: 12px;font-size: 16px;margin-top: 2px; margin-bottom: 2px;
}
.readingcontainer {
  margin-top: 20px;
  border-radius: 0px;
}

.tg{
  height:52px;
  width:52px;
  // font-size: 12px;
  border-width: 0px;margin-right: 20px;
  text-align: center;line-height:14px;
}
.retaincompany {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
  color: #888888;
  .el-link {
    font-size: 14px;
    color: #333333;
  }
}
.resumecompanylogo {
  text-align: left;
  margin-top: 6px;
}
.retainlist
{
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.retaininfo {
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;}
}
.rightyx {
  margin-left: 12px;
  margin-right:12px;
  text-align: left;
  font-size: 14px;
  color: #777777;
  line-height: 28px;
  .el-row {
    border-bottom-width:1px;
    border-bottom-style: solid;
    border-bottom-color:  #dddfe5;
    padding-top: 2px;
    padding-bottom: 8px;
  }
  .el-tab-pane {
    padding-left: 20px;
    .el-tag { margin-right: 6px; color:#222222;}
  }
}
</style>
