<template>
  <div class="maincontain">
    <el-container>
      <el-aside width="Auto" class="asidecontainer">
        <el-menu default-active="/" :collapse="isCollapse" @open="handleOpen" @close="handleClose" style="height:92%;min-height: 528px;" router class="el-menu" background-color="#ffffff" >
          <el-menu-item index="/" class="asidelogo" style="display: none;">
              <el-avatar shape="square" size="60" :fit="none" src="/images/logo.png" />
          </el-menu-item>
          <!-- resume ------------------------------------- -->
          <div v-if="users.userstype==1" class="reel-menu-item" style="min-height:468px;margin-top: 20px;">
          <el-menu-item index="/resumes/home">
            <el-icon><HomeFilled /></el-icon>
            <template #title>管理首页</template>
          </el-menu-item>
          <el-menu-item index="/resume">
            <el-icon><User /></el-icon>
            <template #title>简历管理</template>
          </el-menu-item>
          <el-menu-item index="/apply">
            <el-icon><Position /></el-icon>
            <template #title>应聘记录</template>
          </el-menu-item>
          <el-menu-item index="/invite">
            <el-icon><CoffeeCup /></el-icon>
            <template #title>面试邀请</template>
          </el-menu-item>
          <el-menu-item index="/collection">
            <el-icon><Collection /></el-icon>
            <template #title>收藏管理</template>
          </el-menu-item>
          <el-menu-item index="/reading">
            <el-icon><View /></el-icon>
            <template #title>查看记录</template>
          </el-menu-item>
          <el-menu-item index="/readingme">
            <el-icon><Star /></el-icon>
            <template #title>谁看过我</template>
          </el-menu-item>
          <el-link href="/chats" target="_blank" :underline="false">
          <el-badge :value="chatscount" v-if="chatscount==0?hidden=false:hidden=true">
          <el-menu-item style="color: #ba181b;margin-right: 0px;">
            <el-icon><img src="/images/goutong-2.png" style="width:22px;height:22px;" /></el-icon>
            <template #title>在线沟通</template>
          </el-menu-item>
          </el-badge>
          <el-menu-item  v-if="chatscount==0" >
            <el-icon><img src="/images/goutong.png" style="width:22px;height:22px;" /></el-icon>
            <template #title>在线沟通</template>
          </el-menu-item>
          </el-link>
          </div>
          <!-- company ------------------------------------- -->
          <div v-if="users.userstype==2" style="min-height:568px;margin-top: 20px;">
          <el-menu-item index="/companys/home">
            <el-icon><HomeFilled /></el-icon>
            <template #title>管理首页</template>
          </el-menu-item>
          <el-menu-item index="/companys/company">
            <el-icon><OfficeBuilding /></el-icon>
            <template #title>企业信息</template>
          </el-menu-item>
          <h3>招聘管理</h3>
          <el-menu-item index="/companys/jobs">
            <el-icon><CoffeeCup /></el-icon>
            <template #title>职位管理</template>
          </el-menu-item>
          <el-menu-item index="/companys/jobse?edit=true" style="color: #ba181b;">
            <el-icon><CirclePlus /></el-icon>
            <template #title>发布职位</template>
          </el-menu-item>
          <el-menu-item index="/companys/apply">
            <el-icon><Lollipop /></el-icon>
            <template #title>收到简历</template>
          </el-menu-item>
          <el-menu-item index="/companys/invite">
            <el-icon><Position /></el-icon>
            <template #title>邀请记录</template>
          </el-menu-item>
          <el-menu-item index="/companys/collection">
            <el-icon><Collection /></el-icon>
            <template #title>收藏简历</template>
          </el-menu-item>
          <el-menu-item index="/companys/reading">
            <el-icon><View /></el-icon>
            <template #title>查看记录</template>
          </el-menu-item>
          <el-menu-item index="/companys/readingme">
            <el-icon><Star /></el-icon>
            <template #title>谁看过我</template>
          </el-menu-item>
          <el-link href="/chats" target="_blank" :underline="false">
          <el-badge :value="chatscount" v-if="chatscount==0?hidden=false:hidden=true">
          <el-menu-item style="color: #ba181b;margin-right: 0px;">
            <el-icon><img src="/images/goutong-2.png" style="width:22px;height:22px;" /></el-icon>
            <template #title>在线沟通</template>
          </el-menu-item>
          </el-badge>
          <el-menu-item  v-if="chatscount==0" >
            <el-icon><img src="/images/goutong.png" style="width:22px;height:22px;" /></el-icon>
            <template #title>在线沟通</template>
          </el-menu-item>
          </el-link>
          <h3>服务管理</h3>
          <el-menu-item index="/companys/accounts">
            <el-icon><User /></el-icon>
            <template #title>帐号管理</template>
          </el-menu-item>
          <el-menu-item index="/companys/advertisement">
            <el-icon><Orange /></el-icon>
            <template #title>推广管理</template>
          </el-menu-item>
          </div>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="headercontainer" style="display: none;">
          <div class="logo">
          <el-icon @click="isCollapse = !isCollapse" style="display:none;">
            <ArrowRight v-if="isCollapse" />
            <ArrowLeft v-else/>
          </el-icon>
          <span class="headertitle">昆明招聘网</span>
          <el-input v-model="searchtext" size="large" placeholder="输入关键词搜索您感兴趣的！" prefix-icon="Search" />
          <el-button color="#ba181b" size="large" style="margin-left:6px;" @click="searchsend">搜索</el-button>
          </div>
          <div class="headeruser">
            <el-tooltip effect="dark" content="在线沟通" placement="left" size="small">
            <el-link href="/chats" target="_blank" :underline="false">
            <el-button size="small" color="#ffffff" title="在线沟通" style="padding-left: 8px;padding-right: 8px;">
              <el-badge :value="chatscount" v-if="chatscount==0?hidden=false:hidden=true">
               <img src="/images/goutong-2.png" style="width:32px;height:32px;" />
              </el-badge>
              <img src="/images/goutong.png" v-if="chatscount==0" style="width:32px;height:32px;" />
            </el-button>
          </el-link>
          </el-tooltip>
            <el-dropdown :hide-on-click="false">
            <el-button size="large" color="#ffffff" style="padding-left: 8px;padding-right: 8px;margin-left:22px;">
            <span class="avatarcontainer">
              <img class="avatarimg" style="width:32px;height:32px;border-radius: 32px;" v-if="users.userstype==1" :src="baseurl+'static/resume/'+users.usersid+'/photo/'+users.usersid+'.jpg?t=' + photoinfo" onerror="this.src='/images/avatar1.png'" />
              <img class="avatarimg" style="width:32px;height:32px;border-radius: 32px;" v-if="users.userstype==2" :src="baseurl + 'static/incpic/'+users.usersid+'/logo/'+users.usersid+'.jpg?t=' + photoinfo" onerror="this.src='/images/avatar2.png'" />
              <b class="avatartitle">{{users.usersname}}</b>
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
          </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="passwordset"><el-icon><Key /></el-icon>密码修改</el-dropdown-item>
                <el-dropdown-item @click="loginout"><el-icon><SwitchButton /></el-icon>退出登陆</el-dropdown-item>
              </el-dropdown-menu>
            </template>
            </el-dropdown>
          </div>
          </el-header>
        <el-main class="maincontainer">
          <router-view v-slot="{ Component }">
            <component ref="child" :is="Component" />
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
  <!-- 密码修改 ------------------------------------- -->
  <el-dialog v-model="dialogpasswordVisible" title="密码修改" width="418px">
    <el-form style="padding-right: 20px;">
      <el-form-item label="新密码" label-width="108px">
        <el-input v-model="newpasswordinfo" type="password" show-password/>
      </el-form-item>
      <el-form-item label="确认新密码" label-width="108px">
        <el-input v-model="newpasswordinfo2" type="password" show-password/>
      </el-form-item>
      <el-form-item label="" label-width="108px">
        <el-button color="#333333" @click="passwordsetsed">提交修改</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>

import { getuserprofile, passwordset } from '@/api/user'
import token from '@/utils/token'
// import { chatscount } from '@/api/chat'
import store from '@/store/index'

export default {
  name: 'userIndex',
  components: {
  },
  props: {},
  data () {
    return {
      baseurl: store.state.baseUrl,
      dialogpasswordVisible: false,
      timer: null,
      chatscount: 0,
      users: {},
      searchtext: '',
      photoinfo: new Date().getSeconds(),
      newpasswordinfo: '',
      newpasswordinfo2: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.$parent.$parent.hendersetshow(true)
    this.getusers()
  },
  mounted () {},
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.status === 200) {
          if (res.data.resultbody != null) {
            this.users = res.data.resultbody
            if (this.users.usersstatus > 0) {
              alert('您好！帐号锁定了！')
              token.settoken('utoken', '')
              this.$router.push('/login')
            }
            if (this.users.userstype === 1) {
              if (this.users.usersinfostatus === 0) {
                if (this.$router.currentRoute._rawValue.path !== '/resume') {
                  alert('您好！简历信息不完善尽快完善简历信息！')
                  this.$router.push('/resume')
                } else {
                  this.$router.push('/resumes/home')
                }
              }
            } else {
              this.$router.push('/companys/home')
            }
            // this.timer = setInterval(() => {
            //   this.joburl = '/companys/jobs?tt=' + new Date()
            //   // this.getchatscount(this.users.usersid)
            // }, 3000)
          } else {
            token.settoken('utoken', '')
            this.$router.push('/login')
          }
        } else {
          alert(res.data.message + '===>outss')
          token.settoken('utoken', '')
          this.$router.push('/login')
        }
      })
    },
    loginout () {
      token.settoken('utoken', '')
      this.$router.push('/login')
    },
    searchsend () {
      if (this.searchtext.length < 2) {
        alert('请输入两个字及以上的关键词搜索！')
      } else {
        if (this.users.userstype === 1) {
          if (this.$refs.child.setsearchkey != null) {
            this.$nextTick(() => {
              this.$refs.child.setsearchkey(this.searchtext)
            })
          } else {
            this.$router.push('/resumes/search?key=' + this.searchtext)
          }
        } else if (this.users.userstype === 2) {
          if (this.$refs.child.setsearchkey != null) {
            this.$nextTick(() => {
              this.$refs.child.setsearchkey(this.searchtext)
            })
          } else {
            this.$router.push('/companys/search?key=' + this.searchtext)
          }
        }
      }
    },
    // getchatscount (id) {
    //   chatscount(id).then(res => {
    //     if (res.data.resultbody != null) {
    //       this.chatscount = res.data.resultbody
    //     }
    //   })
    // },
    passwordset () {
      this.dialogpasswordVisible = true
    },
    passwordsetsed () {
      if (this.newpasswordinfo.length > 5) {
        if (this.newpasswordinfo === this.newpasswordinfo2) {
          passwordset(this.newpasswordinfo).then(res => {
            if (res.data.status === 200) {
              this.dialogpasswordVisible = false
              this.loginout()
              alert('密码修改成功！请重新登陆！')
            } else {
              alert(res.data.message)
            }
          })
        } else {
          alert('两次输入密码不一致！')
        }
      } else {
        alert('请输入不少于6位的密码！')
      }
    },
    getn () {
      return 1
    }
  }
}
</script>

<style scoped lang="less">
  .maincontain{
  max-width: 1188px; width: 100%;height: 96%;overflow-y:auto;
  margin-left: auto;
  margin-right: auto;
  align-self: top;
  background-color: #888888;
  }
  .asidecontainer{
    background-color: #ffffff;
   }
  .headercontainer{
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
      border-bottom-width:0px;
      border-bottom-style: solid;
      border-radius: 0px;
      border-bottom-color:  #dddfe5;
    .logo
    {
      height: 60px;
      display: flex;
      align-items: center;
      min-width: 458px;
      .headertitle {
        font-size: 22px;
        font-weight: bold;
        margin-left: 12px;
        display: none;
      }
    }
    .headeruser
    {
      height: 80px;
      display: flex;
      align-items: center;
      .avatarcontainer {
        height: 80px;
        display: flex;
        align-items: center;
        .avatarimg {
          margin-right: 8px;
        }
        .avatartitle {
          font-size: 18px;
          margin-right: 8px;;
        }
      }
    }
  }
  .maincontainer{
     background-color: #ffffff;
  }
.el-menu {
   margin-left: 50px;
  .asidelogo {
    padding-top: 50px;
   justify-content: center;
   padding-bottom:30px;
}
.reel-menu-item{
  .el-menu-item{
  font-size: 16px;
  margin-right: 20px;
  border-radius: 6px;
  height: 50px;
  margin-bottom: 2px;
  }
}
.el-menu-item{
  font-size: 16px;
  margin-right: 20px;
  border-radius: 6px;
  height: 36px;
  margin-bottom: 2px;
}
h3{margin-top: 12px; margin-bottom: 12px;}
.el-menu-item:hover {
  background-color: #eaebea;
}
.el-menu-item:active {
  background-color: #eaebea;
  font-weight: bold;
}
.asidelogo:hover {
     background-color: #ffffff;
   }
.el-icon {
  font-size: 20px;
}
}
// ---------------------

.containerbox {
  margin-top: 20px;
  border-radius: 0px;
.listbox
{
  font-size: 14px;line-height:26px;color:#888888;
  .idbox{margin-right: 12px;}
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
.el-link{font-size: 16px;font-weight: bold; color: #333333;}
.el-link2{font-size: 16px;font-weight:normal;color:#333333;}
.el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
  .xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
 .statusbox{
    .el-image {width:52px;height:52px;}
}
}
}
</style>
