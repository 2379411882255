<template>
  <h2 class="regtitle1"></h2>
  <div class="readingcontainer" v-loading="loadingmain">
    <el-row :gutter="30">
      <el-col :span="24" style="padding-left: 22px;">
          <el-tabs v-model="activeName" type="card"  @tab-click="tabsclick">
            <el-tab-pane label="帐户情况" name="t1" class="retainlist">
              <div class="scrollbarbox" :style="'height:'+(boxheight-242)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;">
              <el-row gutter="12">
                <el-col :span="18" style="border-right-width:1px;border-right-style: solid;border-right-color: #ececec;">
                  <!-- -------------------------------------------- -->
              <el-row gutter="0">
                <el-col :span="24" >帐户余额:<b style="color:red;font-size: 18px;"> ¥ {{suminfo}} 元</b></el-col>
              </el-row>
              <h3>在线充值</h3>
              <div style="font-size: 12px; color: red;">特别说明：<div>用户所有的款项请全部通过该银行账号进行汇付，<br />其它任何单位账号或个人账号都与昆明招聘网无关，请广大用户谨防假冒。 <br />咨询电话：0871-67287888</div></div>
              <h4>方式一：转帐充值</h4>
              <el-row class="retaininfo">
                <el-col :span="4" >
                  <img src="/images/duigongzhifu.png" style="width:100%;" />
                </el-col>
                <el-col :span="20" >
                  <span class="infott">开户行：</span><span class="infovv">中信银行股份有限公司昆明北京路支行 <span style="color: red;">对公账号(对公需2天)</span></span><br />
                  <span class="infott">帐户：</span><span class="infovv">开门招聘（云南）人力资源有限公司</span><br />
                  <span class="infott">帐号：</span><span class="infovv">8111 9010 1280 0263 112</span>
                </el-col>
                <el-divider />
              </el-row>
              <el-row class="retaininfo">
                <el-col :span="4" >
                  <img src="/images/zhongguojiansheyinhang.png" style="width:100%;" />
                </el-col>
                <el-col :span="20" >
                  <span class="infott">开户行：</span><span class="infovv">建设银行昆明十里长街支行</span><br />
                  <span class="infott">帐户：</span><span class="infovv">梁保堂</span><br />
                  <span class="infott">帐号：</span><span class="infovv">6227 0038 6209 0149 231</span>
                </el-col>
                <el-divider />
              </el-row>
              <el-row class="retaininfo">
                <el-col :span="4" >
                  <img src="/images/nongcunxinyongshe.png" style="width:100%;" />
                </el-col>
                <el-col :span="20" >
                  <span class="infott">开户行：</span><span class="infovv">昆明官渡农村合作银行关上支行小街分理处</span><br />
                  <span class="infott">帐户：</span><span class="infovv">梁保堂</span><br />
                  <span class="infott">帐号：</span><span class="infovv">6223 6912 3578 9934</span>
                </el-col>
                <el-divider />
              </el-row>
              <h4>方式二：充值卡充值</h4>
              <el-row gutter="12" class="retaininfo">
                <el-col :span="1" >
                </el-col>
                <el-col :span="23" >
                  <el-form :inline="true">
                    <el-form-item label="请输入充值卡密码">
                      <el-input v-model="paykey" placeholder="请输入充值卡密码" />
                    </el-form-item>
                    <el-form-item>
                      <el-button color="#333333" @click="paysend">充值</el-button>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <!-- -------------------------------------------- -->
                </el-col>
                <el-col :span="6">
                  <h3>帐户记录</h3>
                  <el-row gutter="12" v-for="item in listinfo" :key="item.payid" style="width:98%;margin-left: 1%;margin-top: 10px;line-height: 25px;font-size: 14px;">
                    <el-col :span="24">
                      <div style="color:#888888;font-size:12px;">{{item.paydate.replaceAll(/T/ig," ").replaceAll(/Z/ig," ")}}</div>
                     <div v-if="item.paybank>0">充值：¥ {{item.paybank}} 元</div>
                     <div v-else>消费：¥ {{item.paybank}} 元</div>
                    </el-col>
                    <el-divider style="margin-top: 2px;margin-bottom: 2px;" />
                  </el-row>
                </el-col>
              </el-row>
              </div>
              </div>
            </el-tab-pane>
          <!-- -------------------------------------------- -->
          <el-tab-pane label="开通服务" name="t2" class="retainlist">
            <div class="scrollbarbox" :style="'height:'+(boxheight-242)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;">
              <!-- -------------------------------------------- -->
              <el-row gutter="12">
                <el-col :span="24" >
                   <table cellpadding="6" cellspacing="1" style="background-color: #cccccc;width:100%;margin-top: 20px;">
                    <tr>
                        <td colspan="15" align="center" valign="middle" bgcolor="#f0f0f0">
                            <strong style="font-size: 16px;">昆明招聘网基础招聘价格表</strong>
                            <span style="color:red;margin-left: 20px;">(备注：昆明招聘网所有服务均以下表所列服务为准)</span>
                        </td>
                    </tr>
                    <tr style="background-color:#f8f8f8;">
                        <td colspan="2" align="center" bgcolor="#f0f0f0" valign="middle" ><b>套餐</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>价格</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>职位</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>刷新置顶</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>简历</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>短信</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>紧急</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>品牌</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>人工</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>半通栏</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>全通栏</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>暂停服务</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>同时在线</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>开通</b></td>
                    </tr>
                    <tr v-for="item in tclista" :key="item.tcid">
                        <td colspan="2" align="center" valign="middle" bgcolor="#f8f8f8" ><strong>{{item.tcname}}</strong></td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc1}}元</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" ><span v-if="item.tc2==-1">不限</span><span v-else>{{item.tc2}}</span></td>
                        <td align="center" valign="middle" bgcolor="#ffffff" ><span v-if="item.tc3==-1">不限</span><span v-else>天/{{item.tc3}}次</span></td>
                        <td align="center" valign="middle" bgcolor="#ffffff" ><span v-if="item.tc4==-1">不限</span><span v-else>{{item.tc4}}份</span></td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc5}}条</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc6}}天 ({{item.tc6/7}}次)</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc7}}天 ({{item.tc7/7}}次)</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc8}}人</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc9}}天 ({{item.tc9/7}}次)</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc10}}天 ({{item.tc10/7}}次)</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc11}}次</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" ><span v-if="item.tc12==-1">不限</span><span v-else>{{item.tc12}}</span></td>
                        <td align="center" valign="middle" bgcolor="#ffffff">
                          <el-button color="#be002f" size="small" @click="server_send(item.tcid)">开通</el-button>
                        </td>
                    </tr>
                    </table>

                    <table cellpadding="6" cellspacing="1" style="background-color: #cccccc;width:100%;margin-top: 20px;">
                    <tr>
                        <td colspan="15" align="center" valign="middle" bgcolor="#f0f0f0">
                          <strong style="font-size: 16px;">昆明招聘网基础招聘价格表(房产经纪、劳务派遣、保险类)</strong>
                            <span style="color:red;margin-left: 20px;">(备注：昆明招聘网所有服务均以下表所列服务为准)</span>
                        </td>
                    </tr>
                    <tr style="background-color:#f8f8f8;">
                        <td colspan="2" align="center" bgcolor="#f0f0f0" valign="middle" ><b>套餐</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>价格</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>职位</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>刷新置顶</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>简历</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>短信</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>紧急</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>品牌</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>人工</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>半通栏</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>全通栏</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>暂停服务</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>同时在线</b></td>
                        <td align="center" valign="middle" bgcolor="#f0f0f0"><b>开通</b></td>
                    </tr>
                    <tr v-for="item in tclistb" :key="item.tcid">
                        <td colspan="2" align="center" valign="middle" bgcolor="#f8f8f8" ><strong>{{item.tcname}}</strong></td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc1}}元</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" ><span v-if="item.tc2==-1">不限</span><span v-else>{{item.tc2}}</span></td>
                        <td align="center" valign="middle" bgcolor="#ffffff" ><span v-if="item.tc3==-1">不限</span><span v-else>天/{{item.tc3}}次</span></td>
                        <td align="center" valign="middle" bgcolor="#ffffff" ><span v-if="item.tc4==-1">不限</span><span v-else>{{item.tc4}}份</span></td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc5}}条</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc6}}天 ({{item.tc6/7}}次)</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc7}}天 ({{item.tc7/7}}次)</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc8}}人</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc9}}天 ({{item.tc9/7}}次)</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc10}}天 ({{item.tc10/7}}次)</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" >{{item.tc11}}次</td>
                        <td align="center" valign="middle" bgcolor="#ffffff" ><span v-if="item.tc12==-1">不限</span><span v-else>{{item.tc12}}</span></td>
                        <td align="center" valign="middle" bgcolor="#ffffff">
                          <el-button color="#be002f" size="small" @click="server_send(item.tcid)">开通</el-button>
                        </td>
                    </tr>
                    </table>
                   <h3>特色广告招聘价格表</h3>
                   <table cellpadding="6" cellspacing="1" style="background-color: #cccccc;width:100%;margin-top: 20px;">
                      <tr>
                          <td align="center" valign="middle" bgcolor="#f0f0f0"><strong>广告招聘类型 </strong></td>
                          <td align="center" valign="middle" bgcolor="#f0f0f0"><strong>规格（</strong><strong>宽×高）</strong><strong></strong></td>
                          <td align="center" valign="middle" bgcolor="#f0f0f0"><strong>会员价 </strong></td>
                          <td align="center" valign="middle" bgcolor="#f0f0f0"><strong>非会员价 </strong></td>
                          <td align="center" valign="middle" bgcolor="#f0f0f0"><strong>服务内容 </strong></td>
                      </tr>
                      <tr>
                          <td align="center" valign="middle" bgcolor="#f8f8f8">
                              <strong>紧急LOGO招聘 </strong>
                          </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">140*40像素 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">800元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">1000元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">享有短信邀请面试200条 </td>
                      </tr>
                      <tr>
                          <td align="center" valign="middle" bgcolor="#f8f8f8">
                              <strong>品牌LOGO招聘 </strong>
                          </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">230*60像素 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">1200元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">1500元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">享有短信邀请面试400条</td>
                      </tr>
                      <tr>
                          <td align="center" valign="middle" bgcolor="#f8f8f8">
                              <strong>半通栏广告招聘 </strong>
                          </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">470*60像素 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">3000元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">4000元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">短信邀请面试2000条</td>
                      </tr>
                      <tr>
                          <td align="center" valign="middle" bgcolor="#f8f8f8">
                              <strong>全通栏广告招聘 </strong>
                          </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">940*60像素 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">5000元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">6000元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">短信邀请面试不限条数</td>
                      </tr>
                      <tr>
                          <td align="center" valign="middle" bgcolor="#f8f8f8">
                              <strong>首页滚动广告 </strong>
                          </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">460*170像素 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">6000元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">8000元/月 </td>
                          <td align="center" valign="middle" bgcolor="#FFFFFF">短信邀请面试不限条数</td>
                      </tr>
                   </table>
                </el-col>
              </el-row>
              <!-- -------------------------------------------- -->
              </div>
              </div>
          </el-tab-pane>
      </el-tabs>
    </el-col>
    </el-row>
  </div>
</template>

<script>
import { paysget, payssum, paysset, gettc, companyserve } from '@/api/user'
export default {
  name: 'accountsIndex',
  components: {},
  data () {
    return {
      loadingmain: true,
      boxheight: 518,
      activeName: 't1',
      total: 0,
      currentpage: 1,
      listinfo: [],
      suminfo: 0,
      paykey: '',
      tclist: [],
      tclista: [],
      tclistb: []
    }
  },
  created () {
    this.boxheight = document.documentElement.clientHeight
    this.getlist()
    this.getsum()
    this.gettclist()
  },
  methods: {
    getlist () {
      this.loadingmain = true
      paysget(this.currentpage, 0).then(res => {
        if (res.data.resultbody.resultlist != null) {
          if (this.currentpage === 1) {
            this.total = res.data.resultbody.total
          }
          this.listinfo = res.data.resultbody.resultlist
        }
        setTimeout(() => { this.loadingmain = false }, 300)
      })
    },
    getsum () {
      payssum().then(res => {
        if (res.data.resultbody != null) {
          this.suminfo = JSON.parse(res.data.resultbody)[0]
        }
      })
    },
    // 充值
    paysend () {
      if (this.paykey.length > 10) {
        paysset(this.paykey).then(res => {
          if (res.data.status === 200) {
            alert('操作成功！')
            this.getlist()
            this.getsum()
          } else {
            alert(res.data.message)
          }
        })
      } else {
        alert('请填写正确的充值参数！')
      }
    },
    server_send (t) {
      companyserve(t).then(res => {
        // alert(JSON.stringify(res.data))
        if (res.data.status === 200) {
          alert('操作成功！')
          this.getsum()
        } else {
          alert(res.data.message)
        }
      })
    },
    // 获取套餐列表
    gettclist () {
      this.loadingmain = true
      gettc(1).then(res => {
        if (res.data.status === 200) {
          this.tclist = JSON.parse(res.data.resultbody)
          for (let i = 0; i < this.tclist.length; i++) {
            const itemtc = this.tclist[i]
            if (itemtc.tcid < 11) {
              this.tclista.push(itemtc)
            } else {
              this.tclistb.push(itemtc)
            }
          }
        }
        setTimeout(() => { this.loadingmain = false }, 300)
      })
    }
  }
}
</script>

<style lang="less" scoped>
td{font-size: 14px;}

.pidbox{margin-right: 8px;}
.resumegendera{position:absolute;top:2px;left:72px;width:16px;height: 16px;background-color: #003399; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.resumegenderb{position:absolute;top:2px;left:72px;width:16px;height: 16px;background-color: #FF0066; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.bordera{border-width: 2px;border-color: #003399; border-style: solid;width:68px;height:68px;border-radius: 68px;}
.borderb{border-width: 2px;border-color: #FF0066; border-style: solid;width:68px;height:68px;border-radius: 68px;}
.titlea{
  text-align: left;padding-left: 12px;font-size: 16px;margin-top: 2px; margin-bottom: 2px;
}
.readingcontainer {
  margin-top: 20px;
  border-radius: 0px;
}

.tg{
  height:52px;
  width:52px;
  // font-size: 12px;
  border-width: 0px;margin-right: 20px;
  text-align: center;line-height:14px;
}
.retaincompany {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
  color: #888888;
  .el-link {
    font-size: 14px;
    color: #333333;
  }
}
.resumecompanylogo {
  text-align: left;
  margin-top: 6px;
}
.retainlist
{
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.retaininfo {
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;font-size: 16px;}
  .infott{text-align: right; display:inline-block; width:68px;}
}
.rightyx {
  margin-left: 12px;
  margin-right:12px;
  text-align: left;
  font-size: 14px;
  color: #777777;
  line-height: 28px;
  .el-row {
    border-bottom-width:1px;
    border-bottom-style: solid;
    border-bottom-color:  #dddfe5;
    padding-top: 2px;
    padding-bottom: 8px;
  }
  .el-tab-pane {
    padding-left: 20px;
    .el-tag { margin-right: 6px; color:#222222;}
  }
}

</style>
