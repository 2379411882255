<template>
<el-header class="hender_a">
      <div class="henderbox">
        <el-row>
              <el-col :span="6"><img src="/images/logoa.png" style="height:45px; margin-left: 20px;" /></el-col>
              <el-col :span="16">
                <a href="/">首页</a>
                <a href="/jobs">找工作</a>
                <a href="/resumessearch">找人才</a>
                <a href="/senior">高级岗位</a>
              </el-col>
         </el-row>
      </div>
</el-header>
<div v-loading="loadingmain" :style="'height:'+ boxheight+'px;'"></div>
<div style="width: 100%;padding-top:0px;padding-bottom: 0px;min-width:11px;margin-top: 0px;" v-if="resumes.resumeid>0&&resumes.resumejobstatus>-1">
<el-row :gutter="20" style="width:100%; max-width: 1188px;margin-left: auto; margin-right: auto; background-color: #ffffff;line-height: 30px; font-size: 14px;">
  <el-affix :offset="298" style="margin-left: 1188px; height:1px;">
    <el-button  color="#dc3023" size="small" style="padding-right:20px;font-size:12px; width:52px;height:52px;font-size: 14px; margin-top: 20px;border-top-right-radius:26px;border-bottom-right-radius:26px;" v-if="users.usersid>0&&users.userstype==2" @click="chatopen(resumes.resumeusersid)">
      <div style="line-height: 18px;text-align: center;"><el-icon><ChatDotRound /></el-icon><br />沟通</div>
    </el-button>
      <br />
      <el-button  color="#dc3023" size="small" style="padding-right:20px;font-size:12px; width:52px;height:52px;font-size: 14px; margin-top: 20px;border-top-right-radius:26px;border-bottom-right-radius:26px;" v-if="users.usersid>0&&users.userstype==2" @click="yqedit(resumes)">
      <div style="line-height: 18px;text-align: center;"><el-icon><Position /></el-icon><br />邀请</div>
    </el-button>
    <br />
      <el-button  color="#dc3023" size="small" style="padding-right:20px;font-size:12px; width:52px;height:52px;font-size: 14px; margin-top: 20px;border-top-right-radius:26px;border-bottom-right-radius:26px;" v-if="users.usersid>0&&users.userstype==2" @click="scsend(resumes)">
      <div style="line-height: 18px;text-align: center;"><el-icon><Collection /></el-icon><br />收藏</div>
    </el-button>
  </el-affix>
  <!-- ------------------------------- -->
    <el-col :span="24">
        <el-row style="text-align:center;padding:10px;">
            <el-col :span="18" style="background-color: #dc3023;color:#ffffff;text-align: right;padding-right: 20px;">PERSONAL RESUME</el-col>
            <el-col :span="4" style="color:#dc3023;font-size: 22px;"><b>个人简历</b></el-col>
            <el-col :span="2" style="background-color: #dc3023;"></el-col>
        </el-row>
        <el-row :gutter="30" style="text-align:center;">
            <el-col :span="5" style="text-align: center;padding-top: 2px;">
                <el-avatar v-if="users.usersid==0" shape="square" :size="98" :src="'/images/avatar1.png'" onerror="this.src='/images/avatar1.png'" />
                <el-avatar v-else shape="square" :size="98" :src="baseurl+'static/resume/'+resumes.resumeusersid+'/photo/'+resumes.resumeusersid+'.jpg'" onerror="this.src='/images/avatar1.png'" />
                <h3 style="margin-top: 12px;font-size: 22px;" v-if="users.usersid==0">{{ resumes.resumename.charAt(0)}}***</h3>
                <h3 style="margin-top: 12px;font-size: 22px;" v-else>{{ resumes.resumename }}</h3>
            </el-col>
            <el-col :span="15" class="exitem">
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;margin-top: 20px;">
                <el-col :span="4" style="text-align: right; ">年龄：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{ getAges(resumes.resumebirthday) }}</b></el-col>
                <el-col :span="4" style="text-align: right;">性别：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumegender}}</b></el-col>
                </el-row>
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;">
                <el-col :span="4" style="text-align: right;">学校：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumeschool}}</b></el-col>
                <el-col :span="4" style="text-align: right;">专业：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumespecialty}}</b></el-col>
                </el-row>
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;">
                <el-col :span="4" style="text-align: right;">经验：</el-col>
                <el-col :span="8" style="text-align: left;"><b>{{resumes.resumeworkage}}</b></el-col>
                <el-col :span="4" style="text-align: right;">居住地：</el-col>
                <el-col :span="8" style="text-align: left;" v-if="resumes.resumearea.length>0"><b>{{resumes.resumearea}}</b></el-col>
                <el-col :span="8" style="text-align: left;" v-else><b>昆明</b></el-col>
                </el-row>
                <el-divider content-position="left">联系方式</el-divider>
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;margin-top: 12px;" v-if="resumes.resumemail!='-1'||resumes.resumeusersphone!='-1'">
                    <el-col :span="2"></el-col>
                    <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Phone /></el-icon></el-col>
                    <el-col :span="6" style="text-align: left;"><img :src="baseurl+'user/phone?p='+resumes.resumeusersphone" /></el-col>
                    <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Message /></el-icon></el-col>
                    <el-col :span="8" style="text-align: left;"><b>{{resumes.resumemail}}</b></el-col>
                </el-row>
                <el-row :gutter="2" style="text-align:center;width: 100%;margin-top: 12px;line-height: 23px;font-size:12px;" v-else>
                    <el-col :span="24" v-if="users.usersid==0 || users.userstype==1">
                      企业会员登陆后查看求职者联系方式！享受全面的服务！<br />
                      服务热线：<b style="margin-right: 20px;">0871-67165818</b>
                      请 <a href="/login" style="font-size: 12px; color: red;text-decoration:none;">登陆</a> 或 <a href="/reg" style="font-size: 12px; color: red;text-decoration:none;">注册</a> 成为我们的会员
                    </el-col>
                    <el-col :span="24" v-if="users.usersid>0 && users.userstype==2" style="text-align: center;">
                    <el-button  color="#dc3023" size="small" style="font-size:12px;" @click="getcontact">查看联系方式</el-button><br />
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="4">
                <div style="text-align: right;font-size: 12px;line-height: 13px;padding-right: 20px;color:#888888;">
                    <img :src="baseurl+'user/generate?text=http://m.kmzp.com/resumeitem?id='+resumes.resumeusersid" style="width:52px;height:52px" />
                    <br />扫码查看
                </div>
            </el-col>
        </el-row>
    </el-col>
    <el-col :span="24">
        <el-row :gutter="30" style="text-align:center;padding:20px;">
        <!--求职意向----------------------------->
        <el-col :span="24" style="text-align: left;">
            <h2 style="margin-top: 0px;"><img src="/images/yixiang.png" style="width:18px; margin-right: 12px;" />求职意向</h2>
            <el-divider style="margin-top: 2px;" />
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">求职类型：</el-col>
                <el-col :span="21" style="text-align: left;"><b>{{resumes.resumejobtype}}</b></el-col>
            </el-row>
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">期望行业：</el-col>
                <el-col :span="21" style="text-align: left;">
                    <b style="margin-right: 20px;" v-for="citem in resumeindustry" :key="citem.categoryid">{{citem.categoryname}}</b>
                </el-col>
            </el-row>
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">期望职位：</el-col>
                <el-col :span="21" style="text-align: left;">
                    <b style="margin-right: 20px;" v-for="citem in resumejobposition" :key="citem.categoryid">{{citem.categoryname}}</b>
                    <b v-if="resumes.resumejobpositions.length>0">{{ resumes.resumejobpositions }}</b>
                </el-col>
            </el-row>
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">工作地区：</el-col>
                <el-col :span="21" style="text-align: left;">
                    <b style="margin-right: 20px;" v-for="citem in resumejobcity" :key="citem.categoryid">{{citem.categoryname}}</b>
                </el-col>
            </el-row>
            <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                <el-col :span="3" style="text-align: right;">期望薪资：</el-col>
                <el-col :span="21" style="text-align: left;">
                    <b style="margin-right: 20px;">{{resumes.resumesalary}}</b>
                </el-col>
            </el-row>
        </el-col>
        <!--个人优势----------------------------->
        <el-col :span="24" style="text-align: left;" v-if="resumepersonaladvantages!=''">
                <h2><img src="/images/youshi1.png" style="width:18px; margin-right: 12px;" />个人优势</h2>
                <el-divider style="color: #ffffff; margin-top: 2px;" />
                <div style="text-indent:2em;">{{ resumes.resumepersonaladvantages }}</div>
            </el-col>
        <!--工作经历----------------------------->
        <el-col :span="24" style="text-align: left;" v-if="resumeworkexperience.length>0">
            <h2 style="margin-top: 12px;"><img src="/images/gongzuojingli.png" style="width:18px; margin-right: 12px;" />工作经历</h2>
            <el-divider style="margin-top: 2px;" />
            <el-row :gutter="20" v-for="item in resumeworkexperience" :key="item.workexid">
                  <el-col :span="1"></el-col>
                  <el-col :span="22" class="itemlistitem">
                    <div class="exitem">时间：<b>{{ item.workextime }}</b></div>
                    <div class="exitem">公司：<b>{{ item.workexinc }}</b></div>
                    <div class="exitem">部门：<b>{{ item.workexdepartment }}</b></div>
                    <div class="exitem">职位：<b>{{ item.workexposition }}</b></div>
                    <div class="exitem">说明：<b>{{ item.workexcontent }}</b></div>
                  </el-col>
            </el-row>
        </el-col>
        <!--教育经历----------------------------->
        <el-col :span="24" style="text-align: left;" v-if="resumeexperienceeducation.length>0">
            <h2 style="margin-top: 2px;"><img src="/images/jiaoyujingli.png" style="width:18px; margin-right: 12px;" />教育经历</h2>
            <el-divider style="margin-top: 2px;" />
            <el-row :gutter="20" v-for="item in resumeexperienceeducation" :key="item.educateexid">
                  <el-col :span="1"></el-col>
                  <el-col :span="22"  class="itemlistitem">
                    <div class="exitem">时间：<b>{{ item.educateextime }}</b></div>
                    <div class="exitem">学校：<b>{{ item.educateexschool }}</b></div>
                    <div class="exitem">学历：<b>{{ item.educateexeducation }}</b></div>
                    <div class="exitem">专业：<b>{{ item.educateexfield }}</b></div>
                    <div class="exitem">说明：<b>{{ item.educateexcontent }}</b></div>
                  </el-col>
            </el-row>
        </el-col>
        </el-row>
    </el-col>
    <el-col :span="24" v-if="users.usersid>0&&users.userstype==2&&(resumes.resumemail!='-1'||resumes.resumeusersphone!='-1')">
      <el-row :gutter="30" style="text-align:center;padding:20px;">
        <!--求职意向----------------------------->
        <el-col :span="24" style="text-align: left;">
          <h2 style="margin-top: 0px;"><el-icon style="color: #dc3023; font-size: 15px;"><Notebook /></el-icon> 简历备注</h2>
          <el-divider style="margin-top: 2px;" />
          <el-row :gutter="20">
          <el-col :span="16">
            <div v-for="item,index in resumelog" :key="index">
            {{ item.rlogdate }} {{ item.rlogcontent }}
          </div>
          </el-col>
          <el-col :span="8" style="text-align: right; background-color: #f8f8f8;padding: 12px;padding-top: 20px;border-radius: 12px;">
            <el-form-item label="备注内容">
           <el-input v-model="resumeloginfo" type="textarea"/>
          </el-form-item>
          <el-button color="#333333" @click="setresumelog" size="small" >添加备注</el-button>
          </el-col>
          </el-row>
        </el-col>
        </el-row>
    </el-col>
    <el-col :span="24" style="text-align: left;font-size: 12px;line-height: 26px;padding-top: 6px;padding-bottom: 6px; color: #333333;background-color: #f8f8f8;">
          Copyright © 2024 开门招聘（云南）人力资源有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 16px;color:#333333;"><b>滇ICP备18009977号</b></a> <span style="margin-left: 16px;">增值电信业务经营许可证：<b>滇B2-20220163</b></span>
          <span style="margin-left: 16px;"><a href="https://beian.mps.gov.cn/#/query/webSearch?code=53010302001462" rel="noreferrer" target="_blank" style="color: #333333;"><img src="http://www.kmzp.com/images/ga.jpeg" style="border: 0px;height: 18px; margin-bottom: -5px;margin-right: 3px;" />滇公网安备53010302001462</a></span><br />
未经www.kmzp.com同意，不得转载昆明招聘网招聘信息，不得将昆明招聘网招聘信息用于其他商业用途 本站常年法律顾问团：北京大成（昆明）律师事务所
      </el-col>
</el-row>
</div>
<div v-if="resumes.resumeid==0||resumes.resumejobstatus<0" style="text-align: center; padding: 260px; font-size: 14px; line-height:42px; color: #333333;">
  <img src="/images/4043.png" style="height:72px;" />
  <br /> 求职者可能屏蔽了简历 <a href="/" style="font-size: 16px; color: red;text-decoration:none;">返回</a> 再找找看
</div>
<!-- -------------------------------------- -->
<el-dialog v-model="yq_dialogVisible" title="邀请面试" width="418px">
      <el-row gutter="60">
        <el-col :span="24">
          <el-form-item label="" style="width: 100%;">
            <el-select  v-model="yq_job" placeholder="选择邀请面试岗位" style="width: 100%;">
                <el-option v-for="item in joblist" :key="item.jobid" :value="item.jobname">{{item.jobname}}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div style="text-align: right;padding-top: 18px;padding-bottom: 18px;">
            <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqsend()"><el-icon style="margin-right:6px;"><Position /></el-icon>发送邀请</el-button>
          </div>
        </el-col>
      </el-row>
</el-dialog>
<!-- -------------------------------------- -->
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:9999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>
<script>

import { getuserprofile, getresumeitem, collectyonsc, cjobmanage, inviteyq, resumelog } from '@/api/user'
import token from '@/utils/token'
import store from '@/store/index'
import timehelp from '@/utils/timehelp'

export default {
  name: 'resumeitemIndex',
  components: {
  },
  props: {},
  data () {
    return {
      baseurl: store.state.baseUrl,
      drawer: 'none',
      iframeSrc: '',
      users: { usersid: 0 },
      resumes: { resumeid: 0 },
      resumejobcity: [],
      resumejobposition: [],
      resumeindustry: [],
      resumeworkexperience: [],
      resumeexperienceeducation: [],
      joblist: [],
      yq_dialogVisible: false,
      yq_job: '选择邀请面试岗位',
      loadingmain: true,
      boxheight: 518,
      resumelog: [],
      resumeloginfo: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    const id = this.$route.query.id
    this.getusers()
    this.getresumes(id)
  },
  mounted () {},
  methods: {
    getresumelog () {
      resumelog(this.resumes.resumeusersid, 0, null).then(res => {
        if (res.data.resultbody != null) {
          this.resumelog = res.data.resultbody
        }
      })
    },
    setresumelog () {
      const resumelogitem = '{"rlogcontent":"' + this.resumeloginfo + '","rlogdate":""}'
      resumelog(this.resumes.resumeusersid, 1, resumelogitem).then(res => {
        this.resumeloginfo = ''
        this.getresumelog()
      })
    },
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.users = res.data.resultbody
          if (this.users.usersstatus > 0) {
            token.settoken('utoken', '')
            this.users = { usersid: 0 }
          }
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    getresumes (id) {
      getresumeitem(id, 0).then(res => {
        if (res.data.status === 200) {
          this.resumes = res.data.resultbody
          this.getresumelog()
          if (this.resumes.resumejobcity.length > 0) {
            if (this.isJsonString(this.resumes.resumejobcity.replace(/\n/g, '').replace(/\r/g, ''))) {
              this.resumejobcity = JSON.parse(this.resumes.resumejobcity.replace(/\n/g, '').replace(/\r/g, ''))
            }
          }
          if (this.resumes.resumejobposition.length > 0) {
            if (this.isJsonString(this.resumes.resumejobposition.replace(/\n/g, '').replace(/\r/g, ''))) {
              this.resumejobposition = JSON.parse(this.resumes.resumejobposition.replace(/\n/g, '').replace(/\r/g, ''))
            }
          }
          if (this.resumes.resumeindustry.length > 0) {
            if (this.isJsonString(this.resumes.resumeindustry.replace(/\n/g, '').replace(/\r/g, ''))) {
              this.resumeindustry = JSON.parse(this.resumes.resumeindustry.replace(/\n/g, '').replace(/\r/g, ''))
            }
          }
          if (this.resumes.resumeexperienceeducation.length > 2) {
            if (this.isJsonString(this.resumes.resumeexperienceeducation.replace(/\n/g, '').replace(/\r/g, ''))) {
              this.resumeexperienceeducation = JSON.parse(this.resumes.resumeexperienceeducation.replace(/\n/g, '').replace(/\r/g, ''))
            }
          }
          if (this.resumes.resumeworkexperience.length > 2) {
            if (this.isJsonString(this.resumes.resumeworkexperience.replace(/\n/g, '').replace(/\r/g, ''))) {
              this.resumeworkexperience = JSON.parse(this.resumes.resumeworkexperience.replace(/\n/g, '').replace(/\r/g, ''))
            }
          }
          this.loadingmain = false
          this.boxheight = 1
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！11' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    getAges (val) {
      return timehelp.getAge(val)
    },
    getcontact () {
      if (this.users.usersid > 0) {
        getresumeitem(this.resumes.resumeusersid, 1).then(res => {
          if (res.data.status === 200) {
            this.resumes = res.data.resultbody
            if (this.resumes.resumejobcity.length > 0) {
              this.resumejobcity = JSON.parse(this.resumes.resumejobcity)
            }
            if (this.resumes.resumejobposition.length > 0) {
              this.resumejobposition = JSON.parse(this.resumes.resumejobposition)
            }
            if (this.resumes.resumeindustry.length > 0) {
              this.resumeindustry = JSON.parse(this.resumes.resumeindustry)
            }
            if (this.resumes.resumeworkexperience.length > 2) {
              this.resumeworkexperience = JSON.parse(this.resumes.resumeworkexperience)
            }
            if (this.resumes.resumeexperienceeducation.length > 2) {
              this.resumeexperienceeducation = JSON.parse(this.resumes.resumeexperienceeducation)
            }
          } else {
            this.$message({
              message: '::>_<:: ' + JSON.stringify(res.data.message),
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！22' + JSON.stringify(res),
            type: 'error'
          })
        })
      }
    },
    scsend (item) {
      if (this.users.usersid > 0) {
        collectyonsc(item.resumeusersid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '企业会员登陆操作！',
          type: 'error'
        })
      }
    },
    yqedit (item) {
      if (this.users.usersid > 0) {
        this.yq_dialogVisible = true
        this.getjoblist()
      } else {
        this.$message({
          message: '企业会员登陆操作！',
          type: 'error'
        })
      }
    },
    getjoblist () {
      if (this.users.usersid > 0) {
        cjobmanage(0, 1, '', 50, 0).then(res => {
          if (res.data.resultbody.resultlist != null) {
            this.joblist = res.data.resultbody.resultlist
          }
        })
      }
    },
    yqsend () {
      if (this.users.usersid > 0) {
        const resumeid = this.resumes.resumeusersid
        let jobid = 0
        for (let i = 0; i < this.joblist.length; i++) {
          if (this.joblist[i].jobname === this.yq_job) {
            jobid = this.joblist[i].jobid
          }
        }
        if (resumeid > 0 && jobid > 0) {
          inviteyq(jobid, resumeid).then(res => {
            if (res.data.status === 200) {
              this.loading = false
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              this.yq_job = '选择邀请面试岗位'
              this.yq_dialogVisible = false
            } else {
              this.$message({
                message: '::>_<:: ' + res.data.message + '',
                type: 'error'
              })
            }
          }).catch(res => {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！',
              type: 'error'
            })
          })
        } else {
          this.$message({
            message: '::>_<:: 请选择邀请面试的岗位！',
            type: 'error'
          })
        }
      }
    },
    chatopen (id) {
      this.drawer = true
      this.iframeSrc = '/chats?id=' + id
    },
    isJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  }
}
</script>

<style scoped lang="less">
.hender_a{
  height:60px;
  display: flex;
  justify-content: center;
  align-items: top;
  width:100%;
  min-width: 1180px;
  background-color: #ffffff;
  margin-top: 0px;
.henderbox{
  background-color: #ffffff;height:46px;line-height: 46px;margin-top: 8px;width:1188px;max-width: 1188px;min-width: 1180px; text-align: left;align-items: left;
  a {
    line-height: 32px;
    font-size: 14px;
    color: #333333;
    margin-left: 22px;
    text-decoration: none;
  }
}
}
h2{margin-top: 30px;margin-bottom: 6px;}
.exitem{color: #888888;
  b{color:#333333;font-weight: normal;}
}
.itemlistitem{
  margin-bottom: 28px;
  border-bottom-width:1px;
  border-bottom-color: #cccccc;
  border-bottom-style: solid;
  width:80%;
  margin-left: 60px;
  border-radius: 15px;
  padding: 12px;
  .exitem{padding-left: 20px;}
}
</style>
