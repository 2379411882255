import { createStore } from 'vuex'

export default createStore({
  state: {
    // baseUrl: 'http://localhost:801/',
    // chatUrl: 'http://localhost:801/',
    // wsUrl: 'ws://localhost:80/',
    // pubUrl: 'http://106.13.168.101:8082/'

    baseUrl: 'https://24api.kmzp.com/',
    chatUrl: 'https://24api.kmzp.com/',
    wsUrl: 'wss://chatapi1.kmzp.com/',
    pubUrl: 'https://24mapi.kmzp.com/'// 管理后台接口
  },
  getters: {
  },
  mutations: {
    // setshops (state, value) {
    //   state.shopsinfo = value
    // }
  },
  actions: {
  },
  modules: {
  }
})
