<template>
<el-row gutter="32" class="count_a">
    <el-col :span="16" class="leftcontainer">
       <el-tabs v-model="activeName" type="card" @tab-change="onTabChange">
          <el-tab-pane label="最新职位" name="first" class="listbox">
              <div :style="stylebox" v-infinite-scroll="getjoblist" infinite-scroll-distance="20" class="scrollbarbox">
                <el-row class="el-row" gutter="12" v-for="item in joblist" :key="item.jobid" style="width:100%;text-align: left;">
                  <el-col :span="16">
                  <div><el-link class="el-link" :underline="false" :href="'/jobitem?id='+item.jobid" target="_blank">{{item.jobname}}</el-link>
                    <span style="margin-left: 28px;">{{ item.jobdates.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}</span>
                  </div>
                  <div>
            <span class="xinzi" v-if="item.jobsalarys!=''">￥{{ item.jobsalarys }}<el-divider direction="vertical" /></span>
            <span class="xinzi" v-else>￥{{ item.jobsalary }}<el-divider direction="vertical" /></span>
                    <el-divider direction="vertical" />
                    <span>经验:{{ item.jobexperience }}</span>
                    <el-divider direction="vertical" />
                    <span>{{ item.jobeducation }}</span>
                    <el-divider direction="vertical" />
                    <span>{{ item.jobarea }}</span><br />
                    <span>{{ item.jobcategory }}</span>
                    <span v-if="item.jobcategorys.length>0"> / {{ item.jobcategorys }}</span>
                  </div>
                  <div style="padding-top:8px;" v-if="item.jobwelfare.length>0">
                    <el-button v-for="bitem in item.jobwelfare.substring(0,item.jobwelfare.length-1).split(',')" :key="bitem" text bg size="small">{{ bitem }}</el-button>
                  </div>
                  </el-col>
                  <el-col :span="8">
                    <div>
                      <el-link class="el-link2" :underline="false" :href="'/companyitem?id='+item.jobusersid" target="_blank">{{item.jobcompanyname }}</el-link>
                    </div>
                    <div>
                    <span v-if="item.JobIndustry!=''">{{item.JobIndustry}}</span><br />
                    <span v-if="item.JobCompanyType!=''">{{item.JobCompanyType}}</span>
                    </div>
                  </el-col>
                  <el-col :span="24">
                  <div style="text-align: right;margin-top: 8px; ">
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="jobsq(item)"><el-icon style="margin-right:2px;"><Position /></el-icon>申请</el-button>
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="scsend(item)"><el-icon style="margin-right:2px;"><Collection /></el-icon>收藏</el-button>
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="chatopen(item.jobusersid)"><el-icon style="margin-right:2px;"><ChatDotRound /></el-icon>沟通</el-button>
                    </div>
                  </el-col>
                  <el-divider />
                </el-row>
                <div v-if="loading==false" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><img src="/images/loading.gif" style="border-width: 0px;" /><br />稍等数据加载中</div>
                <div v-if="loading==true" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><el-icon><WarningFilled /></el-icon><br />没有了...</div>
              </div>
          </el-tab-pane>
          <el-tab-pane label="推荐职位" name="second" class="listbox">
            <div :style="stylebox" v-infinite-scroll="getjoblisttj" infinite-scroll-distance="20" class="scrollbarbox">
                <el-row class="el-row" gutter="12" v-for="item in joblisttj" :key="item.jobid" style="width:100%;text-align: left;">
                  <el-col :span="16">
                  <div><el-link class="el-link" :underline="false" :href="'/jobitem?id='+item.jobid" target="_blank">{{item.jobname}}</el-link>
                    <span style="margin-left: 28px;">{{ item.jobdates.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}</span>
                  </div>
                  <div>
                    <span class="xinzi">￥{{ item.jobsalary }}/{{ item.jobsalarys }}</span>
                    <el-divider direction="vertical" />
                    <span>经验:{{ item.jobexperience }}</span>
                    <el-divider direction="vertical" />
                    <span>{{ item.jobeducation }}</span>
                    <el-divider direction="vertical" />
                    <span>{{ item.jobarea }}</span><br />
                    <span>{{ item.jobcategory }}</span>
                    <span v-if="item.jobcategorys.length>0"> / {{ item.jobcategorys }}</span>
                  </div>
                  <div style="padding-top:8px;" v-if="item.jobwelfare.length>0">
                    <el-button v-for="bitem in item.jobwelfare.substring(0,item.jobwelfare.length-1).split(',')" :key="bitem" text bg size="small">{{ bitem }}</el-button>
                  </div>
                  </el-col>
                  <el-col :span="8">
                    <div>
                      <el-link class="el-link2" :underline="false" :href="'/companyitem?id='+item.jobusersid" target="_blank">{{item.jobcompanyname }}</el-link>
                    </div>
                    <div>
                    <span v-if="item.JobIndustry!=''">{{item.JobIndustry}}</span><br />
                    <span v-if="item.JobCompanyType!=''">{{item.JobCompanyType}}</span>
                    </div>
                  </el-col>
                  <el-col :span="24">
                  <div style="text-align: right;margin-top: 8px; ">
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="jobsq(item)"><el-icon style="margin-right:2px;"><Position /></el-icon>申请</el-button>
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="scsend(item)"><el-icon style="margin-right:2px;"><Collection /></el-icon>收藏</el-button>
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="chatopen(item.jobusersid)"><el-icon style="margin-right:2px;"><ChatDotRound /></el-icon>沟通</el-button>
                    </div>
                  </el-col>
                  <el-divider />
                </el-row>
                <div v-if="loading==false" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><img src="/images/loading.gif" style="border-width: 0px;" /><br />稍等数据加载中</div>
                <div v-if="loading==true" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><el-icon><WarningFilled /></el-icon><br />没有了...</div>
            </div>
          </el-tab-pane>
        </el-tabs>
    </el-col>
    <!-- -------------------------------------- -->
    <el-col :span="8" class="rightcontainer">
      <el-row :gutter="12" class="count_a">
        <el-col :span="6">
           <img style="width:60px;height:60px;border-radius: 10px;" :src="resumeface" onerror="this.src='/images/avatar1.png'" /><br />
           <el-form size="small" hide-required-asterisk="true" class="formcontainer" label-position="top" style="margin-top:10px; margin-bottom: 10px;">
              <el-upload :before-upload="beforeuploadlogo" :action="resumefaceurl" method="post" name="file" style="margin-top:6px;" :on-success="onsuccesslogo" :headers="headers" :show-file-list="false">
                <el-button size="small" type="info" plain><el-icon style="margin-right:3px"><Camera /></el-icon>上传</el-button>
              </el-upload>
           </el-form>
        </el-col>
        <el-col :span="18" style="text-align:left;line-height: 22px;font-size: 12px;">
        欢迎您！<b style="font-size: 14px;">{{ resume.resumename }}</b><br />
        <div>
          <span class="infostyle">生日：<b class="infostylevv">{{ resume.resumebirthday }}</b></span>
          <br />
          <span class="infostyle">参工时间：<b class="infostylevv">{{ resume.resumeworktime }}</b></span>
          <br />
          <span class="infostyle">工作经验：<b class="infostylevv">{{ resume.resumeworkage }}</b></span><br />
          <el-button  color="#000000" size="small" round style="margin-top:8px;" @click="resumeedit">
             <el-icon style="margin-right:6px;"><Edit /></el-icon>
             简历编辑
        </el-button>
        </div>
        <div style="margin-top: 15px;">
          <div style="color:darkred;font-size: 14px;line-height: 42px;">暂时不想找工作？可屏蔽简历先</div>
         <el-select size="small" v-model="resume.resumejobstatus" placeholder="选择状态" @change="updatestatus" style="width:100%;">
          <el-option :value="1" label="离职 - 随时到岗"/>
          <el-option :value="0" label="在职 - 投递企业可查看"/>
          <el-option :value="-1" label="屏蔽 - 隐藏求职信息"/>
        </el-select>
        <div style="color:darkred;font-size: 14px;line-height: 32px;margin-top: 8px;">也可以注销简历
          <el-button  color="#C83B3B" size="small" round style="margin-left: 12px;" @click="resumerm">
             <el-icon style="margin-right:6px;"><Edit /></el-icon>
             注销简历
        </el-button>
        </div>
        </div>
        <div>
        </div>
        </el-col>
      </el-row>
      <!-- -------------------------------------- -->
      <el-row :gutter="2" class="count_a">
        <el-col :span="6">
          <el-card shadow="always" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ countlist[0] }}</b><br />
            已投递
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ countlist[1] }}</b><br />
            被邀请
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ countlist[2] }}</b><br />
            被查看
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ countlist[3] }}</b><br />
            收藏
          </el-card>
        </el-col>
      </el-row>

    <div style="position: relative;">
      <el-tabs type="card" class="rightyx">
        <el-tab-pane label="我的求职意向" class="scrollbarbox" :style="'height:'+(boxheight-258)+'px;overflow-y:auto;overflow-x:none;'">
          <el-row>
            <el-col :span="7" style="text-align: right;">
            求职类别：
            </el-col>
            <el-col :span="17" style="text-align:left;">
               <el-tag  type="info">{{resume.resumejobtype}}</el-tag>
            </el-col>
          </el-row><el-row>
            <el-col :span="7" style="text-align: right;">
            薪资要求：
            </el-col>
            <el-col :span="17" style="text-align:left;">
               <el-tag  type="info">{{resume.resumesalary}}</el-tag>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7" style="text-align: right;">
            工作城市：
            </el-col>
            <el-col :span="17" style="text-align:left;">
              <el-tag  type="info" v-for="citem in resumejobcity" :key="citem.categoryid">{{citem.categoryname}}</el-tag>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="7" style="text-align: right;">
            期望职位：
            </el-col>
            <el-col :span="17" style="text-align:left;">
              <el-tag  type="info" v-for="citem in resumejobposition" :key="citem.categoryid">{{citem.categoryname}}</el-tag>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24" style="text-align: left;padding-left: 22px;margin-bottom: 8px;">
            期望行业：
            </el-col>
            <el-col :span="24" style="text-align:left;padding-left: 22px;">
              <el-tag  type="info" v-for="citem in resumeindustry" :key="citem.categoryid">{{citem.categoryname}}</el-tag>
            </el-col>
          </el-row>
        </el-tab-pane>

      </el-tabs>
        <el-button  size='small' color="#000000" round style="position: absolute;right:10px;top:5px;" @click="resumeedits">
          <el-icon style="margin-right:6px;"><Edit /></el-icon> 意向编辑
        </el-button>
     </div>

    </el-col>
  </el-row>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:9999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>

import { rgetresume, rresumeput, jobs, applysq, collectyonsc, resumecount, jobsearch, logoff } from '@/api/user'
import store from '@/store/index'
import token from '@/utils/token'

export default {
  name: 'homeIndex',
  components: {},
  props: {},
  data () {
    return {
      headers: {},
      resumeface: '',
      boxheight: 518,
      drawer: 'none',
      iframeSrc: '',
      stylebox: 'width:100%;height: 200px;overflow: auto',
      loading: false,
      loadingtj: false,
      activeName: 'first',
      statusvalue: '1',
      resume: { resumeid: 0 },
      resumejobcity: [],
      resumejobposition: [],
      resumeindustry: [],
      currentpage: 1,
      currentpagetj: 1,
      joblist: [],
      joblisttj: [],
      resumefaceurl: store.state.baseUrl + 'resume/pic?t=1',
      baseurl: store.state.baseUrl,
      countlist: []
    }
  },
  computed: {
    disabled () {
      return this.loading
    }
  },
  watch: {},
  created () {
    this.headers.token = token.gettoken('utoken')
    this.getresumes()
    const listheight = document.documentElement.clientHeight - 218
    this.stylebox = 'width:100%;height: ' + listheight + 'px;overflow: auto'
    this.boxheight = listheight
  },
  mounted () {},
  methods: {
    getresumes () {
      rgetresume().then(res => {
        if (res.data.status === 200) {
          this.resume = res.data.resultbody
          if (this.resume.resumejobcity.length > 0) {
            this.resumejobcity = JSON.parse(this.resume.resumejobcity.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resume.resumejobposition.length > 0) {
            this.resumejobposition = JSON.parse(this.resume.resumejobposition.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resume.resumeindustry.length > 0) {
            this.resumeindustry = JSON.parse(this.resume.resumeindustry.replace(/\n/g, '').replace(/\r/g, ''))
          }
        }
        if (this.resume.resumeid === 0) {
          this.$router.push('/resumes')
        } else {
          this.getresumeount()
          this.resumeface = this.baseurl + 'static/resume/' + this.resume.resumeusersid + '/photo/' + this.resume.resumeusersid + '.jpg?t=' + new Date().getSeconds()
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      })
    },
    getresumeount () {
      resumecount().then(res => {
        if (res.data.status === 200) {
          this.countlist = res.data.resultbody
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      })
    },
    updatestatus () {
      rresumeput(this.resume).then(res => {
        if (res.data.status === 200) {
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！{' + res.data.message + '}',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{updatestatus}',
          type: 'error'
        })
      })
    },
    getjoblist () {
      setTimeout(() => {
        if (this.loading === false) {
          jobs(this.currentpage, '').then(res => {
            if (res.data.resultbody.resultlist != null) {
              if (this.currentpage === 1) {
                this.jobtotaltj = res.data.resultbody.total
              }
              for (let i = 0; i < res.data.resultbody.resultlist.length; i++) {
                this.joblist.push(res.data.resultbody.resultlist[i])
              }
              if (res.data.resultbody.resultlist.length < 30) {
                setTimeout(() => { this.loading = true }, 100)
              } else {
                this.currentpage++
                setTimeout(() => { this.loading = false }, 100)
              }
            } else {
              setTimeout(() => { this.loading = true }, 100)
            }
          })
        }
      }, 100)
    },
    getjoblisttj () {
      jobsearch(this.currentpagetj, 'tj').then(res => {
        if (res.data.resultbody != null) {
          if (res.data.resultbody.length < 30) {
            setTimeout(() => { this.loadingtj = true }, 100)
          } else {
            this.currentpagetj++
            setTimeout(() => { this.loadingtj = false }, 100)
          }
          if (this.currentpagetj === 1) {
            this.jobtotaltj = res.data.resultbody.total
          }
          for (let i = 0; i < res.data.resultbody.length; i++) {
            this.joblisttj.push(res.data.resultbody[i])
          }
        } else {
          setTimeout(() => { this.loadingtj = true }, 100)
        }
      })
    },
    jobsq (item) {
      const companyid = item.jobusersid
      const jobids = item.jobid
      if (companyid > 0 && jobids > 0) {
        applysq(jobids, companyid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '::>_<:: 请选择邀请面试的岗位！',
          type: 'error'
        })
      }
    },
    scsend (item) {
      collectyonsc(item.jobid).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: ' + res.data.message + '',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    chatopen (id) {
      this.drawer = 'block'
      this.iframeSrc = '/chats?id=' + id
    },
    beforeuploadlogo (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的图片！')
        return false
      } else if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        alert('请上传jpg或者png格式的图片！')
        return false
      }
      return true
    },
    onsuccesslogo (uploadFilea, uploadFileb, uploadFilec) {
      this.resumeface = this.baseurl + 'static/resume/' + this.resume.resumeusersid + '/photo/' + this.resume.resumeusersid + '.jpg?t=' + new Date().getSeconds()
      location.reload()
    },
    resumeedit () {
      this.$router.push('/resume')
    },
    resumeedits () {
      this.$router.push('/resume?t=2')
    },
    onTabChange (vv) {
      if (vv === 'second') {
        this.getjoblisttj()
      }
    },
    resumerm () {
      if (confirm('确定要执行该操作吗？本操作将删除您的简历信息！删除后将不能恢复！')) {
        // 如果用户确认执行，则执行您的方法
        logoff().then(res => {
          this.resume.resumejobstatus = -1
          alert('操作已经执行！感谢您对昆明招聘网的支持！')
          token.settoken('utoken', '')
          top.location.href = '/login'
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！{updatestatus}',
            type: 'error'
          })
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
}
.bg-purple {
background-color: #ffffff;
}
.bg-purple-light {
  background-color: bisque;
}
.count_a {
  text-align: center;
  line-height: 22px;
  .el-card{
  color: #909399;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px;
  border-radius: 0px;
  margin-bottom: 6px;
  margin-right: 5px;
  .countstyle {
    font-size: 16px;
    color: #000000;
  }
  }}
.rightcontainer {
  border-left-width:1px;
  border-left-style: solid;
  border-radius: 0px;
  border-left-color:  #dddfe5;

  .rightyx{
    font-size: 13px;
    .el-tag{margin-bottom: 5px; margin-right: 3px;}
  }
}

.listbox
{
  font-size: 14px;line-height:22px;
  .idbox{margin-right: 12px;}
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
.el-link{font-size: 14px;font-weight: bold; color: #333333;}
.el-link2{font-size: 12px;font-weight:normal;color:#666666;}
.el-divider {
    margin-top:8px;
    margin-bottom: 12px;
  }
  .xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
}
</style>
