<template>
<div class="containerbox">
      <el-row :gutter="30" style="width:100%;">
        <el-col :span="24">
            <el-tabs model-value="first" type="card">
              <el-tab-pane label="查看记录" name="first" class="listbox">
              <!-- pagers -------------------------------------------- -->
              <el-row gutter="2" style="margin-bottom: 8px;">
                <el-col :span="18">
                </el-col>
                <el-col :span="6">
                  <div style="text-align:right;">
                    <el-pagination style="float: right;" small :current-page="currentpage" background :total="total" :page-size="12" pager-count="5" layout="prev, pager, next" @current-change="pagechange"/>
                  </div>
                </el-col>
              </el-row>
              <!-- pagers -------------------------------------------- -->
          <!-- -------------------------------------------- -->
            <div class="scrollbarbox" :style="'height:'+(boxheight-228)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;">
              <el-row gutter="12" v-for="item in list" :key="item.readingid">
                <el-col :span="16" class="retaininfo" >
                  <div v-if="item.readingjob.length>100">
                  <div><el-link :underline="false" :href="'/jobitem?id='+JSON.parse(item.readingjob.replace(/\n/g, '').replace(/\r/g, '')).jobid" target="_blank">{{JSON.parse(item.readingjob.replace(/\n/g, '').replace(/\r/g, '')).jobname}}</el-link></div>
                  <div>
                    <span class="xinzi">￥{{JSON.parse(item.readingjob.replace(/\n/g, '').replace(/\r/g, '')).jobsalary}}
                    </span>
                    <el-divider direction="vertical" />
                    <span>{{JSON.parse(item.readingjob.replace(/\n/g, '').replace(/\r/g, '')).jobexperience}}</span>
                    <el-divider direction="vertical" />
                    <span>{{JSON.parse(item.readingjob.replace(/\n/g, '').replace(/\r/g, '')).jobarea}}</span>
                  </div>
                  <div style="padding-top:8px;" v-if="JSON.parse(item.readingjob.replace(/\n/g, '').replace(/\r/g, '')).jobwelfare.length>0">
                    <el-button v-for="bitem in JSON.parse(item.readingjob.replace(/\n/g, '').replace(/\r/g, '')).jobwelfare.substring(0,JSON.parse(item.readingjob.replace(/\n/g, '').replace(/\r/g, '')).jobwelfare.length-1).split(',')" :key="bitem" text bg size="small">{{ bitem }}</el-button>
                   </div>
                   </div>
                  </el-col>
                <el-col :span="8" class="retaincompany">
                    <div><el-link class="el-link2" :underline="false" :href="'/companyitem?id='+JSON.parse(item.readingcompany.replace(/\n/g, '').replace(/\r/g, '')).companyusersid" target="_blank">{{JSON.parse(item.readingcompany.replace(/\n/g, '').replace(/\r/g, '')).companyname}}</el-link></div>
                    <div>
                    <span>{{JSON.parse(item.readingcompany.replace(/\n/g, '').replace(/\r/g, '')).companyindustry}}</span>
                    <el-divider direction="vertical" />
                    <span>{{JSON.parse(item.readingcompany.replace(/\n/g, '').replace(/\r/g, '')).companytype}}</span>
                    <el-divider direction="vertical" />
                    <span>{{JSON.parse(item.readingcompany.replace(/\n/g, '').replace(/\r/g, '')).companyemployees}}</span>
                    </div>
                  </el-col>
                  <el-divider />
              </el-row>
              </div>
              </div>
          <!-- -------------------------------------------- -->
      </el-tab-pane>
            </el-tabs>
        </el-col>
      </el-row>
</div>
</template>

<script>
import { rreadings, getuserprofile } from '@/api/user'
import token from '@/utils/token'
export default {
  name: 'readingIndex',
  components: {},
  data () {
    return {
      boxheight: 518,
      total: 0,
      currentpage: 1,
      list: []
    }
  },
  created () {
    this.getusers()
    this.boxheight = document.documentElement.clientHeight
    this.getlist(this.currentpage)
  },
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.status === 200) {
          if (res.data.resultbody != null) {
            this.users = res.data.resultbody
            if (this.users.usersstatus > 0) {
              alert('您好！帐号锁定了！')
              token.settoken('utoken', '')
              this.$router.push('/login')
            }
            if (this.users.userstype === 1) {
              if (this.users.usersinfostatus === 0) {
                if (this.$router.currentRoute._rawValue.path !== '/resume') {
                  alert('您好！简历信息不完善尽快完善简历信息！')
                  this.$router.push('/resume')
                }
              }
            } else {
              this.$router.push('/companys/home')
            }
          } else {
            token.settoken('utoken', '')
            this.$router.push('/login')
          }
        } else {
          alert(res.data.message + '===>outss')
          token.settoken('utoken', '')
          this.$router.push('/login')
        }
      })
    },
    getlist () {
      rreadings(this.currentpage, 1).then(res => {
        if (res.data.resultbody.resultlist != null) {
          if (this.currentpage === 1) {
            this.total = res.data.resultbody.total
          }
          this.list = res.data.resultbody.resultlist
        }
      })
    },
    pagechange (p) {
      if (this.page !== p) {
        this.currentpage = p
        this.getlist(p)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.statusbox{
    .el-image {width:52px;height:52px;}
}
.containerbox {
  border-radius: 0px;
}
.listbox
{
  font-size: 14px;line-height:22px;
  .idbox{margin-right: 12px;}
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
.el-link{font-size: 14px;font-weight: bold; color: #333333;}
.el-link2{font-size: 12px;font-weight:normal;color:#666666;}
.el-divider {
    margin-top:8px;
    margin-bottom: 12px;
  }
  .xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
}
</style>
