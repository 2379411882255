<template>
  <app-header v-if="header_show"></app-header>
  <router-view />
  <app-foot v-if="foot_show"></app-foot>
</template>

<script>
import Header from './components/homehendermenu'
import Foot from './components/homefoot'
export default {
  name: 'appIndex',
  components: {
    'app-header': Header,
    'app-foot': Foot
  },
  data () {
    return {
      header_show: false,
      foot_show: false,
      isCollapse: false,
      searchtext: ''
    }
  },
  methods: {
    handleOpen (key, keyPath) {},
    handleClose (key, keyPath) {},
    // 是否显示头部
    hendersetshow (bool) {
      this.header_show = bool
    },
    // 是否显示底部
    footsetshow (bool) {
      this.foot_show = bool
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
</style>
