<template>
<h4 style="text-align: center;padding:60px;">稍等。。。。</h4>
</template>

<script>

import { getuserprofile } from '@/api/user'
import token from '@/utils/token'
export default {
  name: 'userIndexs',
  data () {
    return {
      users: {}
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getusers()
  },
  mounted () {},
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.status === 200) {
          if (res.data.resultbody != null) {
            this.users = res.data.resultbody
            if (this.users.usersstatus > 0) {
              alert('您好！帐号锁定了！')
              token.settoken('utoken', '')
              this.$router.push('/login')
            } else {
              if (this.users.userstype === 1) {
                if (this.users.usersinfostatus === 0) {
                  if (this.$router.currentRoute._rawValue.path !== '/resume') {
                    alert('您好！简历信息不完善尽快完善简历信息！')
                    this.$router.push('/resume')
                  } else {
                    this.$router.push('/resumes/home')
                  }
                }
              } else {
                this.$router.push('/companys/home')
              }
            }
          } else {
            token.settoken('utoken', '')
          }
        } else {
          alert(res.data.message + '')
          token.settoken('utoken', '')
          this.$router.push('/login')
        }
      })
    },
    loginout () {
      token.settoken('utoken', '')
      this.$router.push('/login')
    }
  }
}
</script>
