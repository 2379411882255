import { createRouter, createWebHistory } from 'vue-router'
import home from '@/views/home'
import homejobs from '@/views/home/jobsindex.vue'
import homejob from '@/views/home/jobsindexs.vue'
import homeresumes from '@/views/home/resumesindex.vue'
import homearticles from '@/views/home/articles.vue'
import homearticlesm from '@/views/home/articlesm.vue'
import homesenior from '@/views/home/senior.vue'
import resumeshome from '@/views/resumes/home'
import userindex from '@/views/user/indexs'
import login from '@/views/login'
import print from '@/views/print'
import resumeitem from '@/views/itemresume'
import companyitem from '@/views/itemcompany'
import resumeitembasic from '@/views/itemresumebasic'
import companyitembasic from '@/views/itemcompanybasic'
import jobitem from '@/views/itemjob'
import reg from '@/views/reg'
import reguser from '@/views/reguser'
import userMain from '@/views/user'
import chats from '@/views/chat/index'
import apply from '@/views/resumes/apply/index.vue'
import collection from '@/views/resumes/collection'
import invite from '@/views/resumes/invite'
import reading from '@/views/resumes/reading'
import readingme from '@/views/resumes/readingme'
import resume from '@/views/resumes/resume'
import resumes from '@/views/resumes/resume/resume.vue'
import companyshome from '@/views/companys/home'
import companyscompany from '@/views/companys/company'
import companysjobs from '@/views/companys/jobs'
import companysjobse from '@/views/companys/jobse'
import companysapply from '@/views/companys/apply'
import companysinvite from '@/views/companys/invite'
import companyscollection from '@/views/companys/collection'
import companysreading from '@/views/companys/reading'
import companysreadingme from '@/views/companys/readingme'
import companysaccounts from '@/views/companys/accounts'
import companyadvertisement from '@/views/companys/advertisement'
import companysearch from '@/views/companys/search'
import resumesearch from '@/views/resumes/search'

import token from '@/utils/token'
import { getuserprofile } from '@/api/user'

const routes = [
  {
    path: '/',
    component: home
  },
  {
    path: '/jobs',
    component: homejob
  },
  {
    path: '/jobssearch',
    component: homejobs
  },
  {
    path: '/resumessearch',
    component: homeresumes
  },
  {
    path: '/senior',
    component: homesenior
  },
  {
    path: '/articles',
    component: homearticles
  },
  {
    path: '/articlesm',
    component: homearticlesm
  },
  {
    path: '/login',
    component: login
  },
  {
    path: '/reg',
    component: reg
  },
  {
    path: '/reguser',
    component: reguser
  },
  {
    path: '/print',
    component: print
  },
  {
    path: '/resumeitem',
    component: resumeitem
  },
  {
    path: '/companyitem',
    component: companyitem
  },
  {
    path: '/resumeitembasic',
    component: resumeitembasic
  },
  {
    path: '/companyitembasic',
    component: companyitembasic
  },
  {
    path: '/jobitem',
    component: jobitem
  },
  {
    path: '/resumes',
    component: resumes
  },
  // --沟通---------
  {
    path: '/chats',
    component: chats
  },
  {
    path: '/user',
    component: userMain,
    children: [
      {
        path: '/',
        component: userindex
      },
      {
        path: '/resumes/home',
        component: resumeshome
      },
      {
        path: '/apply',
        component: apply
      },
      {
        path: '/collection',
        component: collection
      },
      {
        path: '/invite',
        component: invite
      },
      {
        path: '/reading',
        component: reading
      },
      {
        path: '/readingme',
        component: readingme
      },
      {
        path: '/resume',
        component: resume
      },
      {
        path: '/resumes/search',
        component: resumesearch
      },
      // --企业管理------------------
      {
        path: '/companys/home',
        component: companyshome
      },
      {
        path: '/companys/company',
        component: companyscompany
      },
      {
        path: '/companys/jobs',
        component: companysjobs
      },
      {
        path: '/companys/jobse',
        component: companysjobse
      },
      {
        path: '/companys/apply',
        component: companysapply
      },
      {
        path: '/companys/invite',
        component: companysinvite
      },
      {
        path: '/companys/collection',
        component: companyscollection
      },
      {
        path: '/companys/reading',
        component: companysreading
      },
      {
        path: '/companys/readingme',
        component: companysreadingme
      },
      {
        path: '/companys/accounts',
        component: companysaccounts
      },
      {
        path: '/companys/advertisement',
        component: companyadvertisement
      },
      {
        path: '/companys/search',
        component: companysearch
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  //   window.location.href = 'http://m.ordercn.cn'
  // }

  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path === '/' || to.path === '/senior' || to.path === '/articles' || to.path === '/articlesm' || to.path === '/jobssearch' || to.path === '/jobs' || to.path === '/resumessearch' || to.path === '/reg' || to.path === '/reguser' || to.path === '/login' || to.path === '/resumeitem' || to.path === '/jobitem' || to.path === '/companyitem') {
    next()
  } else {
    getuserprofile().then(res => {
      if (res.data.status === 200) {
        if (res.data.resultbody.usersid != null) {
          // alert(JSON.stringify(res.data))
          if (res.data.resultbody.usersid > 0) {
            next()
          } else {
            token.settoken('utoken', '')
            top.location.href = '/login'
            // next('/login')
          }
        } else {
          token.settoken('utoken', '')
          top.location.href = '/login'
          // next('/login')
        }
      } else {
        alert(res.data.message)
        token.settoken('utoken', '')
        top.location.href = '/login'
        // next('/login')
      }
    }).catch(res => {
      token.settoken('utoken', '')
      top.location.href = '/login'
      // next('/login')
    })
  }
})

export default router
