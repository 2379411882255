<template>
  <div class="collectioncontainer">
    <el-row :gutter="30">
        <el-col :span="24" style="padding-left: 42px;">
          <el-row :gutter="20" style="margin-bottom: 8px;">
            <el-col :span="24" style="text-align: right;">
                  <el-button v-if="editshow=='list'" @click="editedit('edit')" color="#000000"><div><el-icon style="margin-right: 3px;"><Plus /></el-icon>添加</div></el-button>
                  <el-button v-if="editshow=='edit'" @click="editedit('list')" color="#000000"><div><el-icon style="margin-right: 3px;"><Back /></el-icon>返回</div></el-button>
            </el-col>
          </el-row>
           <!-- -------------------------------------------- -->
          <div v-if="editshow=='list'" v-loading="loadingmain">
            <el-tabs v-model="activeName" type="card"  @tab-click="tabsclick">
              <!-- pagers -------------------------------------------- -->
              <el-row gutter="2" style="margin-bottom: 8px;">
                <el-col :span="18">
                  <div style="text-align:left; " v-if="jobtype==0">
                    <el-button color="#be002f" @click="jobupdate" size="small">刷新</el-button>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div style="text-align:right;">
                    <el-pagination style="float: right;" small :current-page="currentpage" background :total="total" :page-size="30" pager-count="5" layout="prev, pager, next" @current-change="pagechange"/>
                  </div>
                </el-col>
              </el-row>
              <!-- pagers -------------------------------------------- -->
              <el-tab-pane label="招聘职位" name="first" class="retainlist">
                <div class="scrollbarbox" :style="'height:'+(boxheight-268)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;" v-if="jobtype==0">
                <el-row gutter="12" v-for="item in joblist" :key="item.jobid" style="width:98%;margin-left: 1%;">
                  <el-col :span="21" class="retaininfo">
                  <div>
                    <input type="checkbox" class="pidbox" name="pid" :value="item.jobid" v-if="item.jobhide==0" />
                    <el-link :underline="false" :href="'/jobitem?id='+item.jobid" target="_blank" style="margin-left: 20px;">{{item.jobname}}</el-link>
                  <span style="margin-left: 28px;">{{ item.jobdates.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}</span>
                  <span class="pinbibox"><el-image src="/images/yipingbi.png" v-if="item.jobhide!=0" /></span>
                  </div>
                  <div class="infovv">
                    <span class="xinzi" v-if="item.jobsalarys!=''">￥{{ item.jobsalarys }}<el-divider direction="vertical" /></span>
                    <span class="xinzi" v-else>￥{{ item.jobsalary }}<el-divider direction="vertical" /></span>
                    <span>{{ item.jobcount }}人<el-divider direction="vertical" /></span>
                    <span>{{ item.jobexperience }}<el-divider direction="vertical" /></span>
                    <span>{{ item.jobeducation }}<el-divider direction="vertical" /></span>
                    <span>{{ item.jobarea }}<el-divider direction="vertical" /></span>
                    <span>{{ item.jobcategory }}{{ item.jobcategorys }}</span>
                  </div>
                  <div style="padding-top:8px;" v-if="item.jobwelfare!='undefined'">
                    <span v-for="bitem in item.jobwelfare.substring(0,item.jobwelfare.length).split(',')" :key="bitem">
                    <el-button  text bg size="small" v-if="bitem!=''" style="margin-right: 12px;">{{ bitem }}</el-button>
                    </span>
                  </div>
                  </el-col>
                  <el-col :span="3" class="retainedit">
                    <div style="text-align:right; margin-top: 8px;">
                      <el-button  color="#003472" size="small" round style="font-size:12px;" @click="jobedit(1,item)" v-if="item.jobhide==0"><el-icon style="margin-right:6px;"><Hide /></el-icon>屏蔽 </el-button>
                      <el-button  color="#003472" size="small" round style="font-size:12px;" @click="jobedit(2,item)" v-else><el-icon style="margin-right:6px;"><View /></el-icon>展示 </el-button><br />
                      <el-button  color="#003472" size="small" round style="font-size:12px;" @click="jobedit(3,item)" ><el-icon style="margin-right:6px;"><Edit /></el-icon>编辑</el-button><br />
                      <el-button  color="#be002f" size="small" round style="font-size:12px;" @click="jobedit(4,item)"><el-icon style="margin-right:6px;"><Delete /></el-icon>删除</el-button>
                    </div>
                  </el-col>
                  <el-divider />
                </el-row>
                </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="职位回收站" name="second" class="retainlist">
                <div class="scrollbarbox" :style="'height:'+(boxheight-268)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;" v-if="jobtype==2">
                <el-row gutter="12" v-for="ditem in joblistdel" :key="ditem.jobsid" style="width:98%;margin-left: 1%;">
                  <el-col :span="21" class="retaininfo">
                  <div><el-link :underline="false" href="#">{{ditem.jobname}}</el-link>
                  <span class="pinbibox"><el-image src="/images/yipingbi.png" v-if="ditem.jobhide!=0" /></span>
                  </div>
                  <div class="infovv">
                    <span class="xinzi">￥{{ ditem.jobsalary }}<el-divider direction="vertical" /></span>
                    <span>{{ ditem.jobcount }}人<el-divider direction="vertical" /></span>
                    <span>{{ ditem.jobexperience }}<el-divider direction="vertical" /></span>
                    <span>{{ ditem.jobeducation }}<el-divider direction="vertical" /></span>
                    <span>{{ ditem.jobarea }}<el-divider direction="vertical" /></span>
                    <span>{{ ditem.jobcategory }}{{ ditem.jobcategorys }}</span>
                  </div>
                  <div style="padding-top:8px;" v-if="ditem.jobwelfare.length>0">
                    <el-button v-for="bitem in ditem.jobwelfare.substring(0,ditem.jobwelfare.length-1).split(',')" :key="bitem" text bg size="small">{{ bitem }}</el-button>
                  </div>
                  </el-col>
                  <el-col :span="3" class="retainedit">
                    <div style="text-align:right; margin-top: 8px;">
                      <el-button  color="#003472" size="small" round style="font-size:12px;" @click="jobedit(5,ditem)"><el-icon style="margin-right:6px;"><RefreshRight /></el-icon>恢复职位</el-button><br />
                      <el-button  color="#be002f" size="small" round style="font-size:12px;" @click="jobedit(6,ditem)"><el-icon style="margin-right:6px;"><Delete /></el-icon>彻底删除</el-button>
                    </div>
                  </el-col>
                  <el-divider />
                </el-row>
                </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- -------------------------------------------- -->
          <div v-if="editshow=='edit'">
            <el-tabs v-model="activeNamee" type="card" >
              <el-tab-pane label="职位编辑" name="firste" class="retainlist">
                  <el-form style="width:888px;" size="default" label-width="82px" label-position="right" class="forminfo" ref="companyjobs" :model="companyjobs" :rules="jobsrules" >
                      <el-row :gutter="20">
                        <el-col :span="12">
                       <el-form-item label="职位名称" prop="jobname">
                            <el-input type="text" v-model="companyjobs.jobname"/>
                        </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="职位类型" prop="jobtype">
                            <el-radio-group v-model="companyjobs.jobtype">
                              <el-radio label="全职" />
                              <el-radio label="兼职" />
                              <el-radio label="实习" />
                            </el-radio-group>
                          </el-form-item>
                        </el-col>
                      </el-row>
                     <el-row :gutter="20">
                        <el-col :span="6" style="display: none;">
                            <el-form-item label="所属行业" :filterable="true">
                          <el-select v-model="companyjobs.jobindustry" placeholder="选择行业">
                                <el-option v-for="item in hylist" :key="item.categoryid" :value="item.categoryname" :label="item.categoryname"/>
                            </el-select>
                        </el-form-item>
                        </el-col>
                        <el-col :span="20">
                          <el-form-item label="工作类别" prop="jobcategory" style="width:100%;">
                            <el-button color="#4B4B4B" round size="small" style="margin-right:12px;" @click="categoryinit(1)">选择</el-button>
                          {{ jobcategorylist.toString() }}
                              <!-- <el-cascader v-model="companyjobs.jobcategory" placeholder="选择类别" :options="lblistinfo" style="width:100%;" /> -->
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                          <el-form-item label="自定类别" prop="jobcategorys">
                              <el-input type="text" v-model="companyjobs.jobcategorys" placeholder="如：会计、出纳" />
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="20">
                            <el-form-item label="工作地区" prop="jobarea">
                              <el-button color="#4B4B4B" round size="small" style="margin-right:12px;" @click="categoryinit(2)">选择</el-button>
                              {{ jobarealist.toString() }}

                              <!-- <el-cascader v-model="companyjobs.jobarea" :options="dqlistinfo" placeholder="选择地区" /> -->
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="工作经验" prop="jobexperience">
                              <el-select  v-model="companyjobs.jobexperience" placeholder="选择经验">
                                <span v-for="item in jylist" :key="item.categoryid">
                                  <el-option v-if="item.categoryroot==0" :value="item.categoryname"><b style="padding-left: 10px;">{{ item.categoryname }}</b></el-option>
                                  <el-option v-else :value="item.categoryname" style="padding-left: 20px;"><en style="padding-left: 20px;">{{ item.categoryname }}</en></el-option>
                                </span>
                              </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="学历要求" prop="jobeducation">
                            <el-select  v-model="companyjobs.jobeducation" placeholder="选择学历">
                                <span v-for="item in xllist" :key="item.categoryid">
                                  <el-option v-if="item.categoryroot==0" :value="item.categoryname"><b style="padding-left: 10px;">{{ item.categoryname }}</b></el-option>
                                  <el-option v-else :value="item.categoryname" style="padding-left: 20px;"><en style="padding-left: 20px;">{{ item.categoryname }}</en></el-option>
                                </span>
                              </el-select>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <el-form-item label="薪资待遇">
                              <el-select  v-model="companyjobs.jobsalary" placeholder="选择薪资">
                                <span v-for="item in xzlist" :key="item.categoryid">
                                  <el-option v-if="item.categoryroot==0" :value="item.categoryname"><b style="padding-left: 10px;">{{ item.categoryname }}</b></el-option>
                                  <el-option v-else :value="item.categoryname" style="padding-left: 20px;"><en style="padding-left: 20px;">{{ item.categoryname }}</en></el-option>
                                </span>
                              </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="自定薪资" prop="jobsalarys">
                              <el-input type="text" v-model="companyjobs.jobsalarys"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="招聘部门" prop="jobdepartment">
                              <el-select  v-model="companyjobs.jobdepartment" placeholder="选择部门">
                                <span v-for="item in departments" :key="item.departmentid">
                                  <el-option :value="item.departmentname">{{ item.departmentname }}</el-option>
                                </span>
                              </el-select>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="6">
                          <el-form-item label="年龄要求" prop="jobage">
                              <el-input type="text" v-model="companyjobs.jobage"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="招聘人数" prop="jobcount">
                              <el-input type="text" v-model="companyjobs.jobcount" oninput="value=value.replace(/[^1-9.]/g,'')"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="性别要求" prop="jobgender">
                              <el-radio-group v-model="companyjobs.jobgender">
                              <el-radio label="不限" />
                              <el-radio label="男" />
                              <el-radio label="女" />
                            </el-radio-group>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="福利待遇" prop="jobwelfare">
                              <el-checkbox-group size="small" v-model="companyjobs.jobwelfare">
                                <el-checkbox-button label="福利五险一金" name="jobwelfare" />
                                <el-checkbox-button label="包吃" name="jobwelfare" />
                                <el-checkbox-button label="包住" name="jobwelfare" />
                                <el-checkbox-button label="周末双休" name="jobwelfare" />
                                <el-checkbox-button label="年底双薪" name="jobwelfare" />
                                <el-checkbox-button label="房补" name="jobwelfare" />
                                <el-checkbox-button label="话补" name="jobwelfare" />
                                <el-checkbox-button label="交通补助" name="jobwelfare" />
                                <el-checkbox-button label="饭补" name="jobwelfare" />
                                <el-checkbox-button label="加班补助" name="jobwelfare" />
                              </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="其他福利" prop="jobwelfares">
                              <el-input type="text" v-model="companyjobs.jobwelfares"/>
                            </el-form-item>
                        </el-col>
                        </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="职位描述" prop="jobcontent">
                              <el-input type="textarea" v-model="companyjobs.jobcontent" :rows="12" />
                              <div>
                                <el-button color="#888888" plain @click="setcontent(1)" size="small">模板一</el-button>
                                <el-button color="#888888" plain @click="setcontent(2)" size="small">模板二</el-button>
                                <el-button color="#888888" plain @click="setcontent(3)" size="small">模板三</el-button>
                                <el-button color="#888888" plain @click="setcontent(4)" size="small">模板四</el-button>
                                <el-button color="#888888" plain @click="setcontent(5)" size="small">模板五</el-button>
                              </div>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24" style="text-align: right;">
                          <el-button color="#ba181b" @click="sendjob('companyjobs')" :loading="loading">保存</el-button>
                        </el-col>
                      </el-row>
                  </el-form>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- -------------------------------------------- -->
        </el-col>
    </el-row>
  </div>
  <!-- 职位 ----------------------------------------- -->
  <el-dialog v-model="dialogVisiblezw" title="选择职位"  width="918px" :before-close="handleClose">
    <div class="categoryname">
        <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22">
            <el-tag @click="categoryclear(1,citem)"  type="info" round  v-for="citem in jobcategorylist" :key="citem">{{ citem }} <span class="cleartext">x</span></el-tag>
          </el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisiblezw = false" round>确定</el-button></el-col>
        </el-row>
    </div>
    <div class="categorylist" style="width: 100%;">
      <el-scrollbar height="400px" style="width: 100%;">
        <el-row :gutter="20" style="width:98%;margin-left: 1%;">
          <el-col :span="6" class="rootiteminfoa"  v-for="(citemroot,index) in zwlistroot" :key="citemroot.categoryid" :class="index%2===0?'itemboxaa':'itemboxbb'">

          <el-popover placement="bottom-start" title="" :width="718" trigger="hover">
            <template #reference>
              {{ citemroot.categoryname }}
            </template>
            <el-col :span="24" style="overflow-y: visible; overflow-x: hidden;max-height:298px;">
              <div class="zcitem3" @click="categoryset(1,citem.categoryname)" v-for="citem in getzlist(1,citemroot.categoryid)" :key="citem.categoryid"><span>{{ citem.categoryname }}</span></div>
            </el-col>
          </el-popover>

          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </el-dialog>

  <!-- 地区 ----------------------------------------- -->
  <el-dialog v-model="dialogVisibledq" title="选择地区"  width="918px" :before-close="handleClose">
    <div class="categoryname">
      <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22">
           <el-tag @click="categoryclear(2,citem)"  type="info" round  v-for="citem in jobarealist" :key="citem">{{ citem }} <span class="cleartext">x</span></el-tag>
          </el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisibledq = false" round>确定</el-button></el-col>
      </el-row>
      </div>
      <div class="categorylist">
      <el-scrollbar height="400px">
        <el-row :gutter="20" v-for="(citemroot,index) in dqlistroot" :key="citemroot.categoryid" :class="index%2===0?'itemboxa':'itemboxb'">
          <el-col :span="24" class="rootiteminfo">
            {{ citemroot.categoryname }}
          </el-col>
          <el-col :span="24" class="iteminfo">
            <div class="zcitem" @click="categoryset(2,citem.categoryname)" v-for="citem in getzlist(2,citemroot.categoryid)" :key="citem.categoryid"><span>{{ citem.categoryname }}</span></div>
          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </el-dialog>
<!--  ----------------------------------------- -->
</template>
<script>
import { cjobmanage, category, departments, jobsput, jobsdel, companys, jobedit, cjobitemmanage } from '@/api/user'
export default {
  name: 'jobseIndex',
  components: {},
  props: {},
  data () {
    return {
      loadingmain: true,
      boxheight: 518,
      loading: false,
      editshow: 'list',
      activeName: 'first',
      activeNamee: 'firste',
      hylist: [],
      lblist: [],
      lblistinfo: [],
      dqlist: [],
      dqlistinfo: [],
      jylist: [],
      xllist: [],
      xzlist: [],
      departments: [],
      total: 0,
      currentpage: 1,
      jobtype: 0,
      joblist: [],
      joblistdel: [],
      companyjobss: { jobgender: '不限', jobtype: '全职', jobcount: 1, jobarea: '' },
      companyjobs: { jobgender: '不限', jobtype: '全职', jobcount: 1, jobarea: '' },
      jobsrules: {
        jobname: [
          { required: true, message: '职位名称不能为空', trigger: 'blur' }
        ],
        jobtype: [
          { required: true, message: '工作类型必须选择', trigger: 'blur' }
        ],
        // jobindustry: [
        //   { required: true, message: '工作行业必须选择', trigger: 'blur' }
        // ],
        jobcategory: [
          { required: true, message: '工作类别必须选择', trigger: 'blur' }
        ],
        jobarea: [
          { required: true, message: '工作地区必须选择', trigger: 'blur' }
        ],
        jobexperience: [
          { required: true, message: '工作经验必须选择', trigger: 'blur' }
        ],
        jobeducation: [
          { required: true, message: '学历必须选择', trigger: 'blur' }
        ],
        // jobsalary: [
        //   { required: true, message: '薪资待遇必须选择', trigger: 'blur' }
        // ],
        // jobdepartment: [
        //   { required: true, message: '招聘部门必须选择', trigger: 'blur' }
        // ],
        jobage: [
          { required: true, message: '年龄必须填写', trigger: 'blur' }
        ],
        jobcount: [
          { required: true, message: '招聘人数必须填写', trigger: 'blur' }
        ],
        jobcontent: [
          { required: true, message: '职位描述不能为空', trigger: 'blur' }
        ]
      },
      companys: {},
      jobarealist: [],
      jobcategorylist: [],
      dialogVisiblezw: false,
      dialogVisibledq: false,
      zwlist: [],
      // dqlist: [],
      zwlistroot: [],
      dqlistroot: []
    }
  },
  created () {
    this.getcompanys()
    this.boxheight = document.documentElement.clientHeight
    this.getlist(this.currentpage)
    if (this.$route.query.edit != null) {
      this.editedit('edit')
      if (this.$route.query.itemid != null) {
        this.getitemedit(this.$route.query.itemid)
      }
    }
  },
  methods: {
    getitemedit (id) {
      // cjobitemmanage
      cjobitemmanage(id).then(res => {
        if (res.data.status === 200) {
          const item = res.data.resultbody
          this.jobedit(3, item)
        }
        // alert(res.data.resultbody)
      })
    },
    getcompanys () {
      companys().then(res => {
        if (res.data.status === 200) {
          this.companys = res.data.resultbody
          this.companys.companycontent = this.companys.companycontent.replaceAll(/<br\/>/ig, '\r\n')
          this.companyjobs.jobcompanyname = this.companys.companyname
          this.companyjobss.jobcompanyname = this.companys.companyname
          this.companyjobs.jobindustry = this.companys.companyindustry
          this.companyjobs.jobcompanytype = this.companys.companytype
          this.companyjobss.jobindustry = this.companys.companyindustry
          this.companyjobss.jobcompanytype = this.companys.companytype

          this.companyjobs.jobsalary = '2000-2500'
          this.companyjobss.jobsalary = '2000-2500'

          if (this.companys.companytype.length === 0 || this.companys.companyarea.length === 0 || this.companys.companyaddress.length === 0) {
            this.$message({
              message: '::>_<:: 完善企业信息再发布职位吧！',
              type: 'info'
            })
            this.$router.push('/companys/company')
          }
          if (this.companys.companystatus < 0) {
            this.$message({
              message: '::>_<:: 完善企业信息联系客服审核后再发布岗位吧！！',
              type: 'error'
            })
            this.$router.push('/companys/company')
          } else {
            let ckserver = true
            const dateinfo = this.companys.companyserver
            const currentDate = new Date()
            currentDate.setHours(0, 0, 0, 0) // 设置为今天的 00:00:00
            const inputDate = new Date(dateinfo)

            if (inputDate < currentDate) {
              ckserver = false
            }
            if (ckserver === false) {
              this.$message({
                message: '::>_<:: 您好!您的会员权限已用完，开通请联系客服（0871-67165818）！',
                type: 'error'
              })
              this.$router.push('/companys/company')
            }
          }
          this.getdepartments()
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      })
    },
    getlist () {
      this.loadingmain = true
      cjobmanage(this.jobtype, this.currentpage, '', 20, 3).then(res => {
        if (res.data.resultbody.resultlist != null) {
          if (this.currentpage === 1) {
            this.total = res.data.resultbody.total
          }
          if (this.jobtype === 0) {
            this.joblist = res.data.resultbody.resultlist
          } else {
            this.joblistdel = res.data.resultbody.resultlist
          }
        }
        setTimeout(() => { this.loadingmain = false }, 300)
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    pagechange (p) {
      if (this.page !== p) {
        this.currentpage = p
        this.getlist(p)
      }
    },
    tabsclick (v) {
      if (v.props.name === 'first') {
        // 招聘中的职位
        this.jobtype = 0
      } else {
        // 职位回收站
        this.jobtype = 2
      }
      this.getlist()
    },
    sendjob (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.companyjobs.jobcount = parseInt(this.companyjobs.jobcount)
          if (this.companyjobs.jobwelfare != null) {
            this.companyjobs.jobwelfare = this.companyjobs.jobwelfare.toString()
          }
          if (this.companyjobs.jobarea != null) {
            this.companyjobs.jobarea = this.companyjobs.jobarea.toString()
          }
          if (this.companyjobs.jobcategory != null) {
            this.companyjobs.jobcategory = this.companyjobs.jobcategory.toString()
          }

          if (this.companyjobs.jobcount === 0) {
            this.companyjobs.jobcount = 1
          }
          // alert(JSON.stringify(this.companyjobs))
          jobsput(this.companyjobs).then(res => {
            if (res.data.status === 200) {
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              this.$router.push('/companys/jobs')
              // this.getlist()
              // this.companyjobs = this.companyjobss
              // this.editshow = 'list'
            }
            this.loading = false
          }).catch(res => {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
              type: 'error'
            })
          })
          this.loading = false
        } else {
          this.loading = false
          return false
        }
      })
    },
    getcategory (t) {
      this.getrootlit(t, 0)
      category(t, 0).then(res => {
        if (t === 9) {
          this.hylist = []
        } else if (t === 1) {
          this.lblist = []
        } else if (t === 2) {
          this.dqlist = []
        } else if (t === 3) {
          this.xzlist = []
        } else if (t === 5) {
          this.jylist = []
        } else if (t === 7) {
          this.xllist = []
        } else if (t === 8) {
          this.gsxzlist = []
        } else if (t === 10) {
          this.gsrslist = []
        }
        for (let i = 0; i < res.data.resultbody.resultlist.length; i++) {
          const citem = { categoryid: res.data.resultbody.resultlist[i].categoryid, categoryname: res.data.resultbody.resultlist[i].categoryname, categoryroot: res.data.resultbody.resultlist[i].categoryroot }
          if (t === 9) {
            this.hylist.push(citem)
          } else if (t === 1) {
            this.lblist.push(citem)
          } else if (t === 2) {
            this.dqlist.push(citem)
          } else if (t === 3) {
            this.xzlist.push(citem)
          } else if (t === 5) {
            this.jylist.push(citem)
          } else if (t === 7) {
            this.xllist.push(citem)
          } else if (t === 8) {
            this.gsxzlist.push(citem)
          } else if (t === 10) {
            this.gsrslist.push(citem)
          }
        }
        if (t === 1) {
          for (let i = 0; i < this.lblist.length; i++) {
            const citem = this.lblist[i]
            if (citem.categoryroot === 0) {
              const ccitem = { value: citem.categoryname, label: citem.categoryname, children: [] }
              for (let ii = 0; ii < this.lblist.length; ii++) {
                const cccitem = this.lblist[ii]
                if (cccitem.categoryroot === citem.categoryid) {
                  const ccccitem = { value: cccitem.categoryname, label: cccitem.categoryname, children: [] }
                  ccitem.children.push(ccccitem)
                }
              }
              this.lblistinfo.push(ccitem)
            }
          }
        }

        if (t === 2) {
          for (let i = 0; i < this.dqlist.length; i++) {
            const citem = this.dqlist[i]
            if (citem.categoryroot === 0) {
              const ccitem = { value: citem.categoryname, label: citem.categoryname, children: [] }
              for (let ii = 0; ii < this.dqlist.length; ii++) {
                const cccitem = this.dqlist[ii]
                if (cccitem.categoryroot === citem.categoryid) {
                  const ccccitem = { value: cccitem.categoryname, label: cccitem.categoryname, children: [] }
                  ccitem.children.push(ccccitem)
                }
              }
              this.dqlistinfo.push(ccitem)
            }
          }
        }
      })
    },
    getdepartments () {
      departments().then(res => {
        if (res.data.resultbody.resultlist != null) {
          this.departments = res.data.resultbody.resultlist
          // const incinfo = { departmentcontacts: this.companys.companycontacts, departmentcontent: '', departmentdate: '', departmentid: 0, departmentmail: this.companys.companymail, departmentname: this.companys.companyname, departmentphone: this.companys.companyusersphone, departmentusersid: 0 }
          // this.departments.unshift(incinfo)
        }
      })
    },
    jobedit (t, item) {
      if (t === 1) {
        // 屏蔽
        this.companyjobs = item
        this.companyjobs.jobhide = 1
        this.sendjobs()
      } else if (t === 2) {
        // 展示
        this.companyjobs = item
        this.companyjobs.jobhide = 0
        this.sendjobs()
      } else if (t === 3) {
        // 编辑
        this.getcategory(1)
        this.getcategory(2)
        this.getcategory(3)
        this.getcategory(5)
        this.getcategory(7)
        this.getcategory(9)
        this.companyjobs = item
        if (this.companyjobs.jobarea != null) {
          this.jobarealist = this.companyjobs.jobarea.split(',')
        }
        if (this.companyjobs.jobcategory != null) {
          this.jobcategorylist = this.companyjobs.jobcategory.split(',')
        }
        if (this.companyjobs.jobwelfare === '') {
          this.companyjobs.jobwelfare = []
        } else {
          this.companyjobs.jobwelfare = this.companyjobs.jobwelfare.split(',')
        }
        this.companyjobs.jobcontent = this.companyjobs.jobcontent.replaceAll(/<br \/>/ig, '\r\n')
        this.editshow = 'edit'
      } else if (t === 4) {
        // 删除
        const ck = confirm('确定删除码?')
        if (ck) {
          this.companyjobs = item
          this.companyjobs.jobstatus = 2
          this.sendjobs()
        }
      } else if (t === 5) {
        // 恢复
        this.companyjobs = item
        this.companyjobs.jobstatus = 0
        this.sendjobs()
        this.getlist()
      } else if (t === 6) {
        // 彻底删除
        const ck = confirm('确定删除码?')
        if (ck) {
          this.companyjobs = item
          jobsdel(this.companyjobs.jobid).then(res => {
            // alert(JSON.stringify(res.data))
            if (res.data.status === 200) {
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              this.getlist()
              this.companyjobs = this.companyjobss
              this.editshow = 'list'
            }
          }).catch(res => {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
              type: 'error'
            })
          })
        }
      }
    },
    sendjobs () {
      this.companyjobs.jobcount = parseInt(this.companyjobs.jobcount)
      if (this.companyjobs.jobwelfare != null) {
        this.companyjobs.jobwelfare = this.companyjobs.jobwelfare.toString()
      }
      if (this.companyjobs.jobarea != null) {
        this.companyjobs.jobarea = this.companyjobs.jobarea.toString()
      }
      this.companyjobs.jobcategory = this.companyjobs.jobcategory.toString()

      jobsput(this.companyjobs).then(res => {
        if (res.data.status === 200) {
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.companyjobs = { jobgender: '不限', jobtype: '全职', jobcount: 1 }
          this.editshow = 'list'
        }
        this.getlist()
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    editedit (tt) {
      if (tt === 'edit') {
        this.editshow = 'edit'
        this.companyjobs = { jobgender: '不限', jobtype: '全职', jobcount: 1 }
        this.getcategory(1)
        this.getcategory(2)
        this.getcategory(3)
        this.getcategory(5)
        this.getcategory(7)
        this.getcategory(9)
        if (this.companyjobs.jobarea != null) {
          this.jobarealist = this.companyjobs.jobarea.split(',')
        }
        if (this.companyjobs.jobcategory != null) {
          this.jobcategorylist = this.companyjobs.jobcategory.split(',')
        }
      } else if (tt === 'list') {
        this.joblist = []
        this.editshow = 'list'
        this.getlist(this.currentpage)
      }
    },
    jobupdate () {
      const idlist = []
      const arr = document.getElementsByName('pid')
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].checked) {
          idlist.push(arr[i].value)
        }
      }
      if (idlist.length > 0) {
        jobedit(idlist.toString(), 0).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
            for (let i = 0; i < arr.length; i++) {
              arr[i].checked = false
            }
            this.getlist(this.currentpage)
          } else {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        alert('(*^__^*)选择需要操作的数据!')
      }
    },
    setcontent (ct) {
      const va = '岗位职责\n1、销售管理职位，负责其功能领域内主要目标和计划；\n2、制定、参与或协助上层执行相关的政策和制度；\n3、负责部门的日常管理工作及部门员工的管理、指导、培训及评估；\n4、负责组织的销售运作，包括计划、组织、进度控制和检讨；\n5、建立和管理销售队伍，完成销售目标；\n6、分析和开发市场并搞好售后服务；\n7、访问和激励特许经销商进一步拓展市场。 \n\n任职资格\n1、大专以上学历；\n2、3年以上X行业销售管理经验；\n3、出色的市场分析洞察能力、具备全面深刻营销知识和技能；\n4、具备一定的管理领导能力和沟通协调能力。'
      const vb = '岗位职责\n1、中层管理职位，负责其功能领域内主要目标和计划，制定、参与或协助上层执行相关的政策和制度；\n2、负责部门的日常管理工作及部门员工的管理、指导、培训及评估；\n3、负责组织行政、后勤、保卫工作等的指导、协调、监督和管理；\n4、公司日常行政管理的运作（包括运送安排、邮件和固定的供给等等）；\n5、公司内部治安管理；\n6、行政管理的成本控制及水电管理等。\n\n任职资格\n1、大学专科以上，企业管理、行政管理、理工科专业；\n2、5年以上行政工作经验，其中3年以上行政管理工作经验，年龄28-45岁；\n3、在大规模正规化运作企业工作者优先；\n4、善于人际沟通协调，责任心强，考虑问题全面细致，性格开朗，有团队合作精神。'
      const vc = '岗位职责\n1、销售人员职位，在上级的领导和监督下定期完成量化的工作要求，并能独立处理和解决所负责的任务；\n2、管理客户关系，完成销售任务；\n3、了解和发掘客户需求及购买愿望，介绍自己产品的优点和特色；\n4、对客户提供专业的咨询；\n5、收集潜在客户资料；\n6、收取应收帐款。\n\n任职资格 \n1、专科及以上学历，市场营销等相关专业；\n2、2年以上销售行业工作经验，业绩突出者优先；\n3、性格外向、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧，具有亲和力；\n4、具备一定的市场分析及判断能力，良好的客户服务意识；\n5、有责任心，能承受较大的工作压力。'
      const vd = '岗位职责 \n1、专业人员职位，在上级的领导和监督下定期完成量化的工作要求，并能独立处理和解决所负责的任务；\n2、协助财务预算、审核、监督工作，按照公司及政府有关部门要求及时编制各种财务报表并报送相关部门；\n3、负责员工报销费用的审核、凭证的编制和登帐；\n4、对已审核的原始凭证及时填制记帐；\n5、准备、分析、核对税务相关问题；\n6、审计合同、制作帐目表格。\n\n任职资格 \n1、财务、会计专业中专以上学历，持有会计证；\n2、有财务会计工作经历者优先；\n3、熟悉会计报表的处理，会计法规和税法，熟练使用财务软件；\n4、良好的学习能力、独立工作能力和财务分析能力；\n5、工作细致，责任感强，良好的沟通能力、团队精神。'
      const ve = '岗位职责 \n1、普通工作人员职位，协助上级执行一般的不需较多工作经验的任务；\n2、直接为经理提供秘书服务；\n3、能独立处理突发事件，或当老板不在时能主动处理一些紧迫事务；\n4、与其他部门的经理进行沟通；\n5、确定并安排会议时间；\n6、负责会议材料的整理、存档工作；\n7、完成上级交给的其它事务性工作。\n\n任职资格\n1、公关、行政管理、企业管理等相关专业本科以上学历；\n2、三年以上总经理助理工作经验，有本领域工作经验者优先；\n3、知识结构较全面，具有丰富的管理经验，了解法律及财务方面的知识，能够迅速掌握与公司业务有关的各种知识；\n4、有较强的组织、协调、沟通、领导能力及人际交往能力以及敏锐的洞察力，具有很强的判断与决策能力，计划和执行能力；\n5、良好的团队协作精神，为人诚实可靠、品行端正；\n6、熟练使用办公软件。'
      if (ct === 1) {
        this.companyjobs.jobcontent = va
      } else if (ct === 2) {
        this.companyjobs.jobcontent = vb
      } else if (ct === 3) {
        this.companyjobs.jobcontent = vc
      } else if (ct === 4) {
        this.companyjobs.jobcontent = vd
      } else if (ct === 5) {
        this.companyjobs.jobcontent = ve
      }
    },
    categoryinit (t) {
      this.getcategory(t)
      if (t === 1) {
        this.dialogVisiblezw = true
      } else if (t === 2) {
        this.dialogVisibledq = true
      }
    },
    getrootlit (t, rootitemid) {
      if (t === 1) {
        this.zwlistroot = []
        const arr = this.lblist.filter(item => { return item.categoryroot === rootitemid })
        this.zwlistroot = arr
      } else if (t === 2) {
        this.dqlistroot = []
        const arr = this.dqlist.filter(item => { return item.categoryroot === rootitemid })
        this.dqlistroot = arr
      }
    },
    getzlist (t, rootitemid) {
      if (t === 1) {
        let zwlistnew = []
        zwlistnew = this.lblist.filter(item => { return item.categoryroot === rootitemid })
        return zwlistnew
      } else {
        let dqlistnew = []
        dqlistnew = this.dqlist.filter(item => { return item.categoryroot === rootitemid })
        return dqlistnew
      }
    },
    categoryclear (t, v) {
      if (t === 1) {
        const newlistinfo = []
        for (let i = 0; i < this.jobcategorylist.length; i++) {
          if (this.jobcategorylist[i] !== v) {
            newlistinfo.push(this.jobcategorylist[i])
          }
        }
        this.jobcategorylist = newlistinfo
        this.companyjobs.jobcategory = this.jobcategorylist.toString()
      } else if (t === 2) {
        const newlistinfo = []
        for (let i = 0; i < this.jobarealist.length; i++) {
          if (this.jobarealist[i] !== v) {
            newlistinfo.push(this.jobarealist[i])
          }
        }
        this.jobarealist = newlistinfo
        this.companyjobs.jobarea = this.jobarealist.toString()
      }
    },
    categoryset (t, v) {
      if (t === 1) {
        if (this.jobcategorylist.length > 2) {
          alert('最多只能选择三项')
        } else {
          if (this.jobcategorylist.indexOf(v) < 0) {
            this.jobcategorylist.push(v)
          }
        }
        this.companyjobs.jobcategory = this.jobcategorylist.toString()
      } if (t === 2) {
        if (this.jobarealist.length > 2) {
          alert('最多只能选择三项')
        } else {
          if (this.jobarealist.indexOf(v) < 0) {
            this.jobarealist.push(v)
          }
        }
        this.companyjobs.jobarea = this.jobarealist.toString()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.categoryname{
  border-top-color: #ececec;border-top-width: 1px;border-top-style: solid;line-height: 32px;
  border-bottom-color: #ececec;border-bottom-width: 1px;border-bottom-style: solid;
  margin-top: -30px; padding-top: 12px;padding-bottom: 16px;
  .el-tag { margin-right: 6px; margin-top: 3px; margin-bottom: 3px; color:#CC3333;height:25px;cursor: pointer; border-width:1px; border-color: #cc3333;background-color: #ffffff;}
}
.categorylist{font-size: 14px;line-height: 32px; padding-top: 12px; max-height: 418px;cursor: pointer;
  width:100%;
}
.rcitem{background-color: #666666;}
.zcitem{width:32%; margin-left: 1%; float: left;}
.zcitem2{width:9%; margin-left: 1%; float: left;}
.zcitem3{
  cursor:pointer;width:21%; margin-left: 1%; float: left; border: 1px #f0f0f0 solid;border-radius: 8px;margin-bottom: 8px; font-size: 12px;padding:6px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.zcitemroot{line-height: 42px;}

.cleartext{font-size: 16px;}

.rootiteminfo{
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}
.rootiteminfoa{
  font-size: 14px;
  text-align: center;
  font-size: 14px;
}
.iteminfo{
  justify-content: center;
  align-items: center;
}
.itemboxa {padding-left: 8px;background-color: #f8f8f8;width:100%;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 12px;padding-top: 12px;}
.itemboxb {padding-left: 8px;width:100%;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 12px;padding-top: 12px;}
.itemboxaa {padding-left: 8px;background-color: #f8f8f8;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 6px;padding-top: 6px;}
.itemboxbb {padding-left: 8px;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 6px;padding-top: 6px;}

.pinbibox{position:absolute;top:2px;right:118px;width:52px;height: 52px;border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}

.addbtn{
  width:52px;
  height:52px;
  border-radius: 52px;
  text-align: center;
  line-height: 15px;
}
.collectioncontainer {
  margin-top: 0px;
  border-radius: 0px;
}
.retainlist
{
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.retaininfo {
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;}
}
.retaininfoa {
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;}
}
.retaininfob {
  padding-top: 8px;
  padding-bottom: 8px;
    background-color: #f8f8f8;
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;}
}
.retainedit {
  text-align: center;
  align-items: center;
justify-content: center;
  .el-button {
    margin-bottom: 5px;
  }
}
.retainlist
{
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.regtitle1{padding-left: 20px;}
.xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
}
.rightyx {
  margin-left: 12px;
  margin-right:12px;
  text-align: left;
  font-size: 14px;
  color: #777777;
  line-height: 28px;
  .el-row {
    border-bottom-width:1px;
    border-bottom-style: solid;
    border-bottom-color:  #dddfe5;
    padding-top: 2px;
    padding-bottom: 8px;
  }
  .el-tab-pane {
    padding-left: 20px;
    .el-tag { margin-right: 6px; color:#222222;}
  }
}
</style>
