<template>
  <div class="containerbox">
    <el-row :gutter="2" style="width:100%;">
      <el-col :span="24">
          <el-tabs model-value="first" type="card">
            <el-tab-pane label="应聘记录" name="first" class="listbox">
          <!-- -------------------------------------------- -->
              <!-- pagers -------------------------------------------- -->
              <el-row gutter="2" style="margin-bottom: 8px;">
                <el-col :span="18">
                  <div style="text-align:left; ">
                    <el-button color="#000000" style="display: none;" @click="edit('01')" round>收藏</el-button>
                    <el-button color="#be002f" @click="edit('0')" size="small">删除</el-button>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div style="text-align:right;">
                    <el-pagination style="float: right;" small :current-page="currentpage" background :total="total" :page-size="12" pager-count="5" layout="prev, pager, next" @current-change="pagechange"/>
                  </div>
                </el-col>
              </el-row>
              <!-- pagers -------------------------------------------- -->
            <div class="scrollbarbox" :style="'height:'+(boxheight-228)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;">
              <el-row class="el-row" gutter="12" v-for="item in listinfo" :key="item.applyid">
                  <el-col :span="13">
                    <input type="checkbox" class="idbox" name="pid" :value="item.applyid" />
                    <el-link class="el-link" :underline="false"  :href="'/jobitem?id='+item.applyjobid" target="_blank">{{JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobname}}</el-link>
                  <div>
                    <span class="xinzi">￥{{JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobsalary}}</span>
                    <el-divider direction="vertical" />
                    <span>{{JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobexperience}}</span>
                    <el-divider direction="vertical" />
                    <span>{{JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobarea}}</span>
                  </div>
                  <div style="padding-top:8px;" v-if="JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobwelfare.length>0">
                    <el-button v-for="bitem in JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobwelfare.substring(0,JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobwelfare.length-1).split(',')" :key="bitem" text bg size="small">{{ bitem }}</el-button>
                   </div>
                  </el-col>
                  <el-col :span="9">
                    <div><el-link class="el-link2" :underline="false" :href="'/companyitem?id='+item.applycompanyid" target="_blank">{{JSON.parse(item.applycompanycontent.replace(/\n/g, '').replace(/\r/g, '')).companyname}}</el-link></div>
                    <div>
                    <span>{{JSON.parse(item.applycompanycontent.replace(/\n/g, '').replace(/\r/g, '')).companyindustry}}</span>
                    <br />
                    <span>{{JSON.parse(item.applycompanycontent.replace(/\n/g, '').replace(/\r/g, '')).companytype}}</span>
                    <el-divider direction="vertical" />
                    <span>{{JSON.parse(item.applycompanycontent.replace(/\n/g, '').replace(/\r/g, '')).companyemployees}}</span>
                    </div>
                  </el-col>
                  <el-col :span="2" style="text-align: center;">
                    <span class="statusbox">
                    <el-image src="/images/daichuli.png" v-if="item.applystatus===0" />
                    <el-image src="/images/jieshou.png" v-else-if="item.applystatus===1" />
                    <el-image src="/images/kaolv.png" v-else-if="item.applystatus===2" />
                    <el-image src="/images/jujue.png" v-else/>
                  </span>
                  <el-button  color="#333333" size="small" round style="font-size:12px;" @click="chatopen(item.applycompanyid, JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobid, JSON.parse(item.applyjobcontent.replace(/\n/g, '').replace(/\r/g, '')).jobname)"><el-icon style="margin-right:6px;" ><ChatDotRound /></el-icon>沟通</el-button>
                  </el-col>
                  <el-divider />
              </el-row>
              </div>
              </div>
        <!-- -------------------------------------------- -->
        </el-tab-pane>
        </el-tabs>
        </el-col>
    </el-row>
  </div>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:9999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>
import { rapplys, applyedit, getuserprofile } from '@/api/user'
import token from '@/utils/token'
export default {
  name: 'applyIndex',
  components: {},
  data () {
    return {
      boxheight: 518,
      drawer: 'none',
      iframeSrc: '',
      total: 0,
      currentpage: 1,
      listinfo: [],
      users: {}
    }
  },
  created () {
    this.getusers()
    this.boxheight = document.documentElement.clientHeight
    this.getlist(this.currentpage, 8)
  },
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.status === 200) {
          if (res.data.resultbody != null) {
            this.users = res.data.resultbody
            if (this.users.usersstatus > 0) {
              alert('您好！帐号锁定了！')
              token.settoken('utoken', '')
              this.$router.push('/login')
            }
            if (this.users.userstype === 1) {
              if (this.users.usersinfostatus === 0) {
                if (this.$router.currentRoute._rawValue.path !== '/resume') {
                  alert('您好！简历信息不完善尽快完善简历信息！')
                  this.$router.push('/resume')
                }
              }
            } else {
              this.$router.push('/companys/home')
            }
          } else {
            token.settoken('utoken', '')
            this.$router.push('/login')
          }
        } else {
          alert(res.data.message + '===>outss')
          token.settoken('utoken', '')
          this.$router.push('/login')
        }
      })
    },
    getlist () {
      rapplys(this.currentpage, 8).then(res => {
        if (res.data.resultbody.resultlist != null) {
          if (this.currentpage === 1) {
            this.total = res.data.resultbody.total
          }
          this.listinfo = res.data.resultbody.resultlist
        }
      })
    },
    pagechange (p) {
      if (this.page !== p) {
        this.currentpage = p
        this.getlist(p)
      }
    },
    edit (t, v) {
      const idlist = []
      const arr = document.getElementsByName('pid')
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].checked) {
          idlist.push(arr[i].value)
        }
      }
      if (v > 0) {
        idlist.push(v)
      }
      if (idlist.length > 0) {
        applyedit(idlist.toString(), t).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
            for (let i = 0; i < arr.length; i++) {
              arr[i].checked = false
            }
            this.getlist()
          } else {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        alert('(*^__^*)选择需要操作的数据!')
      }
    },
    chatopen (id, jobid, jobname) {
      this.drawer = 'block'
      this.iframeSrc = '/chats?id=' + id + '&jobid=' + jobid + '&jobname=' + jobname
    }
  }
}
</script>

<style lang="less" scoped>
.statusbox{
    .el-image {width:52px;height:52px;}
}
.containerbox {
  border-radius: 0px;
}
.listbox
{
  font-size: 14px;line-height:22px;
  .idbox{margin-right: 12px;}
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
.el-link{font-size: 14px;font-weight: bold; color: #333333;}
.el-link2{font-size: 12px;font-weight:normal;color:#666666;}
.el-divider {
    margin-top:8px;
    margin-bottom: 12px;
  }
  .xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
}
</style>
