<template>
<el-row gutter="30" class="count_a">
    <el-col :span="16" class="leftcontainer">
       <el-tabs v-model="activeName" type="card" @tab-change="onTabChange">
          <el-tab-pane label="最新人才" name="first" class="resumelist">
             <div :style="stylebox" v-infinite-scroll="getlist" infinite-scroll-distance="20" class="listlistbox">
              <div v-for="item in resumelist" :key="item.resumeid">
                <el-row gutter="12"  style="width:98%;margin-left: 1%;margin-top: 10px;"  v-if="Number(getAge(item.resumebirthday).replace('岁',''))>10">
                  <el-col :span="3" class="resumecompanylogo">
                    <span :class="item.resumegender==='男'?'resumegendera':'resumegenderb'">{{ item.resumegender }}</span>
                      <img :class="item.resumegender==='男'?'bordera':'borderb'" :src="baseurl+'static/resume/'+item.resumeusersid+'/photo/'+item.resumeusersid+'.jpg'" onerror="this.src='/images/avatar1.png'" />
                  </el-col>
                  <el-col :span="18" class="resumeinfo">
                  <div>
                    <el-link :underline="false" :href="'/resumeitem?id='+item.resumeusersid" target="_blank">{{item.resumename}}</el-link>
                    <span style="margin-left: 30px;">{{ item.resumelogintime.replace(/T/g,' ').replace(/Z/g,' ').substring(0,10) }}</span>
                  </div>
                  <div>
                    <span class="infovv">
                      <span>{{ getAge(item.resumebirthday) }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeworkage!=''">{{ item.resumeworkage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschool!=''">{{ item.resumeschool }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumespecialty!=''">{{item.resumespecialty}}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschoolage!=''">{{ item.resumeschoolage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumesalary!=''" style="color: crimson;">{{ item.resumesalary }}</span>
                    </span>
                    <br />
                    <span v-if="item.resumeindustry!=''&&isJsonString(item.resumeindustry)">期望行业：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumeindustry.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumejobposition!=''&&isJsonString(item.resumejobposition)">期望岗位：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobposition.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><el-tag type="info" color="#ffffff" v-if="item.resumejobpositions!=''" >{{ item.resumejobpositions }}</el-tag><br /></span>
                    <span v-if="item.resumejobcity!=''&&item.resumejobcity.length>30&&isJsonString(item.resumejobcity)">期望地区：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobcity.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumepersonaladvantages!=''" style="display: none;">特长优势：
                      <span class="infovv" v-if="item.resumepersonaladvantages.length<80">{{ item.resumepersonaladvantages }}</span>
                      <span class="infovv" v-else>{{ item.resumepersonaladvantages.substring(0,80) }}...</span>
                    </span>
                  </div>
                  </el-col>
                  <el-col :span="3">
                    <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqedit(item)"><el-icon style="margin-right:6px;"><Position /></el-icon>邀请</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px;margin-top: 12px;" @click="scsend(item)"><el-icon style="margin-right:6px;"><Collection /></el-icon>收藏</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px; margin-top: 12px;" @click="chatopen(item.resumeusersid)"><el-icon style="margin-right:6px;"><ChatDotRound /></el-icon>沟通</el-button>
                  </el-col>
                  <el-divider />
                </el-row>
                </div>
                <div v-if="loading==false" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><img src="/images/loading.gif" style="border-width: 0px;" /><br />稍等数据加载中</div>
                <div v-if="loading==true" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><el-icon><WarningFilled /></el-icon><br />没有了...</div>
              </div>
          </el-tab-pane>
          <el-tab-pane label="推荐人才" name="second" class="resumelist">
            <div :style="stylebox" v-infinite-scroll="getlisttj" infinite-scroll-distance="20" class="listlistbox">
              <div v-for="item in resumelisttj" :key="item.resumeid">
                <el-row gutter="12"  style="width:98%;margin-left: 1%;margin-top: 10px;" v-if="Number(getAge(item.resumebirthday).replace('岁',''))>10">
                  <el-col :span="3" class="resumecompanylogo">
                    <span :class="item.resumegender==='男'?'resumegendera':'resumegenderb'">{{ item.resumegender }}</span>
                      <img :class="item.resumegender==='男'?'bordera':'borderb'" :src="baseurl+'static/resume/'+item.resumeusersid+'/photo/'+item.resumeusersid+'.jpg'" onerror="this.src='/images/avatar1.png'" />
                  </el-col>
                  <el-col :span="18" class="resumeinfo">
                  <div><el-link :underline="false" :href="'/resumeitem?id='+item.resumeusersid" target="_blank">{{item.resumename}}</el-link>
                    <span style="margin-left: 30px;">{{ item.resumelogintime.replace(/T/g,' ').replace(/Z/g,' ') }}</span></div>
                  <div>
                    <span class="infovv">
                      <span>{{ getAge(item.resumebirthday) }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeworkage!=''">{{ item.resumeworkage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschool!=''">{{ item.resumeschool }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumespecialty!=''">{{item.resumespecialty}}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschoolage!=''">{{ item.resumeschoolage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumesalary!=''" style="color: crimson;">{{ item.resumesalary }}</span>
                    </span>
                    <br />
                    <!-- <span v-if="item.resumeindustry!=''">期望行业：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumeindustry)" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumejobposition!=''">期望岗位：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobposition)" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><el-tag type="info" color="#ffffff" v-if="item.resumejobpositions!=''" >{{ item.resumejobpositions }}</el-tag><br /></span>
                    <span v-if="item.resumejobcity!=''">期望地区：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobcity)" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span> -->
                    <span v-if="item.resumepersonaladvantages!=''">特长优势：
                      <span class="infovv" v-if="item.resumepersonaladvantages.length<80">{{ item.resumepersonaladvantages }}</span>
                      <span class="infovv" v-else>{{ item.resumepersonaladvantages.substring(0,80) }}...</span>
                    </span>
                  </div>
                  </el-col>
                  <el-col :span="3">
                    <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqedit(item)"><el-icon style="margin-right:6px;"><Position /></el-icon>邀请</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px;margin-top: 12px;" @click="scsend(item)"><el-icon style="margin-right:6px;"><Collection /></el-icon>收藏</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px; margin-top: 12px;" @click="chatopen(item.resumeusersid)"><el-icon style="margin-right:6px;"><ChatDotRound /></el-icon>沟通</el-button>
                  </el-col>
                  <el-divider />
                </el-row>
                </div>
                <div v-if="loading==false" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><img src="/images/loading.gif" style="border-width: 0px;" /><br />稍等数据加载中</div>
                <div v-if="loading==true" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><el-icon><WarningFilled /></el-icon><br />没有了...</div>
              </div>
          </el-tab-pane>
        </el-tabs>
    </el-col>
    <!-- -------------------------------------- -->
    <el-col :span="8" class="rightcontainer">
      <el-row :gutter="12" class="count_a">
        <el-col :span="6">
          <img style="width:60px;height:60px;border-radius: 16px;"  :src="logoinfo" onerror="this.src='/images/avatar2.png'" /><br />
           <el-form size="small" hide-required-asterisk="true" class="formcontainer" label-position="top" style="margin-top:10px; margin-bottom: 10px;">
              <el-upload :before-upload="beforeuploadlogo" :action="companyuploadlogo" method="post" name="file" style="margin-top:6px;" :on-success="onsuccesslogo" :headers="headers" :show-file-list="false">
                <el-button size="small" type="info" plain><el-icon style="margin-right:3px"><Camera /></el-icon>上传</el-button>
              </el-upload>
           </el-form>
        </el-col>
        <el-col :span="18" style="text-align:left;line-height: 22px;font-size: 12px;">
        欢迎您！<br /><b style="font-size: 14px;">{{ companys.companyname }}</b>
        <div>
        <el-button  color="#C83B3B" size="small" round style="margin-top:8px;" @click="goecompanysdit">
             <el-icon style="margin-right:6px;"><Edit /></el-icon>
             企业信息编辑
        </el-button>
        </div>
        </el-col>
      </el-row>
      <!-- -------------------------------------- -->
      <h5 class="titlea">服务情况</h5>
      <el-row :gutter="1" class="count_a">
        <el-col :span="6">
          <el-card shadow="never" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ companys.companyserver1 }}</b><br />
            简历份数
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="never" :body-style="{ padding: '8px' }" @click="goreading()">
            <b class="countstyle">{{ countlist[0] }}</b><br />
            已查阅数
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="never" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ companys.companyserver2 }}</b><br />
            短信条数
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="never" :body-style="{ padding: '8px' }" style="border-right-width:0px;">
            <b class="countstyle">{{ countlist[1] }}</b><br />
            收藏简历
          </el-card>
        </el-col>
      </el-row>
      <h5 class="titlea">招聘情况</h5>
      <el-row :gutter="1" class="count_a">
        <el-col :span="6">
          <el-card shadow="never" :body-style="{ padding: '8px' }" @click="gojoblist(0)">
            <b class="countstyle">{{ countlist[2] }}</b><br />
            招聘中
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="never" :body-style="{ padding: '8px' }" @click="gojoblist(1)">
            <b class="countstyle">{{ countlist[3] }}</b><br />
            已屏蔽
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="never" :body-style="{ padding: '8px' }" @click="gojoblist(3)">
            <b class="countstyle">{{ countlist[4] }}</b><br />
            回收站
          </el-card>
        </el-col>
        <el-col :span="6" @click="gojoblist(0)">
          <el-card shadow="never" :body-style="{ padding: '8px' }" style="border-right-width:0px;">
            <b class="countstyle">{{ countlist[5] }}</b><br />
            职位点击
          </el-card>
        </el-col>
      </el-row>
      <h5 class="titlea">收到的简历</h5>
      <el-row class="count_a">
        <el-col :span="6" @click="goapply(1)">
          <el-card shadow="never" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ countlist[6] }}</b><br />
            已接受
          </el-card>
        </el-col>
        <el-col :span="6" @click="goapply(2)">
          <el-card shadow="never" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ countlist[7] }}</b><br />
            考虑中
          </el-card>
        </el-col>
        <el-col :span="6" @click="goapply(3)">
          <el-card shadow="never" :body-style="{ padding: '8px' }">
            <b class="countstyle">{{ countlist[8] }}</b><br />
            已拒绝
          </el-card>
        </el-col>
        <el-col :span="6" @click="goapply(0)">
          <el-card shadow="never" :body-style="{ padding: '8px' }" style="border-right-width:0px;">
            <b class="countstyle">{{ countlist[9] }}</b><br />
            待处理
          </el-card>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
<!-- -------------------------------------- -->
<el-dialog v-model="yq_dialogVisible" title="邀请面试" width="418px">
      <el-row gutter="60">
        <el-col :span="24">
          <el-form-item label="" style="width: 100%;">
            <el-select  v-model="yq_job" placeholder="选择邀请面试岗位" style="width: 100%;">
                <el-option v-for="item in joblist" :key="item.jobid" :value="item.jobname">{{item.jobname}}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div style="text-align: right;padding-top: 18px;padding-bottom: 18px;">
            <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqsend()"><el-icon style="margin-right:6px;"><Position /></el-icon>发送邀请</el-button>
          </div>
        </el-col>
      </el-row>
  </el-dialog>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;">关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>
import { companys, resumes, companyscount, cjobmanage, inviteyq, collectyonsc, resumesearch } from '@/api/user'
import store from '@/store/index'
import token from '@/utils/token'
import timehelp from '@/utils/timehelp'
export default {
  name: 'homeIndex',
  components: {},
  props: {},
  data () {
    return {
      boxheight: 518,
      drawer: 'none',
      iframeSrc: '',
      yq_dialogVisible: false,
      yq_item: {},
      yq_job: '选择邀请面试岗位',
      baseurl: store.state.baseUrl,
      logoinfo: '',
      headers: {},
      companyuploadlogo: store.state.baseUrl + 'company/pic?t=4', // 上传路径
      stylebox: 'width:100%;height: 200px;overflow: auto',
      box_height: 200,
      loading: false,
      loadingtj: false,
      activeName: 'first',
      statusvalue: '1',
      companys: { companyid: 0 },
      currentpage: 1,
      currentpagetj: 1,
      resumelist: [],
      resumelisttj: [],
      countlist: [],
      joblist: []
    }
  },
  computed: {
    disabled () {
      return this.loading
    }
  },
  watch: {},
  created () {
    this.headers.token = token.gettoken('utoken')
    this.getcompany()
    const listheight = document.documentElement.clientHeight - 208
    this.stylebox = 'width:100%;height: ' + listheight + 'px;overflow: auto'
    this.boxheight = listheight
  },
  mounted () {},
  methods: {
    getcompany () {
      companys().then(res => {
        if (res.data.status === 200) {
          this.companys = res.data.resultbody
          if (this.companys.companyserver.length > 9) {
            this.companys.companyserver = this.companys.companyserver.substring(0, 10)
            if (this.companys.companytype.length === 0 || this.companys.companyarea.length === 0 || this.companys.companyaddress.length === 0) {
              this.$message({
                message: '::>_<:: 快完善企业信息吧！',
                type: 'info'
              })
              this.$router.push('/companys/company')
            }
            this.getcompanycount()
          }
          this.logoinfo = this.baseurl + 'static/incpic/' + this.companys.companyusersid + '/logo/' + this.companys.companyusersid + '.jpg?t=' + new Date().getSeconds()
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      })
    },
    getcompanycount () {
      companyscount().then(res => {
        if (res.data.status === 200) {
          this.countlist = res.data.resultbody
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      })
    },
    getlist () {
      setTimeout(() => {
        if (this.loading === false) {
          resumes(this.currentpage).then(res => {
            if (res.data.resultbody.resultlist != null) {
              this.currentpage++
              if (res.data.resultbody.resultlist.length < 30) {
                setTimeout(() => { this.loading = true }, 100)
              } else {
                setTimeout(() => { this.loading = false }, 100)
              }
              if (res.data.resultbody.resultlist != null) {
                for (let i = 0; i < res.data.resultbody.resultlist.length; i++) {
                  this.resumelist.push(res.data.resultbody.resultlist[i])
                }
              } else {
                setTimeout(() => { this.loading = true }, 100)
              }
            }
          })
        }
      }, 100)
    },
    getlisttj () {
      setTimeout(() => {
        if (this.loadingtj === false) {
          resumesearch(this.currentpagetj, 'tj').then(res => {
            if (res.data.resultbody != null) {
              for (let i = 0; i < res.data.resultbody.length; i++) {
                const iteminfo = res.data.resultbody[i]
                // if (this.resumelist.indexOf(iteminfo) === 0) {
                this.resumelisttj.push(iteminfo)
                // }
              }
              if (res.data.resultbody.length < 30) {
                setTimeout(() => { this.loadingtj = true }, 50)
                this.stopptj = this.currentpagetj
              } else {
                setTimeout(() => {
                  this.loadingtj = false
                  this.currentpagetj++
                }, 50)
              }
            } else {
              setTimeout(() => { this.loadingtj = true }, 50)
              this.stopptj = this.currentpagetj
            }
          })
        }
      }, 50)
    },
    getAge (vv) {
      return timehelp.getAge(vv)
    },
    beforeuploadlogo (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的图片！')
        return false
      } else if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        alert('请上传jpg或者png格式的图片！')
        return false
      }
      return true
    },
    onsuccesslogo (uploadFilea, uploadFileb, uploadFilec) {
      this.logoinfo = this.baseurl + 'static/incpic/' + this.companys.companyusersid + '/logo/' + this.companys.companyusersid + '.jpg?t=' + new Date().getSeconds()
      location.reload()
    },
    goecompanysdit () {
      this.$router.push('/companys/company')
    },
    gojoblist (t) {
      this.$router.push('/companys/jobs?t=' + t)
    },
    goreading () {
      this.$router.push('/companys/reading')
    },
    goapply (t) {
      this.$router.push('/companys/apply?t=' + t)
    },
    yqedit (item) {
      this.yq_dialogVisible = true
      this.yq_item = item
      this.getjoblist()
    },
    getjoblist () {
      cjobmanage(0, 1, '', 50, 0).then(res => {
        if (res.data.resultbody.resultlist != null) {
          if (this.currentpage === 1) {
            this.total = res.data.resultbody.total
          }
          this.joblist = res.data.resultbody.resultlist
        }
      })
    },
    yqsend () {
      const resumeid = this.yq_item.resumeusersid
      let jobid = 0
      for (let i = 0; i < this.joblist.length; i++) {
        if (this.joblist[i].jobname === this.yq_job) {
          jobid = this.joblist[i].jobid
        }
      }
      if (resumeid > 0 && jobid > 0) {
        inviteyq(jobid, resumeid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
            this.yq_job = '选择邀请面试岗位'
            this.yq_dialogVisible = false
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '::>_<:: 请选择邀请面试的岗位！',
          type: 'error'
        })
      }
    },
    scsend (item) {
      collectyonsc(item.resumeusersid).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: ' + res.data.message + '',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    chatopen (id) {
      this.drawer = true
      this.iframeSrc = '/chats?id=' + id
    },
    onTabChange (vv) {
      if (vv === 'second') {
        this.getlisttj()
      }
    },
    isJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="less" scoped>
.listlistbox::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
.listlistbox::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-size: cover;
  background-color: #888888;
  height:120px;
  width:5px;
}
.resumegendera{position:absolute;top:2px;left:52px;width:16px;height: 16px;background-color: #003399; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.resumegenderb{position:absolute;top:2px;left:52px;width:16px;height: 16px;background-color: #FF0066; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.bordera{border-width: 2px;border-color: #003399; border-style: solid;width:48px;height:48px;border-radius: 48px;}
.borderb{border-width: 2px;border-color: #FF0066; border-style: solid;width:48px;height:48px;border-radius: 48px;}
.titlea{
  text-align: left;padding-left: 12px;font-size: 15px;margin-top: 2px; margin-bottom: 8px;
}

.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
}
.bg-purple {
background-color: #ffffff;
}
.bg-purple-light {
  background-color: bisque;
}
.count_a {
  text-align: center;
  line-height: 22px;
  .el-col{
    cursor: pointer;
  }
  .el-card{
  color: #909399;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
  font-size: 12px;
  border-radius: 0px;
  margin-bottom: 6px;
  cursor: pointer;
  .countstyle {
    font-size: 16px;
    color: #000000;
  }
  }

  .infostyle{
    font-size: 14px;
    color:#909399;
  }
  .infostylevv{font-size: 14px; color:#333333;}
}
.rightcontainer {
  border-left-width:1px;
  border-left-style: solid;
  border-radius: 0px;
  border-left-color:  #dddfe5;
}
.resumeinfo {
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;}
}
.resumecompany {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
  color: #888888;
  .el-link {
    font-size: 14px;
    color: #333333;
  }
}
.resumecompanylogo {
  text-align: left;
  margin-top: 6px;
}
.resumelist
{
  .el-row{ margin-bottom: 8px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
}
.rightyx {
  margin-left: 12px;
  margin-right:12px;
  text-align: left;
  font-size: 14px;
  color: #777777;
  line-height: 28px;
  .el-row {
    border-bottom-width:1px;
    border-bottom-style: solid;
    border-bottom-color:  #dddfe5;
    padding-top: 2px;
    padding-bottom: 8px;
  }
  .el-tab-pane {
    padding-left: 20px;
    .el-tag { margin-right: 6px; color:#222222;}
  }
}
</style>
