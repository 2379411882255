<template>
<div class="containerbox">
          <!-- ----------------------------------------- -->
          <el-row :gutter="30" style="width:100%;">
           <el-col :span="24">
          <el-tabs model-value="firstbox" type="card">
              <el-tab-pane label="简历管理" name="firstbox" class="listbox">
                <el-affix :offset="418" style="width: 100px;">
                <el-link href="/print" target="_blank"><el-icon style="margin-right: 8px;"><View /></el-icon> 简历预览</el-link><br /><br />
                <el-link href="/print" target="_blank"><el-icon style="margin-right: 8px;"><Printer /></el-icon> 简历打印</el-link>
              </el-affix>
              <!-- ----------------------------------------- -->
              <el-tabs tab-position="left" :model-value="re" @tab-click="handleClick">
                <el-form label-width="82px" size="default" label-position="right" class="forminfo" ref="resume"  :model="resume" :rules="rules" >
                <el-tab-pane label="基本信息" name="re1" class="scrollbarbox" :style="'height:'+(boxheight-228)+'px;overflow-y:auto;overflow-x:none;padding-left:60px;'">
                  <!-- 基本信息 ----------------------------------------- -->
                  <div style="width:98%;">
                      <el-row :gutter="20">
                        <el-col :span="24"><h3>基本信息</h3></el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="10">
                          <el-form-item label="姓名" prop="resumename">
                              <el-input type="text" v-model="resume.resumename "/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                          <el-form-item label="性别">
                            <el-radio-group v-model="resume.resumegender">
                              <el-radio label="男" />
                              <el-radio label="女" />
                            </el-radio-group>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="10">
                          <el-form-item label="生日" prop="resumebirthday">
                            <el-config-provider :locale="zhCn">
                            <el-date-picker v-model="resume.resumebirthday" type="date" placeholder="选择生日" value-format="YYYY-MM-DD" :editable="false"/>
                            </el-config-provider>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                          <el-form-item label="参工时间">
                            <el-config-provider :locale="zhCn">
                              <!-- <el-config-provider :locale="zhLocale"> -->
                            <el-date-picker v-model="resume.resumeworktime " type="date" placeholder="选择参工时间" value-format="YYYY-MM-DD" :editable="false"/>
                          </el-config-provider>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="10">
                          <el-form-item label="求职状态" prop="resumejobstatus">
                            <el-select v-model="resume.resumejobstatus" placeholder="选择状态">
                              <el-option :value="1" label="离职 - 随时到岗"/>
                              <el-option :value="0" label="在职 - 投递企业可查看"/>
                              <el-option :value="-1" label="屏蔽 - 隐藏求职信息"/>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="10">
                          <el-form-item label="身份" prop="resumeidentity">
                            <el-select v-model="resume.resumeidentity" placeholder="选择身份">
                              <el-option value="职位大牛" label="职位大牛"/>
                              <el-option value="在校学生" label="在校学生"/>
                            </el-select>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="居住地区" prop="resumearea">
      <el-cascader
      v-model="resume.resumearea"
      :options="dqlistselect"
      :props="{ value: 'name', label: 'name', children: 'children' }"
      @change="dqhandleCascaderChange"
      placeholder="请选择居住地区"
    ></el-cascader>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="20">
                          <el-form-item label="个人优势" prop="resumepersonaladvantages">
                              <el-input type="textarea" v-model="resume.resumepersonaladvantages" :rows="3" />
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="10">
                          <el-form-item label="毕业学校" prop="resumeschool">
                              <el-input type="text" v-model="resume.resumeschool" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                          <el-form-item label="专业" prop="resumespecialty">
                              <el-input type="text" v-model="resume.resumespecialty" />
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="10">
                          <el-form-item label="学历" prop="resumeschoolage">
                            <el-select v-model="resume.resumeschoolage" placeholder="选择学历">
                              <el-option value="大专" label="大专"/>
                              <el-option value="中专" label="中专"/>
                              <el-option value="本科" label="本科"/>
                              <el-option value="硕士" label="硕士"/>
                              <el-option value="博士" label="博士"/>
                              <el-option value="高中" label="高中"/>
                            </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                          <el-form-item label="工作经验" prop="resumeworkage">
                            <el-select v-model="resume.resumeworkage" placeholder="选择工作经验">
                              <el-option value="一年" label="一年"/>
                              <el-option value="两年" label="两年"/>
                              <el-option value="三年" label="三年"/>
                              <el-option value="四年" label="四年"/>
                              <el-option value="五年" label="五年"/>
                            </el-select>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="20">
                          <el-form-item label="邮箱" prop="resumemail">
                              <el-input type="email" v-model="resume.resumemail"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="20">
                          <el-form-item label="电话" prop="resumeusersphone">
                              <el-input type="tel" v-model="resume.resumeusersphone"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="20" style="text-align: right;">
                          <el-button color="#ba181b" @click="sendresume('resume',1)" :loading="loading">保存</el-button>
                        </el-col>
                      </el-row>
                  </div>
                  <!-- 基本信息 ----------------------------------------- -->
                </el-tab-pane>
                <el-tab-pane label="求职意向" name="re2" class="scrollbarbox" :style="'height:'+(boxheight-228)+'px;overflow-y:auto;overflow-x:none;padding-left:60px;'">
                  <!-- 求职意向 ----------------------------------------- -->
                  <div style="width:98%;">
                      <el-row :gutter="20">
                      <el-col :span="24"><h3>求职意向</h3></el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="求职类型" prop="resumejobtype">
                            <el-select v-model="resume.resumejobtype" placeholder="选择求职类型">
                              <el-option value="全职" label="全职"/>
                              <el-option value="兼职" label="兼职"/>
                              <el-option value="实习" label="实习"/>
                            </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="待遇" prop="resumesalary">
                            <el-select v-model="resume.resumesalary" placeholder="选择待遇">
                                 <span v-for="item in xzlist" :key="item.categoryid">
                                  <el-option v-if="item.categoryroot==0" :value="item.categoryname"><b style="padding-left: 10px;">{{ item.categoryname }}</b></el-option>
                                  <el-option v-else :value="item.categoryname" style="padding-left: 20px;"><en style="padding-left: 20px;">{{ item.categoryname }}</en></el-option>
                                </span>
                            </el-select>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="期望行业" prop="resumeindustry">
                          <el-button color="#4B4B4B" round size="small" style="margin-top:10px;" @click="categoryinit(1)">选择</el-button>
                          <div class="yxbox">
                          <el-tag type="info" round v-for="citem in hylistvvv" :key="citem.categoryid">{{ citem.categoryname }} </el-tag>
                          </div>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" style="margin-top: 20px; margin-bottom: 20px;">
                        <el-col :span="24">
                          <el-form-item label="期望职位" prop="resumejobposition">
                            <el-button color="#4B4B4B" round size="small" style="margin-top:10px;" @click="categoryinit(2)">选择</el-button>
                          <div class="yxbox">
                            <el-tag type="info" round v-for="citem in zwlistvvv" :key="citem.categoryid">{{ citem.categoryname }} </el-tag>
                          </div>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="工作地区" prop="resumejobcity">
                            <el-button color="#4B4B4B" round size="small" style="margin-top:10px;" @click="categoryinit(3)">选择</el-button>
                          <div class="yxbox">
                            <el-tag type="info" round v-for="citem in dqlistvvv" :key="citem.categoryid">{{ citem.categoryname }} </el-tag>
                          </div>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="自定岗位" prop="resumejobpositions">
                              <el-input type="text" v-model="resume.resumejobpositions" />
                              <div style="color: #888888;">如：会计，销售，设计，驾驶，文员，助理，行政，出纳，工程，营销，客服</div>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24" style="text-align: right;">
                          <el-button color="#ba181b" @click="sendresume('resume',2)" :loading="loading">保存</el-button>
                        </el-col>
                      </el-row>
                  </div>
                  <!-- 求职意向 ----------------------------------------- -->
                </el-tab-pane>
                </el-form>
                <el-tab-pane label="工作经历" name="re3"  class="scrollbarbox" :style="'height:'+(boxheight-228)+'px;overflow-y:auto;overflow-x:none;padding-left:60px;'">
                  <!-- 工作经历 ----------------------------------------- -->
                  <div style="width:98%;">
                  <el-row :gutter="20">
                        <el-col :span="24">
                          <h3>工作经历
                          <el-button style="float: right;margin-right: 58px;" color="#161a1d" v-if="workshow=='list'" @click="workshow='edit'">添加工作经历</el-button>
                          <el-button style="float: right;margin-right: 58px;" color="#161a1d" v-if="workshow=='edit'" @click="workshow='list'">返回工作经历</el-button>
                          </h3>
                        </el-col>
                  </el-row>
                  <div v-if="workshow=='list'">
                  <el-row :gutter="20" v-for="item in resumeworkexperience" :key="item.workexid" class="exitembox">
                  <el-col :span="24">
                    <div class="exitem">时间:<b>{{ item.workextime }}</b></div>
                    <div class="exitem" style="display: none;">行业:<b>{{ item.workextrade }}</b></div>
                    <div class="exitem">公司:<b>{{ item.workexinc }}</b></div>
                    <div class="exitem">部门:<b>{{ item.workexdepartment }}</b></div>
                    <div class="exitem">职位:<b>{{ item.workexposition }}</b></div>
                    <div class="exitem">说明:<b>{{ item.workexcontent }}</b></div>
                    <div class="exitemedit">
                      <el-button color="#4B4B4B" size="small" @click="workedit(item)" round><el-icon style="margin-right: 8px;"><Edit /></el-icon> 编辑</el-button>
                      <el-button color="#4B4B4B" size="small" @click="workdel(item)" round><el-icon style="margin-right: 8px;"><Delete /></el-icon> 删除</el-button>
                    </div>
                  </el-col>
                  </el-row>
                  </div>
                  <!-- ---------- ----------------------------------------- -->
                  <div v-if="workshow=='edit'" style="width:60%;padding-top:30px;">
                  <el-form label-width="82px" size="default" label-position="right" class="forminfo" :v-if="workshow===edit" ref="workex"  :model="workex" :rules="workexrules" >
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="时间" prop="workextime">
                              <el-input type="text" v-model="workex.workextime "/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" style="display: none;">
                        <el-col :span="24">
                          <el-form-item label="行业">
                            <el-select v-model="workex.workextrade" placeholder="选择行业" style="width:100%;">
                              <el-option :value="item.categoryname" v-for="item in hylist" :key="item.categoryid" :label="item.categoryname"/>
                            </el-select>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="公司" prop="workexinc">
                              <el-input type="text" v-model="workex.workexinc "/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="部门" prop="workexdepartment">
                              <el-input type="text" v-model="workex.workexdepartment "/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="职位" prop="workexposition">
                              <el-input type="text" v-model="workex.workexposition "/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="工作内容" prop="workexcontent">
                              <el-input type="textarea" v-model="workex.workexcontent" :rows="3" />
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24" style="text-align: right;">
                          <el-button color="#ba181b" @click="sendword('workex')" :loading="loading">保存</el-button>
                        </el-col>
                      </el-row>
                  </el-form>
                  </div>
                  </div>
                  <!-- ---------- ----------------------------------------- -->
                  <!-- 工作经历 ----------------------------------------- -->
                </el-tab-pane>
                <el-tab-pane label="教育经历" name="re4" class="scrollbarbox" :style="'height:'+(boxheight-228)+'px;overflow-y:auto;overflow-x:none;padding-left:60px;'">
                  <!-- 教育经历 ----------------------------------------- -->
                  <div style="width:98%;">
                  <el-row :gutter="20">
                        <el-col :span="24">
                          <h3>教育经历
                          <el-button style="float: right;margin-right: 58px;" color="#161a1d" v-if="educateshow=='list'" @click="educateshow='edit'">添加教育经历</el-button>
                          <el-button style="float: right;margin-right: 58px;" color="#161a1d" v-if="educateshow=='edit'" @click="educateshow='list'">返回教育经历</el-button>
                          </h3>
                        </el-col>
                  </el-row>
                  <div v-if="educateshow=='list'">
                  <el-row :gutter="20" v-for="item in resumeexperienceeducation" :key="item.educateexid" class="exitembox">
                  <el-col :span="24">
                    <div class="exitem">时间:<b>{{ item.educateextime }}</b></div>
                    <div class="exitem">学校:<b>{{ item.educateexschool }}</b></div>
                    <div class="exitem">学历:<b>{{ item.educateexeducation }}</b></div>
                    <div class="exitem">专业:<b>{{ item.educateexfield }}</b></div>
                    <div class="exitem">说明:<b>{{ item.educateexcontent }}</b></div>
                    <div class="exitemedit">
                      <el-button color="#4B4B4B" size="small" @click="educateedit(item)" round><el-icon style="margin-right: 8px;"><Edit /></el-icon> 编辑</el-button>
                      <el-button color="#4B4B4B" size="small" @click="educatedel(item)" round><el-icon style="margin-right: 8px;"><Delete /></el-icon> 删除</el-button>
                    </div>
                  </el-col>
                  </el-row>
                  </div>
                  <!-- ---------- ----------------------------------------- -->
                  <div v-if="educateshow=='edit'" style="width:60%;padding-top:30px;">
                    <el-form label-width="82px" size="default" label-position="right" class="forminfo" ref="educateex"  :model="educateex" :rules="educateexrules" >
                    <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="时间" prop="educateextime">
                              <el-input type="text" v-model="educateex.educateextime"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="学校" prop="educateexschool">
                              <el-input type="text" v-model="educateex.educateexschool"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="学历" prop="educateexeducation">
                              <el-input type="text" v-model="educateex.educateexeducation"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="专业" prop="educateexfield">
                              <el-input type="text" v-model="educateex.educateexfield"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="学习说明" prop="educateexcontent">
                              <el-input type="textarea" v-model="educateex.educateexcontent" :rows="3" />
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24" style="text-align: right;">
                          <el-button color="#ba181b" @click="sendeducate('educateex')" :loading="loading">保存</el-button>
                        </el-col>
                      </el-row>
                  </el-form>
                  </div>
                  </div>
                  <!-- ---------- ----------------------------------------- -->
                  <!-- 教育经历 ----------------------------------------- -->
                </el-tab-pane>
              </el-tabs>
              <!-- ----------------------------------------- -->
              </el-tab-pane>
              </el-tabs>
             </el-col>
          </el-row>
          <!-- ----------------------------------------- -->
  </div>

<!-- 行业 ----------------------------------------- -->
  <el-dialog v-model="dialogVisiblehy" title="请选择行业" width="918px" :before-close="handleClose">
    <div class="categoryname">
        <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22"><el-tag @click="categoryclear(1,citem)" type="info"  round  v-for="citem in hylistvvv" :key="citem.categoryid">{{ citem.categoryname }} <span class="cleartext">x</span></el-tag></el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisiblehy = false" round>确定</el-button></el-col>
        </el-row>
    </div>
    <div class="categorylist" style="width: 100%;">
      <el-scrollbar height="400px" style="width: 100%;">
        <el-row :gutter="20" style="width:100%;">
          <el-col @click="categoryset(1,citem)" :span="8" v-for="citem in hylist" :key="citem.categoryid" class='itemboxb'>
            {{ citem.categoryname }}
          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </el-dialog>
<!-- 职位 ----------------------------------------- -->
  <el-dialog v-model="dialogVisiblezw" title="选择职位"  width="918px" :before-close="handleClose">
    <div class="categoryname">
        <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22">
            <el-tag @click="categoryclear(2,citem)"  type="info" round  v-for="citem in zwlistvvv" :key="citem.categoryid">{{ citem.categoryname }} <span class="cleartext">x</span></el-tag>
          </el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisiblezw = false" round>确定</el-button></el-col>
        </el-row>
    </div>
    <div class="categorylist" style="width: 100%;">
      <el-scrollbar height="400px" style="width: 100%;">
        <el-row :gutter="20" style="width:98%;margin-left: 1%;">
          <el-col :span="6" class="rootiteminfoa"  v-for="(citemroot,index) in zwlistroot" :key="citemroot.categoryid" :class="index%2===0?'itemboxaa':'itemboxbb'">

  <el-popover placement="bottom-start" title="" :width="718" trigger="hover">
    <template #reference>
      {{ citemroot.categoryname }}
    </template>
    <el-col :span="24" style="overflow-y: visible; overflow-x: hidden;max-height:298px;">
      <div class="zcitem3" @click="categoryset(2,citem)" v-for="citem in getzlist(2,citemroot.categoryid)" :key="citem.categoryid"><span>{{ citem.categoryname }}</span></div>
    </el-col>
  </el-popover>

          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </el-dialog>
<!-- 地区 ----------------------------------------- -->
  <el-dialog v-model="dialogVisibledq" title="选择地区"  width="918px" :before-close="handleClose">
    <div class="categoryname">
      <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22">
           <el-tag @click="categoryclear(3,citem)"  type="info" round  v-for="citem in dqlistvvv" :key="citem.categoryid">{{ citem.categoryname }} <span class="cleartext">x</span></el-tag>
          </el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisibledq = false" round>确定</el-button></el-col>
      </el-row>
      </div>
      <div class="categorylist">
      <el-scrollbar height="400px">
        <el-row :gutter="20" v-for="(citemroot,index) in dqlistroot" :key="citemroot.categoryid" :class="index%2===0?'itemboxa':'itemboxb'">
          <el-col :span="24" class="rootiteminfo">
            {{ citemroot.categoryname }}
          </el-col>
          <el-col :span="24" class="iteminfo">
            <div class="zcitem" @click="categoryset(3,citem)" v-for="citem in getzlist(3,citemroot.categoryid)" :key="citem.categoryid"><span>{{ citem.categoryname }}</span></div>
          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </el-dialog>
<!--  ----------------------------------------- -->
</template>

<script>

import { category, rresumeput, rgetresume } from '@/api/user'
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
export default {
  name: 'resumeIndex',
  components: { [ElConfigProvider.name]: ElConfigProvider },
  data () {
    return {
      zhCn,
      boxheight: 518,
      re: 're1',
      workshow: 'list',
      educateshow: 'list',
      hylist: [],
      hylistvvv: [],
      zwlist: [],
      zwlistroot: [],
      zwlistvvv: [],
      xzlist: [],
      dqlist: [],
      dqlistroot: [],
      dqlistvvv: [],
      dqlistselect: [],
      dialogVisiblehy: false,
      dialogVisiblezw: false,
      dialogVisibledq: false,
      loading: false,
      resume: {
        resumeid: 0,
        resumegender: '男'
      },
      resumpassword: '',
      rules: {
        resumeusersphone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的11位手机号码', trigger: 'blur' }
        ],
        resumename: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        resumebirthday: [
          { required: true, message: '请选择生日', trigger: 'blur' }
        ],
        // resumeworktime: [
        //   { required: true, message: '请选择参工时间', trigger: 'blur' }
        // ],
        resumearea: [
          { required: true, message: '请选择居住地区', trigger: 'blur' }
        ],
        resumejobstatus: [
          { required: true, message: '请选择求职状态', trigger: 'blur' }
        ],
        resumeidentity: [
          { required: true, message: '请选择身份', trigger: 'blur' }
        ],
        resumepersonaladvantages: [
          { required: true, message: '请填写优势', trigger: 'blur' }
        ],
        resumeschool: [
          { required: true, message: '请填写毕业学校', trigger: 'blur' }
        ],
        resumeschoolage: [
          { required: true, message: '请选择学历', trigger: 'blur' }
        ],
        resumespecialty: [
          { required: true, message: '专业必须选择', trigger: 'blur' }
        ],
        resumeworkage: [
          { required: true, message: '请选择工作经验', trigger: 'blur' }
        ],
        resumejobtype: [
          { required: true, message: '请选择求职类型', trigger: 'blur' }
        ],
        resumesalary: [
          { required: true, message: '请选择待遇', trigger: 'blur' }
        ],
        resumejobpositions: [
          { required: true, message: '请填写自定岗位', trigger: 'blur' }
        ],
        resumeindustry: [
          {
            required: true, message: '请选择行业', trigger: 'blur'
          }
        ],
        resumejobposition: [
          { required: true, message: '请选择意向职位', trigger: 'blur' }
        ],
        resumejobcity: [
          { required: true, message: '请选择意向地区', trigger: 'blur' }
        ],
        resumemail: [
          { type: 'string', required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      },
      resumeworkexperience: [],
      workex: { workexid: 0 },
      resumeexperienceeducation: [],
      educateex: { educateexid: 0 },
      workexrules: {
        workextime: [
          { required: true, message: '时间必须填写', trigger: 'blur' }
        ],
        // workextrade: [
        //   { required: true, message: '行业必须选择', trigger: 'blur' }
        // ],
        workexinc: [
          { required: true, message: '公司必须填写', trigger: 'blur' }
        ],
        workexdepartment: [
          { required: true, message: '部门必须填写', trigger: 'blur' }
        ],
        workexposition: [
          { required: true, message: '职位必须填写', trigger: 'blur' }
        ],
        workexcontent: [
          { required: true, message: '简单描述一下工作情况吧', trigger: 'blur' }
        ]
      },
      educateexrules: {
        educateextime: [
          { required: true, message: '时间必须填写', trigger: 'blur' }
        ],
        educateexschool: [
          { required: true, message: '学校必须选择', trigger: 'blur' }
        ],
        educateexeducation: [
          { required: true, message: '学历必须填写', trigger: 'blur' }
        ],
        educateexfield: [
          { required: true, message: '专业必须填写', trigger: 'blur' }
        ],
        educateexcontent: [
          { required: true, message: '简单描述一下学习情况吧', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getcategory(3)
    this.getcategory(33)
    this.boxheight = document.documentElement.clientHeight
    this.getresumes()
    const t = this.$route.query.t
    if (t === '2') {
      this.re = 're2'
    }
  },
  methods: {
    getcategory (t) {
      let ct = t
      if (t === 1) {
        ct = 9
      } else if (t === 2) {
        ct = 1
      } else if (t === 3) {
        ct = 2
      } else if (t === 33) {
        ct = 3
      }
      category(ct, 0).then(res => {
        this.hylist = []
        this.zwlist = []
        this.dqlist = []
        if (t === 33) {
          this.xzlist = []
        }
        for (let i = 0; i < res.data.resultbody.resultlist.length; i++) {
          const citem = { categoryid: res.data.resultbody.resultlist[i].categoryid, categoryname: res.data.resultbody.resultlist[i].categoryname, categoryroot: res.data.resultbody.resultlist[i].categoryroot }
          if (t === 1) {
            this.hylist.push(citem)
          } else if (t === 2) {
            this.zwlist.push(citem)
          } else if (t === 3) {
            this.dqlist.push(citem)
          } else if (t === 33) {
            this.xzlist.push(citem)
          }
          // -----------------------
          // if (t > 1) {
          //   category(ct, res.data.resultbody.resultlist[i].categoryid).then(ress => {
          //     for (let ii = 0; ii < ress.data.resultbody.resultlist.length; ii++) {
          //       const citemz = { categoryid: ress.data.resultbody.resultlist[ii].categoryid, categoryname: ress.data.resultbody.resultlist[ii].categoryname, categoryroot: ress.data.resultbody.resultlist[ii].categoryroot }
          //       if (t === 1) {
          //         this.hylist.push(citemz)
          //       } else if (t === 2) {
          //         this.zwlist.push(citemz)
          //       } else if (t === 3) {
          //         this.dqlist.push(citemz)
          //       }
          //     }
          //   })
          // }
          // ------------------------
        }
        this.getrootlit(t, 0)
        if (t === 3) {
          for (let ii = 0; ii < this.dqlist.length; ii++) {
            if (this.dqlist[ii].categoryroot === 0) {
              const dqid = this.dqlist[ii].categoryid
              const dqname = this.dqlist[ii].categoryname
              const dqchildrens = []
              if (dqid > 0) {
                for (let iii = 0; iii < this.dqlist.length; iii++) {
                  if (this.dqlist[iii].categoryroot === dqid) {
                    const zitemdq = { id: this.dqlist[iii].categoryid, name: this.dqlist[iii].categoryname }
                    dqchildrens.push(zitemdq)
                  }
                }
                const selectitemdq = { id: dqid, name: dqname, children: dqchildrens }
                this.dqlistselect.push(selectitemdq)
              }
            }
          }
        }
      })
    },
    categoryset (t, v) {
      if (t === 1) {
        if (this.hylistvvv.length > 4) {
          alert('最多只能选择五项')
        } else {
          if (this.hylistvvv.indexOf(v) < 0) {
            this.hylistvvv.push(v)
          }
        }
        this.resume.resumeindustry = JSON.stringify(this.hylistvvv)
      } if (t === 2) {
        if (this.zwlistvvv.length > 4) {
          alert('最多只能选择五项')
        } else {
          if (this.zwlistvvv.indexOf(v) < 0) {
            this.zwlistvvv.push(v)
          }
        }
        this.resume.resumejobposition = JSON.stringify(this.zwlistvvv)
      } if (t === 3) {
        if (this.dqlistvvv.length > 4) {
          alert('最多只能选择五项')
        } else {
          if (this.dqlistvvv.indexOf(v) < 0) {
            this.dqlistvvv.push(v)
          }
        }
        this.resume.resumejobcity = JSON.stringify(this.dqlistvvv)
      }
    },
    categoryclear (t, v) {
      if (t === 1) {
        const newlistinfo = []
        for (let i = 0; i < this.hylistvvv.length; i++) {
          if (this.hylistvvv[i] !== v) {
            newlistinfo.push(this.hylistvvv[i])
          }
        }
        this.hylistvvv = newlistinfo
      } else if (t === 2) {
        const newlistinfo = []
        for (let i = 0; i < this.zwlistvvv.length; i++) {
          if (this.zwlistvvv[i] !== v) {
            newlistinfo.push(this.zwlistvvv[i])
          }
        }
        this.zwlistvvv = newlistinfo
      } else if (t === 3) {
        const newlistinfo = []
        for (let i = 0; i < this.dqlistvvv.length; i++) {
          if (this.dqlistvvv[i] !== v) {
            newlistinfo.push(this.dqlistvvv[i])
          }
        }
        this.dqlistvvv = newlistinfo
      }
    },
    categoryinit (t) {
      this.getcategory(t)
      if (t === 1) {
        this.dialogVisiblehy = true
      } else if (t === 2) {
        this.dialogVisiblezw = true
      } else if (t === 3) {
        this.dialogVisibledq = true
      }
    },
    getrootlit (t, rootitemid) {
      if (t === 2) {
        this.zwlistroot = []
        const arr = this.zwlist.filter(item => { return item.categoryroot === rootitemid })
        this.zwlistroot = arr
      } else if (t === 3) {
        this.dqlistroot = []
        const arr = this.dqlist.filter(item => { return item.categoryroot === rootitemid })
        this.dqlistroot = arr
      }
    },
    getzlist (t, rootitemid) {
      if (t === 2) {
        let zwlistnew = []
        zwlistnew = this.zwlist.filter(item => { return item.categoryroot === rootitemid })
        return zwlistnew
      } else {
        let dqlistnew = []
        dqlistnew = this.dqlist.filter(item => { return item.categoryroot === rootitemid })
        return dqlistnew
      }
    },
    sendresume (formName, t) {
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          this.loading = true
          this.resume.resumejobstatus = parseInt(this.resume.resumejobstatus)
          this.resume.resumearea = this.resume.resumearea.join().replace(',', '/')
          rresumeput(this.resume).then(res => {
            if (res.data.status === 200) {
              this.loading = false
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              if (t === 1) {
                this.getresumes()
                this.re = 're2'
              } else if (t === 2) {
                this.re = 're3'
              }
            } else {
              this.$message({
                message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
                type: 'error'
              })
            }
          }).catch(res => {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！',
              type: 'error'
            })
          })
        } else {
          const a = []
          for (const key in obj) {
            a.push(obj[key][0].message)
          }
          this.$message({
            message: '::>_<:: ' + a[0] + '！',
            type: 'error'
          })
          return false
        }
      })
    },
    getresumes () {
      rgetresume().then(res => {
        if (res.data.status === 200) {
          this.resume = res.data.resultbody
          this.resume.resumearea = this.resume.resumearea.split('/')
          if (this.resume.resumeindustry.length > 0) {
            this.hylistvvv = JSON.parse(this.resume.resumeindustry.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resume.resumejobposition.length > 0) {
            this.zwlistvvv = JSON.parse(this.resume.resumejobposition.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resume.resumejobcity.length > 0) {
            this.dqlistvvv = JSON.parse(this.resume.resumejobcity.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resume.resumeworkexperience.length > 20) {
            this.resumeworkexperience = JSON.parse(this.resume.resumeworkexperience.replace(/\n/g, '').replace(/\r/g, ''))
          }
          if (this.resume.resumeexperienceeducation.length > 20) {
            this.resumeexperienceeducation = JSON.parse(this.resume.resumeexperienceeducation.replace(/\n/g, '').replace(/\r/g, ''))
          }
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    handleClick (el) {
      if (el.props.label === '工作经历') {
        this.getcategory(1)
      }
    },
    sendword (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.workex.workexid === 0) {
            this.workex.workexid = this.resumeworkexperience.length + 1
          }
          const resumeworkexperiencenew = []
          for (let i = 0; i < this.resumeworkexperience.length; i++) {
            const item = this.resumeworkexperience[i]
            if (this.workex.workexid !== item.workexid) {
              resumeworkexperiencenew.push(item)
            }
          }
          resumeworkexperiencenew.push(this.workex)
          this.resumeworkexperience = resumeworkexperiencenew
          this.resume.resumeworkexperience = JSON.stringify(this.resumeworkexperience)
          this.sendresumeinfo()
        } else {
          return false
        }
      })
    },
    workedit (item) {
      this.workshow = 'edit'
      this.workex = item
    },
    workdel (item) {
      const resumeworkexperiencenew = []
      for (let i = 0; i < this.resumeworkexperience.length; i++) {
        if (this.resumeworkexperience[i].workexid !== item.workexid) {
          resumeworkexperiencenew.push(this.resumeworkexperience[i])
        }
      }
      this.resumeworkexperience = resumeworkexperiencenew
      this.resume.resumeworkexperience = JSON.stringify(this.resumeworkexperience)
      this.sendresumeinfo()
    },
    sendeducate (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.educateex.educateexid === 0) {
            this.educateex.educateexid = this.resumeexperienceeducation.length + 1
          }
          const resumeexperienceeducationnew = []
          for (let i = 0; i < this.resumeexperienceeducation.length; i++) {
            const item = this.resumeexperienceeducation[i]
            if (this.educateex.educateexid !== item.educateexid) {
              resumeexperienceeducationnew.push(item)
            }
          }
          resumeexperienceeducationnew.push(this.educateex)
          this.resumeexperienceeducation = resumeexperienceeducationnew
          this.resume.resumeexperienceeducation = JSON.stringify(this.resumeexperienceeducation)
          this.sendresumeinfo()
        } else {
          return false
        }
      })
    },
    educateedit (item) {
      this.educateshow = 'edit'
      this.educateex = item
    },
    educatedel (item) {
      const resumeexperienceeducationnew = []
      for (let i = 0; i < this.resumeexperienceeducation.length; i++) {
        if (this.resumeexperienceeducation[i].educateexid !== item.educateexid) {
          resumeexperienceeducationnew.push(this.resumeexperienceeducation[i])
        }
      }
      this.resumeexperienceeducation = resumeexperienceeducationnew
      this.resume.resumeexperienceeducation = JSON.stringify(this.resumeexperienceeducation)
      this.sendresumeinfo()
    },
    sendresumeinfo () {
      rresumeput(this.resume).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.workex = { workexid: 0 }
          this.educateex = { educateexid: 0 }
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.workshow = 'list'
          this.educateshow = 'list'
        } else {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.containerbox {
  margin-top: 20px;
  border-radius: 0px;
}
// .common-layout
// {
//  // position: fixed;
//   left: 0;
//   top: 0px;
//   right: 0;
//   bottom: 0;
//   // display: flex;
//   justify-content: center;
//   align-items: center;
//   padding-bottom: 120px;

// .regtitle{text-align: left; font-size: 25px;padding-left: 30px; margin-bottom: 2px;}
// .regtitle1{line-height: 42px;margin-top: 2px;}
// .regbox{
//   padding-right: 60px;
//   padding-top: 2px;
//   padding-bottom: 2px;
//   margin-top: 2px;
// }
// }
.yxbox{width:100%;padding-top:6px;}
.forminfo {
  .el-tag { margin-right: 6px; margin-top: 3px; margin-bottom: 3px; color:#222222;height:25px;cursor: pointer;}
}
.categoryname{
  border-top-color: #ececec;border-top-width: 1px;border-top-style: solid;line-height: 32px;
  border-bottom-color: #ececec;border-bottom-width: 1px;border-bottom-style: solid;
  margin-top: -30px; padding-top: 12px;padding-bottom: 16px;
  .el-tag { margin-right: 6px; margin-top: 3px; margin-bottom: 3px; color:#CC3333;height:25px;cursor: pointer; border-width:1px; border-color: #cc3333;background-color: #ffffff;}
}
.categorylist{font-size: 14px;line-height: 32px; padding-top: 12px; max-height: 418px;cursor: pointer;
  width:100%;
}
.rcitem{background-color: #666666;}
.zcitem{width:32%; margin-left: 1%; float: left;}
.zcitem2{width:9%; margin-left: 1%; float: left;}
.zcitem3{
  cursor:pointer;width:21%; margin-left: 1%; float: left; border: 1px #f0f0f0 solid;border-radius: 8px;margin-bottom: 8px; font-size: 12px;padding:6px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.zcitemroot{line-height: 42px;}

.cleartext{font-size: 16px;}

.rootiteminfo{
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}
.rootiteminfoa{
  font-size: 14px;
  text-align: center;
  font-size: 14px;
}
.iteminfo{
  justify-content: center;
  align-items: center;
}
.itemboxa {padding-left: 8px;background-color: #f8f8f8;width:100%;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 12px;padding-top: 12px;}
.itemboxb {padding-left: 8px;width:100%;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 12px;padding-top: 12px;}
.itemboxaa {padding-left: 8px;background-color: #f8f8f8;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 6px;padding-top: 6px;}
.itemboxbb {padding-left: 8px;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 6px;padding-top: 6px;}

.exitembox{
  width:98%;
  padding:6px;
  border-color: #ececec;
  border-width: 1px;
  border-style: solid;
  border-radius:6px;
  margin-bottom: 8px;
  font-size: 14px;
.exitem{color: #888888; line-height: 26px;
b{color: #333333;margin-left: 8px;font-weight: normal;}
 }
 .exitemedit{text-align: right;}
}
</style>
