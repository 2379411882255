function curDateTime () {
  const myDate = new Date()
  const year = myDate.getFullYear()
  const month = myDate.getMonth() + 1
  const date = myDate.getDate()
  const h = myDate.getHours()
  const m = myDate.getMinutes()
  const s = myDate.getSeconds()
  const ss = myDate.getMilliseconds()
  const now = year + (month < 10 ? '0' : '') + month + ((date < 10 ? '0' : '')) + date + '' + ((h < 10 ? '0' : '')) + h + ((m < 10 ? '0' : '')) + m + ((s < 10 ? '0' : '')) + s + '' + ss
  return now
}
function getDateTimeToString (dateinfo) {
  const date_ = new Date(dateinfo)
  const year = date_.getFullYear()
  const days = date_.getDate()
  const month = date_.getMonth()
  const hours = date_.getHours()
  const mins = date_.getMinutes()
  const secs = date_.getSeconds()
  return year + '-' + month + '-' + days + ' ' + hours + ':' + mins + ':' + secs
}

function getTodays () {
  // alert('eee')
  const date_ = new Date()
  const year = date_.getFullYear()
  const days = date_.getDate()
  const month = date_.getMonth() + 1
  // alert(year + '-' + month + '-' + days)
  return year + '-' + month + '-' + days
}

function getDay (startDate, endDate) {
  const startTime = new Date(Date.parse(startDate.replace(/-/g, '/'))).getTime()
  const endTime = new Date(Date.parse(endDate.replace(/-/g, '/'))).getTime()
  const dates = Math.abs((startTime - endTime)) / (1000 * 60 * 60 * 24)
  if (startTime < endTime) {
  // alert(startDate + '<' + endDate + '||' + dates)
    return 0
  } else {
    // alert(startDate + '>' + endDate + '||' + dates)
    return dates
  }
}

function getAge (val) {
  let valinfo = ''
  if (val.split('-').length > 2) {
    const vy = val.split('-')[0]
    let vm = val.split('-')[1]
    if (vm.length < 2) {
      vm = '0' + vm
    }
    let vd = val.split('-')[2]
    if (vd.length < 2) {
      vd = '0' + vd
    }
    valinfo = vy + '-' + vm + '-' + vd
    const currentYear = new Date().getFullYear() // 当前的年份
    const calculationYear = new Date(valinfo).getFullYear() // 计算的年份
    const wholeTime = currentYear + valinfo.substring(4) // 周岁时间
    let calculationAge = currentYear - calculationYear // 按照年份计算的年龄
    if (calculationAge === 0) {
      calculationAge = 26
    }
    // 判断是否过了生日
    if (new Date().getTime() > new Date(wholeTime).getTime()) {
      return calculationAge + '岁'
    } else {
      return calculationAge - 1 + '岁'
    }
  } else {
    return val
  }
}

export default {
  curDateTime,
  getDateTimeToString,
  getTodays,
  getDay,
  getAge
}
