<template>
<el-row gutter="32" class="count_a">
    <el-col :span="24" class="leftcontainer">
       <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="职位搜索" name="first" class="listbox">{{ searchkey }}
              <div :style="stylebox" v-infinite-scroll="getjoblist" infinite-scroll-distance="20" class="scrollbarbox">
                <el-row class="el-row" gutter="12" v-for="item in joblist" :key="item.jobid" style="width:100%;text-align: left;">
                  <el-col :span="16">
                  <div><el-link class="el-link" :underline="false" :href="'/jobitem?id='+item.jobid" target="_blank">{{item.jobname}}</el-link>
                    <span style="margin-left: 28px;">{{ item.jobdates.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}</span>
                  </div>
                  <div>
                    <span class="xinzi" v-if="item.jobsalarys!=''">￥{{ item.jobsalarys }}<el-divider direction="vertical" /></span>
            <span class="xinzi" v-else>￥{{ item.jobsalary }}<el-divider direction="vertical" /></span>
                    <el-divider direction="vertical" />
                    <span>经验:{{ item.jobexperience }}</span>
                    <el-divider direction="vertical" />
                    <span>{{ item.jobeducation }}</span>
                    <el-divider direction="vertical" />
                    <span>{{ item.jobarea }}</span><br />
                    <span>{{ item.jobcategory }}</span>
                    <span v-if="item.jobcategorys.length>0"> / {{ item.jobcategorys }}</span>
                  </div>
                  <div style="padding-top:8px;" v-if="item.jobwelfare.length>0">
                    <el-button v-for="bitem in item.jobwelfare.substring(0,item.jobwelfare.length-1).split(',')" :key="bitem" text bg size="small">{{ bitem }}</el-button>
                  </div>
                  </el-col>
                  <el-col :span="8">
                    <div>
                      <el-link class="el-link2" :underline="false" :href="'/companyitem?id='+item.jobusersid" target="_blank">{{item.jobcompanyname }}</el-link>
                    </div>
                    <div>
                    <span v-if="item.JobIndustry!=''">{{item.JobIndustry}}</span><br />
                    <span v-if="item.JobCompanyType!=''">{{item.JobCompanyType}}</span>
                    </div>
                  </el-col>
                  <el-col :span="24">
                  <div style="text-align: right;margin-top: 8px; ">
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="jobsq(item)"><el-icon style="margin-right:2px;"><Position /></el-icon>申请</el-button>
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="scsend(item)"><el-icon style="margin-right:2px;"><Collection /></el-icon>收藏</el-button>
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="chatopen(item.jobusersid)"><el-icon style="margin-right:2px;"><ChatDotRound /></el-icon>沟通</el-button>
                    </div>
                  </el-col>
                  <el-divider />
                </el-row>
                <div v-if="loading==false" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><img src="/images/loading.gif" style="border-width: 0px;" /><br />稍等数据加载中</div>
                <div v-if="loading==true" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><el-icon><WarningFilled /></el-icon><br />没有了...</div>
              </div>
          </el-tab-pane>
        </el-tabs>
    </el-col>
    <!-- -------------------------------------- -->
  </el-row>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:9999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>

import { jobsearch, applysq, collectyonsc } from '@/api/user'
import store from '@/store/index'

export default {
  name: 'homeIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: false,
      resumeface: '',
      boxheight: 518,
      drawer: 'none',
      iframeSrc: '',
      searchkey: '',
      stylebox: 'width:100%;height: 200px;overflow: auto',
      activeName: 'first',
      statusvalue: '1',
      currentpage: 1,
      joblist: [],
      baseurl: store.state.baseUrl
    }
  },
  computed: {
    disabled () {
      return this.loading
    }
  },
  watch: {},
  created () {
    const listheight = document.documentElement.clientHeight - 218
    this.stylebox = 'width:100%;height: ' + listheight + 'px;overflow: auto'
    this.boxheight = listheight
    if (this.$route.query.key != null) {
      this.searchkey = this.$route.query.key
    }
  },
  mounted () {},
  methods: {
    getjoblist () {
      jobsearch(this.currentpage, this.searchkey).then(res => {
        if (res.data.resultbody != null) {
          if (res.data.resultbody.length < 30) {
            setTimeout(() => { this.loading = true }, 100)
          } else {
            this.currentpage++
            setTimeout(() => { this.loading = false }, 100)
          }
          if (this.currentpage === 1) {
            this.jobtotal = res.data.resultbody.total
          }
          for (let i = 0; i < res.data.resultbody.length; i++) {
            this.joblist.push(res.data.resultbody[i])
          }
        } else {
          setTimeout(() => { this.loading = true }, 100)
        }
      })
    },
    setsearchkey (sv) {
      this.joblist = []
      this.currentpage = 1
      this.searchkey = sv
      setTimeout(() => { this.loading = false }, 300)
      this.getjoblist()
    },
    jobsq (item) {
      const companyid = item.jobusersid
      const jobids = item.jobid
      if (companyid > 0 && jobids > 0) {
        applysq(jobids, companyid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '::>_<:: 请选择邀请面试的岗位！',
          type: 'error'
        })
      }
    },
    scsend (item) {
      collectyonsc(item.jobid).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: ' + res.data.message + '',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    chatopen (id) {
      this.drawer = 'block'
      this.iframeSrc = '/chats?id=' + id
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
}
.bg-purple {
background-color: #ffffff;
}
.bg-purple-light {
  background-color: bisque;
}
.count_a {
  text-align: center;
  line-height: 22px;
  .el-card{
  color: #909399;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px;
  border-radius: 0px;
  margin-bottom: 6px;
  margin-right: 5px;
  .countstyle {
    font-size: 16px;
    color: #000000;
  }
  }}
.rightcontainer {
  border-left-width:1px;
  border-left-style: solid;
  border-radius: 0px;
  border-left-color:  #dddfe5;

  .rightyx{
    font-size: 13px;
    .el-tag{margin-bottom: 5px; margin-right: 3px;}
  }
}

.listbox
{
  font-size: 14px;line-height:22px;
  .idbox{margin-right: 12px;}
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
.el-link{font-size: 14px;font-weight: bold; color: #333333;}
.el-link2{font-size: 12px;font-weight:normal;color:#666666;}
.el-divider {
    margin-top:8px;
    margin-bottom: 12px;
  }
  .xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
}
</style>
