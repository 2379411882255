import axios from 'axios'
import token from '@/utils/token'
import store from '@/store/index'

const request = axios.create({
  baseURL: store.state.baseUrl
})
// 请求拦截器
request.interceptors.request.use(
  function (config) {
    const tokeninfo = token.gettoken('utoken')
    if (tokeninfo != null) {
      config.headers.token = tokeninfo
    }
    if (config.url === '/user/codesend') {
      config.headers.token = config.data
    }
    return config
  }
)
// 响应拦截器

export default request
