<template>
  <div class="readingcontainer" v-loading="loadingmain">
    <el-row :gutter="30">
      <el-col :span="20" style="padding-left: 42px;">
          <el-tabs v-model="activeName" type="card"  @tab-click="tabsclick">
            <el-tab-pane label="紧急推广" name="t1" class="retainlist">
              <div class="scrollbarbox" :style="'height:'+(boxheight-242)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:96%;">
              <!-- -------------------------------------------- -->
              <el-row gutter="50">
                <el-col :span="24">
                  <!-- -------------------------------------------- -->
                 <h3>推广操作</h3>
                 <div style="line-height:32px;">
                  剩余推广次数 <b>{{ companys.companyserver4 }}</b> 次
                  <el-button v-if="companys.companyadpic1!=''&&companys.companyserver4>0" color="#003472" size="small" round style="font-size:12px; margin-left: 28px;" @click="adsend(2)"><el-icon style="margin-right:6px;"><View /></el-icon>申请推广 </el-button>
                  <br />
                  <el-image style="width: 143px;height:40px; margin-top: 12px;" :src="baseurl+companys.companyadpic1" :fit="fit" />
                  <el-form size="small" hide-required-asterisk="true" class="formcontainer" label-position="top" style="margin-top:10px; margin-bottom: 10px;">
                      <el-upload :before-upload="beforeuploadad1" :action="companyuploadad1" method="post" name="file" style="margin-top:6px;" :on-success="onsuccessad1" :headers="headers" :show-file-list="false">
                        <el-button size="small" type="info" plain><el-icon style="margin-right:8px"><Camera /></el-icon>上传推广图片</el-button>
                      </el-upload>
                  </el-form>
                 <span style="font-size:12px;">(规格143*40 单位像素)</span>
                 </div>
                </el-col>
              </el-row>
                <el-row gutter="50">
                <el-col :span="24">
                  <!-- -------------------------------------------- -->
                 <h3>推广记录</h3>
                 <el-row gutter="2" v-for="item in promotionlist" :key="item.promotionid" style="font-size: 12px;color:#777777;">
                    <el-col :span="24">
                      申请时间：{{ item.promotiondate.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}
                      <span style="margin-left: 20px;"><el-tag v-if="item.promotionstatus==1" type="success" effect="dark" round>已审</el-tag>
                           <el-tag v-else type="danger" effect="dark" round>待审</el-tag>
                           </span>
                      <span style="margin-left: 20px;" v-if="item.promotionstatus==1">
                      开始：{{ item.promotionstart.replaceAll(/T/ig," ").replaceAll(/Z/ig," ").substring(0,10) }}
                      </span>
                      <span style="margin-left: 20px;" v-if="item.promotionstatus==1">
                      结束：{{ item.promotionend.replaceAll(/T/ig," ").replaceAll(/Z/ig," ").substring(0,10) }}
                      </span>
                    </el-col>
                    <el-divider />
                  </el-row>
                </el-col>
              </el-row>
              <!-- -------------------------------------------- -->
              </div>
              </div>
            </el-tab-pane>
            <!-- -------------------------------------------- -->
            <el-tab-pane label="品牌推广" name="t2" class="retainlist">
              <div class="scrollbarbox" :style="'height:'+(boxheight-242)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:96%;">
              <!-- -------------------------------------------- -->
              <el-row gutter="50">
                <el-col :span="24">
                  <!-- -------------------------------------------- -->
                 <h3>推广操作</h3>
                 <div style="line-height:32px;">
                  剩余推广次数 <b>{{ companys.companyserver5 }}</b> 次
                  <el-button v-if="companys.companyadpic2!=''&&companys.companyserver5>0"   color="#003472" size="small" round style="font-size:12px; margin-left: 28px;" @click="adsend(5)"><el-icon style="margin-right:6px;"><View /></el-icon>申请推广 </el-button>
                  <br />
                  <el-image style="width: 228px;height:60px; margin-top: 12px;" :src="baseurl+companys.companyadpic2" :fit="fit" />
                  <el-form size="small" hide-required-asterisk="true" class="formcontainer" label-position="top" style="margin-top:10px; margin-bottom: 10px;">
                      <el-upload :before-upload="beforeuploadad2" :action="companyuploadad2" method="post" name="file" style="margin-top:6px;" :on-success="onsuccessad2" :headers="headers" :show-file-list="false">
                        <el-button size="small" type="info" plain><el-icon style="margin-right:8px"><Camera /></el-icon>上传推广图片</el-button>
                      </el-upload>
                  </el-form>
                 <span style="font-size:12px;">(规格228*60 单位像素)</span>
                 </div>
                </el-col>
              </el-row>
                <el-row gutter="50">
                <el-col :span="24">
                  <!-- -------------------------------------------- -->
                 <h3>推广记录</h3>
                 <el-row gutter="2" v-for="item in promotionlist" :key="item.promotionid" style="font-size: 12px;color:#777777;">
                    <el-col :span="24">
                      申请时间：{{ item.promotiondate.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}
                      <span style="margin-left: 20px;"><el-tag v-if="item.promotionstatus==1" type="success" effect="dark" round>已审</el-tag>
                           <el-tag v-else type="danger" effect="dark" round>待审</el-tag>
                           </span>
                      <span style="margin-left: 20px;" v-if="item.promotionstatus==1">
                      开始：{{ item.promotionstart.replaceAll(/T/ig," ").replaceAll(/Z/ig," ").substring(0,10) }}
                      </span>
                      <span style="margin-left: 20px;" v-if="item.promotionstatus==1">
                      结束：{{ item.promotionend.replaceAll(/T/ig," ").replaceAll(/Z/ig," ").substring(0,10) }}
                      </span>
                    </el-col>
                    <el-divider />
                  </el-row>
                </el-col>
              </el-row>
              <!-- -------------------------------------------- -->
              </div>
              </div>
            </el-tab-pane>
            <!-- -------------------------------------------- -->
            <el-tab-pane label="全通推广" name="t3" class="retainlist">
              <div class="scrollbarbox" :style="'height:'+(boxheight-242)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:96%;">
              <!-- -------------------------------------------- -->
              <el-row gutter="50">
                <el-col :span="24" style="border-right-width:1px;border-right-style: solid;border-right-color: #ececec;">
                  <!-- -------------------------------------------- -->
                 <h3>推广操作</h3>
                 <div style="line-height:32px;">
                  剩余推广次数 <b>{{ companys.companyserver8 }}</b> 次
                  <el-button v-if="companys.companyadpic3!=''&&companys.companyserver8>0"  color="#003472" size="small" round style="font-size:12px; margin-left: 28px;" @click="adsend(6)"><el-icon style="margin-right:6px;"><View /></el-icon>申请推广 </el-button>
                  <br />
                  <el-image style="width: 98%;height:60px; margin-top: 12px;" :src="baseurl+companys.companyadpic3" :fit="fit" />
                  <el-form size="small" hide-required-asterisk="true" class="formcontainer" label-position="top" style="margin-top:10px; margin-bottom: 10px;">
                      <el-upload :before-upload="beforeuploadad3" :action="companyuploadad3" method="post" name="file" style="margin-top:6px;" :on-success="onsuccessad3" :headers="headers" :show-file-list="false">
                        <el-button size="small" type="info" plain><el-icon style="margin-right:8px"><Camera /></el-icon>上传推广图片</el-button>
                      </el-upload>
                  </el-form>
                 <span style="font-size:12px;">(规格946*60 单位像素)</span>
                 </div>
                </el-col>
                <el-col :span="24">
                  <!-- -------------------------------------------- -->
                 <h3>推广记录</h3>
                 <el-row gutter="2" v-for="item in promotionlist" :key="item.promotionid" style="font-size: 12px;color:#777777;">
                    <el-col :span="24">
                      申请时间：{{ item.promotiondate.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}
                      <span style="margin-left: 20px;"><el-tag v-if="item.promotionstatus==1" type="success" effect="dark" round>已审</el-tag>
                           <el-tag v-else type="danger" effect="dark" round>待审</el-tag>
                           </span>
                      <span style="margin-left: 20px;" v-if="item.promotionstatus==1">
                      开始：{{ item.promotionstart.replaceAll(/T/ig," ").replaceAll(/Z/ig," ").substring(0,10) }}
                      </span>
                      <span style="margin-left: 20px;" v-if="item.promotionstatus==1">
                      结束：{{ item.promotionend.replaceAll(/T/ig," ").replaceAll(/Z/ig," ").substring(0,10) }}
                      </span>
                    </el-col>
                    <el-divider />
                  </el-row>
                </el-col>
              </el-row>
              <!-- -------------------------------------------- -->
              </div>
              </div>
            </el-tab-pane>
            <!-- -------------------------------------------- -->
            <el-tab-pane label="半通推广" name="t4" class="retainlist">
              <div class="scrollbarbox" :style="'height:'+(boxheight-242)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:96%;">
              <!-- -------------------------------------------- -->
              <el-row gutter="50">
                <el-col :span="24">
                  <!-- -------------------------------------------- -->
                 <h3>推广操作</h3>
                 <div style="line-height:32px;">
                  剩余推广次数 <b>{{ companys.companyserver7 }}</b> 次
                  <el-button v-if="companys.companyadpic4!=''&&companys.companyserver7>0"  color="#003472" size="small" round style="font-size:12px; margin-left: 28px;" @click="adsend(7)"><el-icon style="margin-right:6px;"><View /></el-icon>申请推广 </el-button>
                  <br />
                  <el-image style="width: 470px;height:60px; margin-top: 12px;" :src="baseurl+companys.companyadpic4" :fit="fit" />
                  <el-form size="small" hide-required-asterisk="true" class="formcontainer" label-position="top" style="margin-top:10px; margin-bottom: 10px;">
                      <el-upload :before-upload="beforeuploadad4" :action="companyuploadad4" method="post" name="file" style="margin-top:6px;" :on-success="onsuccessad4" :headers="headers" :show-file-list="false">
                        <el-button size="small" type="info" plain><el-icon style="margin-right:8px"><Camera /></el-icon>上传推广图片</el-button>
                      </el-upload>
                  </el-form>
                 <span style="font-size:12px;">(规格470*60 单位像素)</span>
                 </div>
                </el-col>
                </el-row>
                <el-row gutter="50">
                <el-col :span="24">
                  <!-- -------------------------------------------- -->
                 <h3>推广记录</h3>
                 <el-row gutter="2" v-for="item in promotionlist" :key="item.promotionid" style="font-size: 12px;color:#777777;">
                    <el-col :span="24">
                      申请时间：{{ item.promotiondate.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}
                      <span style="margin-left: 20px;"><el-tag v-if="item.promotionstatus==1" type="success" effect="dark" round>已审</el-tag>
                           <el-tag v-else type="danger" effect="dark" round>待审</el-tag>
                           </span>
                      <span style="margin-left: 20px;" v-if="item.promotionstatus==1">
                      开始：{{ item.promotionstart.replaceAll(/T/ig," ").replaceAll(/Z/ig," ").substring(0,10) }}
                      </span>
                      <span style="margin-left: 20px;" v-if="item.promotionstatus==1">
                      结束：{{ item.promotionend.replaceAll(/T/ig," ").replaceAll(/Z/ig," ").substring(0,10) }}
                      </span>
                    </el-col>
                    <el-divider />
                  </el-row>
                </el-col>
              </el-row>
              <!-- -------------------------------------------- -->
              </div>
              </div>
            </el-tab-pane>
      </el-tabs>
    </el-col>
    <el-col :span="3"></el-col>
    </el-row>
  </div>
</template>

<script>
import { companys, promotionput, promotionget } from '@/api/user'
import store from '@/store/index'
import token from '@/utils/token'
export default {
  name: 'advertisementIndex',
  components: {},
  data () {
    return {
      loadingmain: true,
      boxheight: 518,
      baseurl: store.state.baseUrl,
      carpassword: '',
      activeName: 't1',
      companys: {},
      headers: {},
      companyuploadad1: store.state.baseUrl + 'company/pic?t=5&pt=1', // 上传路径jj
      companyuploadad2: store.state.baseUrl + 'company/pic?t=5&pt=2', // 上传路径pa
      companyuploadad3: store.state.baseUrl + 'company/pic?t=5&pt=3', // 上传路径qt
      companyuploadad4: store.state.baseUrl + 'company/pic?t=5&pt=4', // 上传路径bt
      promotionlist: []
    }
  },
  created () {
    this.boxheight = document.documentElement.clientHeight
    this.headers.token = token.gettoken('utoken')
    this.getcompanys()
    this.getlist(2)
  },
  methods: {
    getcompanys () {
      companys().then(res => {
        if (res.data.status === 200) {
          this.companys = res.data.resultbody
          this.companys.companycontent = this.companys.companycontent.replaceAll(/<br\/>/ig, '\r\n')
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      })
    },
    beforeuploadad1 (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的图片！')
        return false
      } else if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/gif' && rawFile.type !== 'image/gif') {
        alert('请上传jpg或者png格式的图片！')
        return false
      }
      return true
    },
    onsuccessad1 (uploadFilea, uploadFileb, uploadFilec) {
      this.getcompanys()
    },
    beforeuploadad2 (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的图片！')
        return false
      } else if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/gif') {
        alert('请上传jpg或者png格式的图片！')
        return false
      }
      return true
    },
    onsuccessad2 (uploadFilea, uploadFileb, uploadFilec) {
      this.getcompanys()
    },
    beforeuploadad3 (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的图片！')
        return false
      } else if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/gif') {
        alert('请上传jpg或者png格式的图片！')
        return false
      }
      return true
    },
    onsuccessad3 (uploadFilea, uploadFileb, uploadFilec) {
      this.getcompanys()
    },
    beforeuploadad4 (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的图片！')
        return false
      } else if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/gif') {
        alert('请上传jpg或者png格式的图片！')
        return false
      }
      return true
    },
    onsuccessad4 (uploadFilea, uploadFileb, uploadFilec) {
      this.getcompanys()
    },
    getlist (t) {
      this.loadingmain = true
      promotionget(t).then(res => {
        if (res.data.status === 200) {
          this.promotionlist = res.data.resultbody.resultlist
        } else {
          alert('操作异常')
        }
        setTimeout(() => { this.loadingmain = false }, 300)
      })
    },
    adsend (t) {
      promotionput(t).then(res => {
        if (res.data.status === 200) {
          alert('操作成功')
          this.getcompanys()
          this.getlist(t)
        } else {
          alert(res.data.message)
        }
      })
    },
    tabsclick (titem) {
      // alert(JSON.stringify(titem.props.name))
      if (titem.props.name === 't1') {
        this.getlist(2)
      } else if (titem.props.name === 't2') {
        this.getlist(5)
      } else if (titem.props.name === 't3') {
        this.getlist(6)
      } else if (titem.props.name === 't4') {
        this.getlist(7)
      }
    }
  }
}
</script>

<style lang="less" scoped>
td{font-size: 14px;}

.pidbox{margin-right: 8px;}
.resumegendera{position:absolute;top:2px;left:72px;width:16px;height: 16px;background-color: #003399; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.resumegenderb{position:absolute;top:2px;left:72px;width:16px;height: 16px;background-color: #FF0066; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.bordera{border-width: 2px;border-color: #003399; border-style: solid;width:68px;height:68px;border-radius: 68px;}
.borderb{border-width: 2px;border-color: #FF0066; border-style: solid;width:68px;height:68px;border-radius: 68px;}
.titlea{
  text-align: left;padding-left: 12px;font-size: 16px;margin-top: 2px; margin-bottom: 2px;
}
.readingcontainer {
  margin-top: 20px;
  border-radius: 0px;
}

.tg{
  height:52px;
  width:52px;
  // font-size: 12px;
  border-width: 0px;margin-right: 20px;
  text-align: center;line-height:14px;
}
.retaincompany {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
  color: #888888;
  .el-link {
    font-size: 14px;
    color: #333333;
  }
}
.resumecompanylogo {
  text-align: left;
  margin-top: 6px;
}
.retainlist
{
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.retaininfo {
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;font-size: 16px;}
  .infott{text-align: right; display:inline-block; width:68px;}
}
.rightyx {
  margin-left: 12px;
  margin-right:12px;
  text-align: left;
  font-size: 14px;
  color: #777777;
  line-height: 28px;
  .el-row {
    border-bottom-width:1px;
    border-bottom-style: solid;
    border-bottom-color:  #dddfe5;
    padding-top: 2px;
    padding-bottom: 8px;
  }
  .el-tab-pane {
    padding-left: 20px;
    .el-tag { margin-right: 6px; color:#222222;}
  }
}
</style>
