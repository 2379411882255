<template>
  <div class="collectioncontainer" v-loading="loadingmain" style="margin-top: 0px;">
    <el-row :gutter="30">
        <el-col :span="24">
          <!-- -------------------------------------------- -->
          <el-affix :offset="82">
           <a :href="'/companyitem?id='+companys.companyusersid" target="_blank"><el-button color="#333333" style="float: left;margin-left: 298px;">企业招聘预览</el-button></a>
          </el-affix>
          <el-tabs type="card">
            <!-- tab 1 -------------------------------------------- -->
            <el-tab-pane label="公司信息" style="padding-left: 60px;">
              <div style="padding-bottom: 12px;display: none;">
              <el-row :gutter="0">
                <el-col :span="24" style="text-align: right;">
                </el-col>
              </el-row>
              </div>
              <div class="scrollbarbox" :style="'height:'+(boxheight-208)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;">
              <el-form style="width:100%;" label-width="82px" size="default" label-position="right" class="forminfo" ref="company"  :model="companys" :rules="companysrules" >
                <h4 style="margin-top: 2px;margin-bottom: 6px;">基本信息
                  <el-affix :offset="128">
                  <el-button color="#ba181b" style="float: right;" @click="sendcompanys('company')" :loading="loading">保存公司信息</el-button>
                  </el-affix>
                </h4>
                <!-- 基本信息 ----------------------------------------- -->
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="企业名称">
                            {{companys.companyname}}
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="企业性质" prop="companytype">
                          <el-select v-model="companys.companytype" placeholder="公司性质">
                            <el-option v-for="item in gsxzlist" :key="item.categoryid" :value="item.categoryname" :label="item.categoryname"/>
                            </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="公司行业" prop="companyindustry" :filterable="true">
                          <el-select v-model="companys.companyindustry" placeholder="公司行业">
                                <el-option v-for="item in hylist" :key="item.categoryid" :value="item.categoryname" :label="item.categoryname"/>
                            </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                            <el-form-item label="地区" prop="companyarea">
                              <el-cascader v-model="companyareass" :options="dqlistinfo" placeholder="选择地区" />
                            </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="8">
                          <el-form-item label="成立时间" prop="companybirthday">
                            <el-config-provider :locale="zhCn">
                              <el-date-picker v-model="companys.companybirthday" type="date" placeholder="成立时间" value-format="YYYY-MM-DD" lang="zh-CN" :editable="false"/>
                            </el-config-provider>
                              </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="注册资金" prop="companyregisteredcapital">
                          <el-input type="text" v-model="companys.companyregisteredcapital" placeholder="注册资金"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="公司人数" prop="companyemployees">
                          <el-select v-model="companys.companyemployees" placeholder="公司人数">
                            <el-option v-for="item in gsrslist" :key="item.categoryid" :value="item.categoryname" :label="item.categoryname"/>
                            </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 基本信息 ----------------------------------------- -->
                   <!-- 联系方式 ----------------------------------------- -->
                   <h4 style="margin-top: 2px;margin-bottom: 6px;">联系方式</h4>
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="联系人" prop="companycontacts">
                            <el-input type="text" v-model="companys.companycontacts "/>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="联系电话" prop="companyusersphone">
                            <el-input type="text" v-model="companys.companyusersphone "/>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="联系邮件" prop="companymail">
                            <el-input type="text" v-model="companys.companymail"/>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="联系地址" prop="companyaddress">
                            <el-input type="text" v-model="companys.companyaddress "/>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="乘车路线" prop="companybux">
                            <el-input type="text" v-model="companys.companybus "/>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="插入地图" prop="rescompany">
                            <div style="width:100%;margin-bottom: 8px;"><el-button size="small" @click="drawer=block">设置地图</el-button></div>
                            <el-input type="text" v-model="companys.companymap" style="display: none;"/>
                            <iframe id="mapiframe" v-if="isrc2.length>0" :src="isrc2" :style="'padding:1px;height:318px;width:88%;min-height:308px;background-color:#cccccc;'" frameborder="0"></iframe>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 联系方式 ----------------------------------------- -->
                    <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item label="公司介绍" prop="companycontent">
                        <el-input type="textarea" v-model="companys.companycontent" :rows="12" placeholder="公司介绍不少于50字" />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <span ref="back"></span>
              </el-form>
              </div>
              </div>
            </el-tab-pane>
            <!-- tab 2 -------------------------------------------- -->
            <el-tab-pane label="部门信息" style="padding-left: 30px;">
              <div class="scrollbarbox" :style="'height:'+(boxheight-208)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:98%;">
              <!-- 部门 ----------------------------------------- -->
                  <el-row :gutter="20" style="margin-bottom: 18px;">
                        <el-col :span="24">
                          <h3>
                          <el-button style="float: right;margin-right: 58px;"  v-if="departmenteditshow=='list'" @click="departmenteditshow='edit',departmentinfos={}" color="#000000" ><div><el-icon style="margin-right: 3px;"><Plus /></el-icon>添加</div></el-button>
                          <el-button style="float: right;margin-right: 58px;" v-if="departmenteditshow=='edit'" @click="departmenteditshow='list'" color="#000000"><div><el-icon style="margin-right: 3px;"><Back /></el-icon>返回</div></el-button>
                          </h3>
                        </el-col>
                  </el-row>
                  <div v-if="departmenteditshow=='list'">
                  <el-row :gutter="20" v-for="item in departments" :key="item.workexid" class="exitembox">
                  <el-col :span="24">
                    <div class="exitem">部门:<b>{{ item.departmentname }}</b></div>
                    <div class="exitem">联系人:<b>{{ item.departmentcontacts }}</b></div>
                    <div class="exitem">电话:<b>{{ item.departmentphone }}</b></div>
                    <div class="exitem">邮件:<b>{{ item.departmentmail }}</b></div>
                    <div class="exitems">介绍:<b>{{ item.departmentcontent }}</b></div>
                    <div class="exitemedit">
                      <el-button color="#003472" size="small" @click="departmentedit(item)" round><el-icon style="margin-right: 8px;"><Edit /></el-icon> 编辑</el-button>
                      <el-button color="#be002f" size="small" @click="departmentdel(item)" round><el-icon style="margin-right: 8px;"><Delete /></el-icon> 删除</el-button>
                    </div>
                  </el-col>
                  </el-row>
                  </div>
                  <!-- ---------- ----------------------------------------- -->
                  <div v-if="departmenteditshow=='edit'" style="width:60%;padding-top:30px;">
                  <el-form label-width="82px" size="default" label-position="right" class="forminfo" ref="departmentinfo" :model="departmentinfos" :rules="departmentsrules" >
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="部门" prop="departmentname">
                              <el-input type="text" v-model="departmentinfos.departmentname"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="联系人" prop="departmentcontacts">
                              <el-input type="text" v-model="departmentinfos.departmentcontacts"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="电话" prop="departmentphone">
                              <el-input type="text" v-model="departmentinfos.departmentphone"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="邮件" prop="departmentmail">
                              <el-input type="text" v-model="departmentinfos.departmentmail"/>
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <el-form-item label="介绍" prop="departmentcontent">
                              <el-input type="textarea" v-model="departmentinfos.departmentcontent" :rows="3" />
                            </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="24" style="text-align: right;">
                          <el-button color="#ba181b" @click="senddepartment('departmentinfo')" :loading="loading">保存</el-button>
                        </el-col>
                      </el-row>
                  </el-form>
                  </div>
                  <!-- ---------- ----------------------------------------- -->
                  <!-- 部门 ----------------------------------------- -->
                  </div>
                  </div>
            </el-tab-pane>
            <!-- tab 3 -------------------------------------------- -->
            <el-tab-pane label="企业展示" style="padding-left: 10px; padding-right: 10px;">
              <div class="scrollbarbox" :style="'height:'+(boxheight-208)+'px;overflow-y:auto;overflow-x:none;'">
              <div style="width:96%;">
                <el-form size="default" hide-required-asterisk="true" class="formcontainer" label-position="top" style="margin-top:20px; margin-bottom: 20px;">
                  <el-row :gutter="60">
                  <el-col :span="24" class="code-content">
                    <div style="text-align:right;line-height: 28px;">
                      <el-upload :before-upload="beforeuploadpic" :action="companyuploadpic" method="post" name="file" style="margin-top:6px; width: 100%;" :on-success="onsuccesspic" :headers="headers" :show-file-list="false">
                        <el-button type="info" color="#000000" style="background-color: #000000; color:#ffffff;border-width: 0px;" plain><el-icon style="margin-right:8px"><Camera /></el-icon>点击上传</el-button>
                      </el-upload>
                    </div>
                    </el-col>
                  </el-row>
                </el-form>
                <el-row :gutter="22" v-if="companys.companyusersid!=null">
                  <el-col v-for="item in piclist" :key="item.index" :span="6" >
                    <el-card :body-style="{ padding: '0px' }" style="margin-bottom: 22px;">
                      <el-image :src="baseurl+'static/incpic/'+companys.companyusersid+'/inc/'+item" class="image" :fit="contain" />
                      <div style="padding: 14px">
                        <div style="text-align: right;">
                          <el-button  color="#be002f" size="small" round style="font-size:12px;" @click="picdelete(item)"><el-icon style="margin-right:6px;"><Delete /></el-icon>删除</el-button>
                        </div>
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
                </div>
                </div>
            </el-tab-pane>
        </el-tabs>
          <!-- -------------------------------------------- -->
        </el-col>
    </el-row>
  </div>
  <!-- ------------- -->
  <div :style="'border-top-left-radius:30px;border: 1px solid #cccccc;border-right-width:0px; border-bottom-width:0px;position:fixed;z-index:999;bottom:0;right:0;background-color:#ffffff;width:618px;height:518px;padding:20px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'">
    <el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 578px;" >关闭</el-button>
  </div>
  <h3>地图设置</h3>
  <span style="font-size: 12px;">1、输入地址 -》2、点击搜索地图 -》3、确定设置</span>
  <iframe id="mapiframe" :src="isrc" :style="'height:388px;width:90%;margin-left:6%;min-height:388px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
</template>

<script>
import store from '@/store/index'
import { companys, category, companysput, departments, departmentsput, departmentsdel, companypic } from '@/api/user'
import token from '@/utils/token'

import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

export default {
  name: 'collectionIndex',
  components: { [ElConfigProvider.name]: ElConfigProvider },
  props: {},
  data () {
    return {
      zhCn,
      loadingmain: true,
      boxheight: 518,
      isrc: '',
      isrc2: '',
      drawer: 'none',
      iframeSrc: '',
      baseurl: store.state.baseUrl,
      headers: {},
      companyuploadpic: store.state.baseUrl + 'company/pic?t=2', // 上传路径
      departmenteditshow: 'list',
      loading: false,
      hylist: [],
      dqlist: [],
      dqlistinfo: [],
      gsxzlist: [],
      gsrslist: [],
      companys: {},
      companysrules: {
        companytype: [
          { required: true, message: '企业性质必须选择', trigger: 'blur' }
        ],
        companyindustry: [
          { required: true, message: '企业行业必须选择', trigger: 'blur' }
        ],
        companyarea: [
          { required: true, message: '企业地区必须选择', trigger: 'blur' }
        ],
        companybirthday: [
          { required: true, message: '成立时间必须选择', trigger: 'blur' }
        ],
        companyregisteredcapital: [
          { required: true, message: '注册资金必须填写', trigger: 'blur' }
        ],
        companyemployees: [
          { required: true, message: '公司人数必须填写', trigger: 'blur' }
        ],
        companycontent: [
          { required: true, message: '公司介绍必须选择', trigger: 'blur' },
          { min: 50, max: 20000000, message: '公司介绍必须不少于50字', trigger: 'blur' }
        ],
        companycontacts: [
          { required: true, message: '联系人必须填写', trigger: 'blur' }
        ],
        companyusersphone: [
          { required: true, message: '联系电话必须填写', trigger: 'blur' }
        ],
        companymail: [
          { required: true, message: '联系邮件必须填写', trigger: 'blur' }
        ],
        companyaddress: [
          { required: true, message: '联系地址必须填写', trigger: 'blur' }
        ]
      },
      departments: [],
      departmentinfos: {},
      departmentsrules: {
        departmentname: [
          { required: true, message: '部门名称不能为空', trigger: 'blur' }
        ],
        departmentcontacts: [
          { required: true, message: '部门联系人不能为空', trigger: 'blur' }
        ],
        departmentphone: [
          { required: true, message: '部门电话不能为空', trigger: 'blur' }
        ],
        departmentmail: [
          { required: true, message: '部门邮件不能为空', trigger: 'blur' }
        ]
      },
      piclist: [],
      lng: '102.83944512326053',
      lat: '24.886272111644136',
      inctitle: '',
      inccontacts: '',
      incaddress: '',
      companyareass: []
    }
  },
  created () {
    this.boxheight = document.documentElement.clientHeight
    this.headers.token = token.gettoken('utoken')
    this.getcategory(2)
    this.getcategory(8)
    this.getcategory(9)
    this.getcategory(10)
    this.getcompanys()
    this.getdepartments()
    this.getpic()
    const _this = this
    window.setmap = (v) => {
      _this.setmaps(v)
    }
    window.uuurl = store.state.baseUrl
    window.lng = this.lng
    window.lat = this.lat
    window.incaddress = this.incaddress
    window.inccontacts = this.inccontacts
    window.inctitle = this.inctitle
  },
  methods: {
    getcompanys () {
      companys().then(res => {
        if (res.data.status === 200) {
          this.companys = res.data.resultbody
          for (let i = 0; i < this.companys.companyarea.split(',').length; i++) {
            const citem = this.companys.companyarea.split(',')[i]
            if (citem.length > 0) {
              this.companyareass.push(citem)
            }
          }
          this.companys.companycontent = this.companys.companycontent.replaceAll(/<br\/>/ig, '\r\n')
          if (this.companys.companymap.split('&').length === 7) {
            const center = this.companys.companymap.split('&')[3].replaceAll(/center=/ig, '')
            this.companys.companyarea = this.companys.companyarea.split(',')
            if (center.split(',').length === 2) {
              this.lng = center.split(',')[0]
              this.lat = center.split(',')[1]
              window.lng = this.lng
              window.lat = this.lat
              window.incaddress = this.companys.companyaddress
              window.inccontacts = this.companys.companycontacts
              window.inctitle = this.companys.companyname
              this.isrc2 = '/map2.html?t=' + new Date().getSeconds()
            }
          }
          this.isrc = '/map.html'
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      })
      setTimeout(() => { this.loadingmain = false }, 300)
    },
    sendcompanys  (formName) {
      this.loading = true
      this.companys.companyarea = this.companyareass.toString()
      if (this.companys.companycontent.length < 50) {
        this.$nextTick(() => {
          this.$refs.back.scrollIntoView({ behavior: 'smooth' })
        })
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          companysput(this.companys).then(res => {
            if (res.data.status === 200) {
              this.loading = false
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              this.getcompanys()
            } else {
              this.$message({
                message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
                type: 'error'
              })
              this.loading = false
            }
          }).catch(res => {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！',
              type: 'error'
            })
            this.loading = false
          })
        } else {
          this.loading = false
          return false
        }
      })
      // companysput
    },
    getcategory (t) {
      category(t, 0).then(res => {
        if (t === 9) {
          this.hylist = []
        } else if (t === 2) {
          this.dqlist = []
        } else if (t === 8) {
          this.gsxzlist = []
        } else if (t === 10) {
          this.gsrslist = []
        }

        for (let i = 0; i < res.data.resultbody.resultlist.length; i++) {
          const citem = { categoryid: res.data.resultbody.resultlist[i].categoryid, categoryname: res.data.resultbody.resultlist[i].categoryname, categoryroot: res.data.resultbody.resultlist[i].categoryroot }
          if (t === 9) {
            this.hylist.push(citem)
          } else if (t === 2) {
            this.dqlist.push(citem)
          } else if (t === 8) {
            this.gsxzlist.push(citem)
          } else if (t === 10) {
            this.gsrslist.push(citem)
          }
        }

        if (t === 2) {
          for (let i = 0; i < this.dqlist.length; i++) {
            const citem = this.dqlist[i]
            if (citem.categoryroot === 0) {
              const ccitem = { value: citem.categoryname, label: citem.categoryname, children: [] }
              for (let ii = 0; ii < this.dqlist.length; ii++) {
                const cccitem = this.dqlist[ii]
                if (cccitem.categoryroot === citem.categoryid) {
                  const ccccitem = { value: cccitem.categoryname, label: cccitem.categoryname, children: [] }
                  ccitem.children.push(ccccitem)
                }
              }
              this.dqlistinfo.push(ccitem)
            }
          }
        }
      })
    },
    getmaps () {
      alert('mapinfo')
    },
    getdepartments () {
      departments().then(res => {
        if (res.data.resultbody.resultlist != null) {
          this.departments = res.data.resultbody.resultlist
        }
      })
    },
    departmentedit (item) {
      this.departmenteditshow = 'edit'
      this.departmentinfos = item
    },
    departmentdel (item) {
      departmentsdel(item.departmentid).then(res => {
        if (res.data.status === 200) {
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.getdepartments()
          this.departmentinfos = {}
          this.departmenteditshow = 'list'
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！{resume}',
          type: 'error'
        })
      })
    },
    senddepartment (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.departmentinfos.departmentusersid = this.companys.companyusersid
          departmentsput(this.departmentinfos).then(res => {
            if (res.data.status === 200) {
              this.loading = false
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              this.getdepartments()
              this.departmentinfos = {}
              this.departmenteditshow = 'list'
            } else {
              this.$message({
                message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
                type: 'error'
              })
            }
          }).catch(res => {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！',
              type: 'error'
            })
          })
        } else {
          return false
        }
      })
    },
    getpic () {
      companypic(1, '').then(res => {
        if (res.data.status === 200) {
          this.piclist = res.data.resultbody
        } else {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    beforeuploadpic (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的图片！')
        return false
      } else if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        alert('请上传jpg或者png格式的图片！')
        return false
      }
      return true
    },
    onsuccesspic (uploadFilea, uploadFileb, uploadFilec) {
      this.getpic()
    },
    picdelete (item) {
      const ck = confirm('确定删除码?')
      if (ck) {
        companypic(3, item).then(res => {
          if (res.data.status === 200) {
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
            this.getpic()
          } else {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      }
    },
    setmaps (mapinfo) {
      this.companys.companymap = mapinfo
      this.drawer = 'none'
      this.companys.companyarea = this.companys.companyarea.toString()
      companysput(this.companys).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.getcompanys()
        } else {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.collectioncontainer {
  margin-top: 20px;
  border-radius: 0px;

  .el-select{
    display: block;
    width: 100%;
  }
}
.retaininfo {
    font-size: 12px;
    text-align: left;
    line-height: 22px;
    color: #888888;
    .el-link {
    font-size: 14px;
    color: #333333;
  }
}
.retaincompany {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
  color: #888888;
  .el-link {
    font-size: 14px;
    color: #333333;
  }
}
.retaincompanylogo {
  text-align: right;
  margin-top: 6px;
}
.retainlist
{
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.regtitle1{padding-left: 20px;}
.xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
}
.rightyx {
  margin-left: 12px;
  margin-right:12px;
  text-align: left;
  font-size: 14px;
  color: #777777;
  line-height: 28px;
  .el-row {
    border-bottom-width:1px;
    border-bottom-style: solid;
    border-bottom-color:  #dddfe5;
    padding-top: 2px;
    padding-bottom: 8px;
  }
  .el-tab-pane {
    padding-left: 20px;
    .el-tag { margin-right: 6px; color:#222222;}
  }
}
.addbtn{
  width:52px;
  height:52px;
  border-radius: 52px;
  text-align: center;
  line-height: 15px;
}
.exitembox{
  width:98%;
  padding:6px;
  border-color: #ececec;
  border-width: 1px;
  border-style: solid;
  border-radius:6px;
  margin-bottom: 8px;
  font-size: 14px;
.exitem{color: #888888; line-height: 26px; width:50%;float: left;
b{color: #333333;margin-left: 8px;font-weight: normal;}
 }
 .exitems{color: #888888; line-height: 26px; width:100%;
b{color: #333333;margin-left: 8px;font-weight: normal;}
 }
 .exitemedit{text-align: right;}
}
.image{height: 128px;}
</style>
