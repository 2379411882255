<template>
  <h2 class="regtitle1"></h2>
  <div class="readingcontainer">
    <el-row :gutter="30">
      <el-col :span="24" style="padding-left: 42px;">
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="简历搜索" name="first" class="resumelist">
             <div :style="stylebox" v-infinite-scroll="getlist" infinite-scroll-distance="20" class="listlistbox">
                <el-row gutter="12" v-for="item in resumelist" :key="item.resumeid" style="width:98%;margin-left: 1%;margin-top: 10px;">
                  <!-- <el-col :span="3" class="resumecompanylogo">
                    <span :class="item.resumegender==='男'?'resumegendera':'resumegenderb'">{{ item.resumegender }}</span>
                      <img :class="item.resumegender==='男'?'bordera':'borderb'" :src="baseurl+'static/resume/'+item.resumeusersid+'/photo/'+item.resumeusersid+'.jpg'" onerror="this.src='/images/avatar1.png'" />
                  </el-col>
                  <el-col :span="18" class="resumeinfo">
                  <div><el-link :underline="false" :href="'/resumeitem?id='+item.resumeusersid" target="_blank">{{item.resumename}}</el-link></div>
                  <div>
                    <span class="infovv">
                      <span>{{ getAge(item.resumebirthday) }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeworkage!=''">{{ item.resumeworkage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschool!=''">{{ item.resumeschool }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumespecialty!=''">{{item.resumespecialty}}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschoolage!=''">{{ item.resumeschoolage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumesalary!=''" style="color: crimson;">{{ item.resumesalary }}</span>
                    </span>
                    <br />||||{{ item.resumejobposition }}||||
                    <span v-if="item.resumeindustry!=''&&isJsonString(item.resumeindustry)">期望行业：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumeindustry)" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumejobposition!=''&&isJsonString(item.resumejobposition)">期望岗位：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobposition)" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><el-tag type="info" color="#ffffff" v-if="item.resumejobpositions!=''" >{{ item.resumejobpositions }}</el-tag><br /></span>
                    <span v-if="item.resumejobcity!=''&&isJsonString(item.resumejobcity)">期望地区：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobcity)" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumepersonaladvantages!=''">特长优势：
                      <span class="infovv" v-if="item.resumepersonaladvantages.length<80">{{ item.resumepersonaladvantages }}</span>
                      <span class="infovv" v-else>{{ item.resumepersonaladvantages.substring(0,80) }}...</span>
                    </span>
                  </div>
                  </el-col>
                  <el-col :span="3">
                    <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqedit(item)"><el-icon style="margin-right:6px;"><Position /></el-icon>邀请</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px;margin-top: 12px;" @click="scsend(item)"><el-icon style="margin-right:6px;"><Collection /></el-icon>收藏</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px; margin-top: 12px;" @click="chatopen(item.resumeusersid)"><el-icon style="margin-right:6px;"><ChatDotRound /></el-icon>沟通</el-button>
                  </el-col>
                  <el-divider /> -->
                  <el-col :span="2" class="resumecompanylogo">
                    <span :class="item.resumegender==='男'?'resumegendera':'resumegenderb'">{{ item.resumegender }}</span>
                      <img :class="item.resumegender==='男'?'bordera':'borderb'" :src="baseurl+'static/resume/'+item.resumeusersid+'/photo/'+item.resumeusersid+'.jpg'" onerror="this.src='/images/avatar1.png'" />
                  </el-col>
                  <el-col :span="19" class="resumeinfo">
                  <div><el-link :underline="false" :href="'/resumeitem?id='+item.resumeusersid" target="_blank">{{item.resumename}}</el-link>
                      <span style="margin-left: 20px;">{{ getAge(item.resumebirthday) }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeworkage!=''">{{ item.resumeworkage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschool!=''">{{ item.resumeschool }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumespecialty!=''">{{item.resumespecialty}}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschoolage!=''">{{ item.resumeschoolage }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumesalary!=''" style="color: crimson;">{{ item.resumesalary }}</span>
                      <span style="margin-left: 12px; margin-right: 12px;">{{ item.resumelogintime.replace(/T/g,' ').replace(/Z/g,' ').substring(0,10) }}</span>
                  </div>
                  <div>
                    <span class="infovv" style="display: none;">
                    </span>
                    <br />
                    <span v-if="item.resumeindustry.length>20&&isJsonString(item.resumeindustry)" style="display: none;">期望行业：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumeindustry.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumejobposition.length>20&&isJsonString(item.resumejobposition)">期望岗位：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobposition.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><el-tag type="info" color="#ffffff" v-if="item.resumejobpositions!=''" >{{ item.resumejobpositions }}</el-tag><br /></span>
                    <span v-if="item.resumejobcity.length>20&&isJsonString(item.resumejobcity)">期望地区：<el-tag type="info" color="#ffffff" v-for="itemz in JSON.parse(item.resumejobcity.replace(/\n/g, '').replace(/\r/g, ''))" :key="itemz.categoryid">{{ itemz.categoryname }}</el-tag><br /></span>
                    <span v-if="item.resumepersonaladvantages!=''" style="display: none;">特长优势：
                      <span class="infovv" v-if="item.resumepersonaladvantages.length<80">{{ item.resumepersonaladvantages }}</span>
                      <span class="infovv" v-else>{{ item.resumepersonaladvantages.substring(0,80) }}...</span>
                    </span>
                  </div>
                  </el-col>
                  <el-col :span="3">
                    <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqedit(item)"><el-icon style="margin-right:6px;"><Position /></el-icon>邀请</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px;margin-top: 12px;" @click="scsend(item)"><el-icon style="margin-right:6px;"><Collection /></el-icon>收藏</el-button><br />
                    <el-button  color="#333333" size="small" round style="font-size:12px; margin-top: 12px;" @click="chatopen(item.resumeusersid)"><el-icon style="margin-right:6px;"><ChatDotRound /></el-icon>沟通</el-button>
                  </el-col>
                  <el-divider />
                </el-row>
                <div v-if="loading==false" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><img src="/images/loading.gif" style="border-width: 0px;" /><br />稍等数据加载中</div>
                <div v-if="loading==true" style="padding:32px; font-size: 12px;color: #cccccc;text-align: center;"><el-icon><WarningFilled /></el-icon><br />没有了...</div>
              </div>
          </el-tab-pane>
          </el-tabs>
      </el-col>
    </el-row>
  </div>
  <!-- -------------------------------------- -->
<el-dialog v-model="yq_dialogVisible" title="邀请面试" width="418px">
      <el-row gutter="60">
        <el-col :span="24">
          <el-form-item label="" style="width: 100%;">
            <el-select  v-model="yq_job" placeholder="选择邀请面试岗位" style="width: 100%;">
                <el-option v-for="item in joblist" :key="item.jobid" :value="item.jobname">{{item.jobname}}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div style="text-align: right;padding-top: 18px;padding-bottom: 18px;">
            <el-button  color="#333333" size="small" round style="font-size:12px;" @click="yqsend()"><el-icon style="margin-right:6px;"><Position /></el-icon>发送邀请</el-button>
          </div>
        </el-col>
      </el-row>
  </el-dialog>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;">关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>
import { resumesearch, cjobmanage, inviteyq, collectyonsc } from '@/api/user'
import store from '@/store/index'
import timehelp from '@/utils/timehelp'
export default {
  name: 'searchresumeIndex',
  components: {},
  data () {
    return {
      drawer: 'none',
      yq_dialogVisible: false,
      yq_item: {},
      yq_job: '选择邀请面试岗位',
      loading: false,
      searchkey: '',
      baseurl: store.state.baseUrl,
      stylebox: 'width:100%;height: 200px;overflow: auto',
      activeName: 'first',
      total: 0,
      currentpage: 1,
      resumelist: [],
      joblist: [],
      stopp: 0
    }
  },
  computed: {
    disabled () {
      return this.loading
    }
  },
  created () {
    this.searchkey = this.$route.query.key
    const listheight = document.documentElement.clientHeight - 220
    this.stylebox = 'width:100%;height: ' + listheight + 'px;overflow: auto'
  },
  methods: {
    getlist () {
      // if (this.currentpage > this.stopp) {
      setTimeout(() => {
        if (this.loading === false) {
          resumesearch(this.currentpage, this.searchkey).then(res => {
            if (res.data.resultbody != null) {
              for (let i = 0; i < res.data.resultbody.length; i++) {
                const iteminfo = res.data.resultbody[i]
                // if (this.resumelist.indexOf(iteminfo) === 0) {
                this.resumelist.push(iteminfo)
                // }
              }
              if (res.data.resultbody.length < 30) {
                setTimeout(() => { this.loading = true }, 50)
                this.stopp = this.currentpage
              } else {
                setTimeout(() => {
                  this.loading = false
                  this.currentpage++
                }, 50)
              }
            } else {
              setTimeout(() => { this.loading = true }, 50)
              this.stopp = this.currentpage
            }
          })
        }
      }, 50)
      // }
    },
    getAge (vv) {
      return timehelp.getAge(vv)
    },
    setsearchkey (sv) {
      this.resumelist = []
      this.currentpage = 1
      this.stopp = 0
      this.searchkey = sv
      setTimeout(() => {
        this.loading = false
      }, 50)
      this.getlist()
    },
    yqedit (item) {
      this.yq_dialogVisible = true
      this.yq_item = item
      this.getjoblist()
    },
    getjoblist () {
      cjobmanage(0, 1, '', 50, 0).then(res => {
        if (res.data.resultbody != null) {
          if (this.currentpage === 1) {
            this.total = res.data.resultbody.total
          }
          this.joblist = res.data.resultbody
        }
      })
    },
    yqsend () {
      const resumeid = this.yq_item.resumeusersid
      let jobid = 0
      for (let i = 0; i < this.joblist.length; i++) {
        if (this.joblist[i].jobname === this.yq_job) {
          jobid = this.joblist[i].jobid
        }
      }
      if (resumeid > 0 && jobid > 0) {
        inviteyq(jobid, resumeid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
            this.yq_job = '选择邀请面试岗位'
            this.yq_dialogVisible = false
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '::>_<:: 请选择邀请面试的岗位！',
          type: 'error'
        })
      }
    },
    scsend (item) {
      collectyonsc(item.resumeusersid).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: ' + res.data.message + '',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    chatopen (id) {
      this.drawer = true
      this.iframeSrc = '/chats?id=' + id
    },
    isJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.resumegendera{position:absolute;top:2px;left:52px;width:16px;height: 16px;background-color: #003399; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.resumegenderb{position:absolute;top:2px;left:52px;width:16px;height: 16px;background-color: #FF0066; color: #ffffff; border-radius: 10px; font-size: 10px; padding:2px; text-align: center;line-height: 16px;}
.bordera{border-width: 2px;border-color: #003399; border-style: solid;width:48px;height:48px;border-radius: 48px;}
.borderb{border-width: 2px;border-color: #FF0066; border-style: solid;width:48px;height:48px;border-radius: 48px;}
.titlea{
  text-align: left;padding-left: 12px;font-size: 16px;margin-top: 2px; margin-bottom: 12px;
}

.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
}
.bg-purple {
background-color: #ffffff;
}
.bg-purple-light {
  background-color: bisque;
}
.count_a {
  text-align: center;
  line-height: 22px;
  .el-card{
  color: #909399;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px;
  border-radius: 0px;
  margin-bottom: 6px;
  margin-right: 5px;
  .countstyle {
    font-size: 16px;
    color: #000000;
  }
  }

  .infostyle{
    font-size: 14px;
    color:#909399;
  }
  .infostylevv{font-size: 14px; color:#333333;}
}
.rightcontainer {
  border-left-width:1px;
  border-left-style: solid;
  border-radius: 0px;
  border-left-color:  #dddfe5;
}
.resumeinfo {
    font-size: 14px;
    text-align: left;
    line-height: 26px;
    color: #888888;
    .el-link {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-tag{margin-right: 8px;color: #333333; margin-bottom: 8px;}
  .infovv{color: #333333;}
}
.resumecompany {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
  color: #888888;
  .el-link {
    font-size: 14px;
    color: #333333;
  }
}
.resumecompanylogo {
  text-align: left;
  margin-top: 6px;
}
.resumelist
{
  .el-row{ margin-bottom: 8px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
}
.xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
}
.rightyx {
  margin-left: 12px;
  margin-right:12px;
  text-align: left;
  font-size: 14px;
  color: #777777;
  line-height: 28px;
  .el-row {
    border-bottom-width:1px;
    border-bottom-style: solid;
    border-bottom-color:  #dddfe5;
    padding-top: 2px;
    padding-bottom: 8px;
  }
  .el-tab-pane {
    padding-left: 20px;
    .el-tag { margin-right: 6px; color:#222222;}
  }
}
</style>
