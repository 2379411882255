<template>
<!--hendermenu---->
    <div class="kmzp_header">
        <el-row :gutter="20" class="menubox">
            <el-col :span="5"><img src="/images/logob.png" style="border: 0px; height: 42px; margin-top: 8px;" /></el-col>
            <el-col :span="10" class="hmenu">
                <a href="/">首页</a>
                <a href="/jobs">找工作</a>
                <a href="/resumessearch">找人才</a>
                <a href="/senior">高级岗位</a>
                <a style="cursor: pointer;" @click="messagedrawer=true">留言/建议</a>
            </el-col>
            <el-col :span="2" class="hmenur">
            <el-tooltip effect="customized" content="在线沟通" placement="left" size="small" :visible="true">
              <el-link href="/chats" target="_blank" :underline="false" style="border-width: 0px;">
              <el-badge :value="chatscount+'条新留言未读'" v-if="chatscount==0?hidden=false:hidden=true" class="flash" style="margin-top: 12px;">
               <img src="/images/goutongb.png" style="width:32px;height:32px;" />
              </el-badge>
              <img src="/images/goutonga.png" v-if="chatscount==0" style="width:32px;height:32px;" />
              </el-link>
            </el-tooltip>
            </el-col>
            <el-col :span="7" class="hmenur">
                <el-badge value="招聘" class="badgeitem" v-if="users.usersid==0">
                <a href="/reg?rt=company">我是企业</a>
                </el-badge>
                <el-badge value="求职" class="badgeitem"  v-if="users.usersid==0">
                <a href="/reg">我要求职者</a>
                </el-badge>
                <!-- --------------------------------------------------------- -->
            <el-dropdown :hide-on-click="false" v-if="users.usersid>0">
            <el-button size="default" color="#ffffff" style="padding-left: 8px;padding-right: 8px;margin-left:22px;margin-top: 13px;border-radius: 18px;">
            <span class="avatarcontainer">
              <img class="avatarimg" style="width:26px;height:26px;border-radius: 26px;" v-if="users.userstype==1" :src="baseurl+'static/resume/'+users.usersid+'/photo/'+users.usersid+'.jpg?t=' + photoinfo" onerror="this.src='/images/avatar1.png'" />
              <img class="avatarimg" style="width:26px;height:26px;border-radius: 26px;" v-if="users.userstype==2" :src="baseurl + 'static/incpic/'+users.usersid+'/logo/'+users.usersid+'.jpg?t=' + photoinfo" onerror="this.src='/images/avatar2.png'" />
              <b class="avatartitle">{{users.usersname}}</b>
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="gouser"><el-icon><HomeFilled /></el-icon>管理中心</el-dropdown-item>
                <el-dropdown-item @click="passwordset"><el-icon><Key /></el-icon>密码修改</el-dropdown-item>
                <el-dropdown-item @click="loginout"><el-icon><SwitchButton /></el-icon>退出登陆</el-dropdown-item>
              </el-dropdown-menu>
            </template>
            </el-dropdown>
            </el-col>
        </el-row>
    </div>
<!-- 投诉、留言--------------------------------------------------------- -->
<el-drawer
    v-model="messagedrawer"
    title="投诉、留言、建议"
  >
  <el-form label-width="82px" :rules="messagerules" ref="messages" :model="messages" >
    <el-form-item label="">
        <el-radio-group v-model="messages.messagetype">
          <el-radio-button label="1" style="border: none;">留言</el-radio-button>
          <el-radio-button label="2" style="border: none;">投诉</el-radio-button>
        </el-radio-group>
    </el-form-item>
    <el-form-item label="名称" prop="messagename">
      <el-input v-model="messages.messagename" placeholder="请输入您的称呼" />
    </el-form-item>
    <el-form-item label="电话" prop="messagephone">
      <el-input v-model="messages.messagephone" placeholder="请输入电话" />
    </el-form-item>
    <el-form-item label="留言" prop="messagecontent">
      <el-input show-word-limit maxlength="200" autosize type="textarea" placeholder="请描述一下您的问题" v-model="messages.messagecontent" />
    </el-form-item>
    <el-form-item label="">
        <el-button color="#e00303" @click="sendmessage('messages')" :loading="loading">提交保存</el-button>
    </el-form-item>
  </el-form>
</el-drawer>

<!-- 密码修改 ------------------------------------- -->
<el-dialog v-model="dialogpasswordVisible" title="密码修改" width="418px" v-if="users.usersid>0">
    <el-form style="padding-right: 20px;">
      <el-form-item label="新密码" label-width="108px">
        <el-input v-model="newpasswordinfo" type="text" show-password/>
      </el-form-item>
      <el-form-item label="确认新密码" label-width="108px">
        <el-input v-model="newpasswordinfo2" type="password" show-password/>
      </el-form-item>
      <el-form-item label="" label-width="108px">
        <el-button color="#333333" @click="passwordsetsed">提交修改</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>

import { messagesput, getuserprofile, passwordset } from '@/api/user'
import { chatscount } from '@/api/chat'
import token from '@/utils/token'
import store from '@/store/index'
export default {
  name: 'homehenderMenu',
  components: {},
  data () {
    return {
      dialogpasswordVisible: false,
      baseurl: store.state.baseUrl,
      loading: false,
      messagedrawer: false,
      messages: { messagesid: 0, messagetype: 1 },
      users: { usersid: 0 },
      photoinfo: new Date().getSeconds(),
      chatscount: 0,
      timer: null,
      newpasswordinfo: '',
      newpasswordinfo2: '',
      messagerules: {
        messagename: [
          { required: true, message: '请输入您的称呼', trigger: 'blur' }
        ],
        messagephone: [
          { required: true, message: '请输入电话', trigger: 'blur' }
        ],
        messagecontent: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getusers()
  },
  computed: {},
  watch: {},
  methods: {
    sendmessage (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.messages.messagetype = Number(this.messages.messagetype)
          messagesput(this.messages).then(res => {
            if (res.data.status === 200) {
              this.loading = false
              this.messagedrawer = false
              this.messages = { messagetype: 1, messagename: '', messagephone: '', messagecontent: '' }
              alert('感谢您的支持我们将第一时间处理您的信息！')
            } else {
              alert('操作异常请稍后重试！')
            }
          })
        }
      })
    },
    getusers () {
      getuserprofile().then(res => {
        if (res.data.status === 200) {
          if (res.data.resultbody != null) {
            this.users = res.data.resultbody
            if (this.users.usersstatus > 0) {
              alert('您好！帐号锁定了！')
              token.settoken('utoken', '')
              top.location.href = '/login'
              //   this.$router.push('/login')
            }
            if (this.users.usersid > 0) {
              this.timer = setInterval(() => {
                this.getchatscount(this.users.usersid)
              }, 3000)
            }
          }
        }
      })
    },
    loginout () {
      token.settoken('utoken', '')
      top.location.href = '/login'
      //  this.$router.push('/login')
    },
    passwordset () {
      if (this.users.usersid > 0) {
        this.dialogpasswordVisible = true
      }
    },
    passwordsetsed () {
      if (this.users.usersid > 0) {
        if (this.newpasswordinfo.length > 5) {
          if (this.newpasswordinfo === this.newpasswordinfo2) {
            passwordset(this.newpasswordinfo).then(res => {
              if (res.data.status === 200) {
                this.dialogpasswordVisible = false
                this.loginout()
                alert('密码修改成功！请重新登陆！')
              } else {
                alert(res.data.message)
              }
            })
          } else {
            alert('两次输入密码不一致！')
          }
        } else {
          alert('请输入不少于6位的密码！')
        }
      }
    },
    gouser () {
      if (this.users.userstype === 1) {
        top.location.href = '/resumes/home'
      } else {
        top.location.href = '/companys/home'
      }
    },
    getchatscount (id) {
      chatscount(id).then(res => {
        if (res.data.resultbody != null) {
          this.chatscount = res.data.resultbody
        }
      })
    }
  }
}
</script>
<style scoped>
::v-deep .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
 -webkit-box-shadow: none !important;
 box-shadow: none !important;
}
/*闪烁动画*/
@keyframes twinkle {
    from {
      opacity: 1.0;
    }
    50% {
      opacity: 0.0;
    }
    to {
      opacity: 1.0;
    }
  }
  .flash /deep/ .el-badge__content {
    animation: twinkle 1s;
    animation-iteration-count: infinite;
  }
</style>
<style>
.el-popper.is-customized {
  padding: 0px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 10px;
  background: linear-gradient(90deg, rgb(248, 248, 248), rgb(204, 204, 204));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #cccccc, #f8f8f8);
  right: 0;
}
</style>
