<template>
  <el-header class="hender_a" >
        <div class="henderbox"><img src="/images/logoa.png" style="height:45px; margin-left: 20px;" /></div>
  </el-header>
  <!-- ----------------------------------------- -->
    <div class="common-layout">
    <div class="regcontain">
      <h2 class="regtitle">个人求职注册</h2>
      <div class="regbox">
        <el-form label-width="82px" size="default" label-position="right" class="forminfo" ref="resume"  :model="resume" :rules="rules" >
          <!-- ----------------------------------------- -->
          <el-row :gutter="60">
            <el-col :span="12">
            <!-- ----------------------------------------- -->
            <el-row :gutter="20">
            <el-col :span="24"><h3>基本信息</h3></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="姓名" prop="resumename">
                  <el-input type="text" v-model="resume.resumename "/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别">
                <el-radio-group v-model="resume.resumegender">
                  <el-radio label="男" />
                  <el-radio label="女" />
                </el-radio-group>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="生日" prop="resumebirthday">
                <el-config-provider :locale="zhCn">
                 <el-date-picker v-model="resume.resumebirthday" type="date" placeholder="选择生日" value-format="YYYY-MM-DD" :editable="false"/>
                </el-config-provider>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="参工时间" prop="resumeworktime">
                  <el-config-provider :locale="zhCn">
                <el-date-picker v-model="resume.resumeworktime " type="date" placeholder="选择参工时间" value-format="YYYY-MM-DD" :editable="false"/>
              </el-config-provider>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="求职状态" prop="resumejobstatus">
                <el-select v-model="resume.resumejobstatus" placeholder="选择状态">
                  <el-option :value="1" label="离职 - 随时到岗"/>
                  <el-option :value="0" label="在职 - 投递企业可查看"/>
                  <el-option :value="-1" label="屏蔽 - 隐藏求职信息"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份" prop="resumeidentity">
                <el-select v-model="resume.resumeidentity" placeholder="选择身份">
                  <el-option value="职场大牛" label="职场大牛"/>
                  <el-option value="在校学生" label="在校学生"/>
                </el-select>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="个人优势" prop="resumepersonaladvantages">
                  <el-input type="textarea" v-model="resume.resumepersonaladvantages" :rows="3" />
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="毕业学校" prop="resumeschool">
                  <el-input type="text" v-model="resume.resumeschool" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                          <el-form-item label="专业" prop="resumespecialty">
                              <el-input type="text" v-model="resume.resumespecialty" />
                            </el-form-item>
                        </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学历" prop="resumeschoolage">
                <el-select v-model="resume.resumeschoolage" placeholder="选择学历">
                  <span v-for="item in xllist" :key="item.categoryid">
                    <el-option v-if="item.categoryroot==0" :value="item.categoryname"><b style="padding-left: 10px;">{{ item.categoryname }}</b></el-option>
                    <el-option v-else :value="item.categoryname" style="padding-left: 20px;"><en style="padding-left: 20px;">{{ item.categoryname }}</en></el-option>
                  </span>
                </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作经验" prop="resumeworkage">
                <el-select  v-model="resume.resumeworkage" placeholder="选择工作经验">
                  <span v-for="item in jylist" :key="item.categoryid">
                    <el-option v-if="item.categoryroot==0" :value="item.categoryname"><b style="padding-left: 10px;">{{ item.categoryname }}</b></el-option>
                    <el-option v-else :value="item.categoryname" style="padding-left: 20px;"><en style="padding-left: 20px;">{{ item.categoryname }}</en></el-option>
                  </span>
                </el-select>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="邮箱" prop="resumemail">
                  <el-input type="email" v-model="resume.resumemail"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="密码" prop="resumpassword">
                  <el-input type="text" v-model="resume.resumpassword" />
                </el-form-item>
            </el-col>
          </el-row>
            <!-- ----------------------------------------- -->
            </el-col>
            <el-col :span="12">
            <!-- ----------------------------------------- -->
            <el-row :gutter="20">
            <el-col :span="24"><h3>求职意向</h3></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="求职类型" prop="resumejobtype">
                <el-select v-model="resume.resumejobtype" placeholder="选择求职类型">
                  <el-option value="全职" label="全职"/>
                  <el-option value="兼职" label="兼职"/>
                  <el-option value="实习" label="实习"/>
                </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="待遇" prop="resumesalary">
                <el-select  v-model="resume.resumesalary" placeholder="选择待遇">
                  <span v-for="item in xzlist" :key="item.categoryid">
                    <el-option v-if="item.categoryroot==0" :value="item.categoryname"><b style="padding-left: 10px;">{{ item.categoryname }}</b></el-option>
                    <el-option v-else :value="item.categoryname" style="padding-left: 20px;"><en style="padding-left: 20px;">{{ item.categoryname }}</en></el-option>
                  </span>
                </el-select>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="期望行业" prop="resumeindustry">
              <el-button color="#333333" size="small" style="margin-top:10px;" @click="categoryinit(1)">选择</el-button>
              <div class="yxbox">
              <el-tag type="info" round v-for="citem in hylistvvv" :key="citem.categoryid">{{ citem.categoryname }} </el-tag>
              </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="期望职位" prop="resumejobposition">
                <el-button color="#333333" size="small" style="margin-top:10px;" @click="categoryinit(2)">选择</el-button>
              <div class="yxbox">
                <el-tag type="info" round v-for="citem in zwlistvvv" :key="citem.categoryid">{{ citem.categoryname }} </el-tag>
               </div>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="工作地区" prop="resumejobcity">
                <el-button color="#333333" size="small" style="margin-top:10px;" @click="categoryinit(3)">选择</el-button>
              <div class="yxbox">
                <el-tag type="info" round v-for="citem in dqlistvvv" :key="citem.categoryid">{{ citem.categoryname }} </el-tag>
              </div>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="自定岗位" prop="resumejobpositions">
                  <el-input type="text" v-model="resume.resumejobpositions" />
                  <div style="color: #888888;">如：会计，销售，设计，驾驶，文员，助理，行政，出纳，工程，营销，客服</div>
                </el-form-item>
            </el-col>
          </el-row>
            <!-- ----------------------------------------- -->
            </el-col>
          </el-row>
          <!-- ----------------------------------------- -->
          <el-form-item>
            <div style="width:100%; text-align: right; ">
            <el-button color="#dc3023" style="width:120px;font-size: 18px;height:52px;line-height: 52px;" @click="sendresume('resume')" :loading="loading">保存</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    </div>
<!-- 行业 ----------------------------------------- -->
  <el-dialog v-model="dialogVisiblehy" title="请选择行业" width="918px" :before-close="handleClose">
    <div class="categoryname">
        <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22"><el-tag @click="categoryclear(1,citem)" type="info"  round  v-for="citem in hylistvvv" :key="citem.categoryid">{{ citem.categoryname }} <span class="cleartext">x</span></el-tag></el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisiblehy = false" round>确定</el-button></el-col>
        </el-row>
    </div>
    <div class="categorylist" style="width: 100%;">
      <el-scrollbar height="400px" style="width: 100%;">
        <el-row :gutter="20" style="width:100%;">
          <el-col @click="categoryset(1,citem)" :span="8" v-for="citem in hylist" :key="citem.categoryid" class='itemboxb'>
            {{ citem.categoryname }}
          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </el-dialog>
<!-- 职位 ----------------------------------------- -->
  <el-dialog v-model="dialogVisiblezw" title="选择职位"  width="918px" :before-close="handleClose">
    <div class="categoryname">
        <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22">
            <el-tag @click="categoryclear(2,citem)"  type="info" round  v-for="citem in zwlistvvv" :key="citem.categoryid">{{ citem.categoryname }} <span class="cleartext">x</span></el-tag>
          </el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisiblezw = false" round>确定</el-button></el-col>
        </el-row>
    </div>

    <div class="categorylist" style="width: 100%;">
      <el-scrollbar height="400px" style="width: 100%;">
        <el-row :gutter="20" style="width:98%;margin-left: 1%;">
          <el-col :span="6" class="rootiteminfoa"  v-for="(citemroot,index) in zwlistroot" :key="citemroot.categoryid" :class="index%2===0?'itemboxaa':'itemboxbb'">

  <el-popover placement="bottom-start" title="" :width="718" trigger="hover">
    <template #reference>
      {{ citemroot.categoryname }}
    </template>
    <el-col :span="24" style="overflow-y: visible; overflow-x: hidden;max-height:298px;">
      <div class="zcitem3" @click="categoryset(2,citem)" v-for="citem in getzlist(2,citemroot.categoryid)" :key="citem.categoryid"><span>{{ citem.categoryname }}</span></div>
    </el-col>
  </el-popover>

          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
    <!-- <div class="categoryname">
        <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22">
            <el-tag @click="categoryclear(2,citem)"  type="info" round  v-for="citem in zwlistvvv" :key="citem.categoryid">{{ citem.categoryname }} <span class="cleartext">x</span></el-tag>
          </el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisiblezw = false" round>确定</el-button></el-col>
        </el-row>
    </div>
    <div class="categorylist" style="width: 100%;">
      <el-scrollbar height="400px" style="width: 100%;">
        <el-row :gutter="20" v-for="(citemroot,index) in zwlistroot" :key="citemroot.categoryid" :class="index%2===0?'itemboxa':'itemboxb'">
          <el-col :span="24" class="rootiteminfo">
           {{ citemroot.categoryname }}
          </el-col>
          <el-col :span="24" class="iteminfo">
            <div class="zcitem" @click="categoryset(2,citem)" v-for="citem in getzlist(2,citemroot.categoryid)" :key="citem.categoryid"><span>{{ citem.categoryname }}</span></div>
          </el-col>
        </el-row>
      </el-scrollbar>
    </div> -->
  </el-dialog>
<!-- 地区 ----------------------------------------- -->
  <el-dialog v-model="dialogVisibledq" title="选择地区"  width="918px" :before-close="handleClose">
    <div class="categoryname">
      <el-row :gutter="20" style="width:100%;height:58px;">
          <el-col :span="22">
           <el-tag @click="categoryclear(3,citem)"  type="info" round  v-for="citem in dqlistvvv" :key="citem.categoryid">{{ citem.categoryname }} <span class="cleartext">x</span></el-tag>
          </el-col>
          <el-col :span="2"><el-button color="#CC3333" @click="dialogVisibledq = false" round>确定</el-button></el-col>
      </el-row>
      </div>
      <div class="categorylist">
      <el-scrollbar height="400px">
        <el-row :gutter="20" v-for="(citemroot,index) in dqlistroot" :key="citemroot.categoryid" :class="index%2===0?'itemboxa':'itemboxb'">
          <el-col :span="24" class="rootiteminfo">
            {{ citemroot.categoryname }}
          </el-col>
          <el-col :span="24" class="iteminfo">
            <div class="zcitem2" @click="categoryset(3,citem)" v-for="citem in getzlist(3,citemroot.categoryid)" :key="citem.categoryid"><span>{{ citem.categoryname }}</span></div>
          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </el-dialog>
<!-- 职位 ----------------------------------------- -->

</template>

<script>
import { category, rresumeput, rgetresume, getuserprofile } from '@/api/user'
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import token from '@/utils/token'

export default {
  name: 'resumeIndex',
  components: { [ElConfigProvider.name]: ElConfigProvider },
  props: {},
  data () {
    return {
      zhCn,
      xllist: [], // 学历 77
      jylist: [], // 工作经验 55
      xzlist: [], // 薪资 33
      hylist: [],
      hylistvvv: [],
      zwlist: [],
      zwlistroot: [],
      zwlistvvv: [],
      dqlist: [],
      dqlistroot: [],
      dqlistvvv: [],
      dialogVisiblehy: false,
      dialogVisiblezw: false,
      dialogVisibledq: false,
      loading: false,
      resume: {
        resumeid: 0,
        resumegender: '男',
        resumejobstatus: 1,
        resumebirthday: '2020-08-18'
      },
      resumpassword: '',
      rules: {
        usersphone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的11位手机号码', trigger: 'blur' }
        ],
        resumename: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        resumebirthday: [
          { required: true, message: '请选择生日', trigger: 'blur' }
        ],
        resumeworktime: [
          { required: true, message: '请选择参工时间', trigger: 'blur' }
        ],
        resumejobstatus: [
          { required: true, message: '请选择求职状态', trigger: 'blur' }
        ],
        resumeidentity: [
          { required: true, message: '请选择身份', trigger: 'blur' }
        ],
        resumepersonaladvantages: [
          { required: true, message: '请填写优势', trigger: 'blur' }
        ],
        resumeschool: [
          { required: true, message: '请填写毕业学校', trigger: 'blur' }
        ],
        resumeschoolage: [
          { required: true, message: '请选择学历', trigger: 'blur' }
        ],
        resumespecialty: [
          { required: true, message: '专业必须选择', trigger: 'blur' }
        ],
        resumeworkage: [
          { required: true, message: '请选择工作经验', trigger: 'blur' }
        ],
        resumejobtype: [
          { required: true, message: '请选择求职类型', trigger: 'blur' }
        ],
        resumesalary: [
          { required: true, message: '请选择待遇', trigger: 'blur' }
        ],
        resumejobpositions: [
          { required: true, message: '请填写自定岗位', trigger: 'blur' }
        ],
        resumeindustry: [
          {
            required: true, message: '请选择行业', trigger: 'blur'
          }
        ],
        resumejobposition: [
          { required: true, message: '请选择意向职位', trigger: 'blur' }
        ],
        resumejobcity: [
          { required: true, message: '请选择意向地区', trigger: 'blur' }
        ],
        resumemail: [
          { type: 'string', required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        resumpassword: [
          { required: true, message: '请填写密码', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getusers()
    this.getresumes()
    this.categoryinit(33)// 薪资
    this.categoryinit(55)// 工作经验
    this.categoryinit(77)// 学历
  },
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.status === 200) {
          if (res.data.resultbody != null) {
            this.users = res.data.resultbody
          } else {
            token.settoken('utoken', '')
            this.$router.push('/login')
          }
        } else {
          alert(res.data.message)
          token.settoken('utoken', '')
          this.$router.push('/login')
        }
      })
    },
    getcategory (t) {
      let ct = t
      if (t === 1) {
        ct = 9
      } else if (t === 2) {
        ct = 1
      } else if (t === 3) {
        ct = 2
      } else if (t === 33) {
        ct = 3
      } else if (t === 55) {
        ct = 5
      } else if (t === 77) {
        ct = 7
      }

      category(ct, 0).then(res => {
        if (t === 1) {
          this.hylist = []
        } else if (t === 2) {
          this.zwlist = []
        } else if (t === 3) {
          this.dqlist = []
        } else if (t === 33) {
          this.xzlist = []
        } else if (t === 55) {
          this.jylist = []
        } else if (t === 77) {
          this.xllist = []
        }

        for (let i = 0; i < res.data.resultbody.resultlist.length; i++) {
          const citem = { categoryid: res.data.resultbody.resultlist[i].categoryid, categoryname: res.data.resultbody.resultlist[i].categoryname, categoryroot: res.data.resultbody.resultlist[i].categoryroot }
          if (t === 1) {
            this.hylist.push(citem)
          } else if (t === 2) {
            this.zwlist.push(citem)
          } else if (t === 3) {
            this.dqlist.push(citem)
          } else if (t === 33) {
            this.xzlist.push(citem)
          } else if (t === 55) {
            this.jylist.push(citem)
          } else if (t === 77) {
            this.xllist.push(citem)
          }
          // -----------------------
          // if (t === 2 || t === 3) {
          //   category(ct, res.data.resultbody.resultlist[i].categoryid).then(ress => {
          //     for (let ii = 0; ii < ress.data.resultbody.resultlist.length; ii++) {
          //       const citemz = { categoryid: ress.data.resultbody.resultlist[ii].categoryid, categoryname: ress.data.resultbody.resultlist[ii].categoryname, categoryroot: ress.data.resultbody.resultlist[ii].categoryroot }
          //       if (t === 1) {
          //         this.hylist.push(citemz)
          //       } else if (t === 2) {
          //         this.zwlist.push(citemz)
          //       } else if (t === 3) {
          //         this.dqlist.push(citemz)
          //       }
          //     }
          //   })
          // }
          // ------------------------
        }
        this.getrootlit(t, 0)
      })
    },
    categoryset (t, v) {
      if (t === 1) {
        if (this.hylistvvv.length > 4) {
          alert('最多只能选择五项')
        } else {
          if (this.hylistvvv.indexOf(v) < 0) {
            this.hylistvvv.push(v)
          }
        }
        this.resume.resumeindustry = JSON.stringify(this.hylistvvv)
      } if (t === 2) {
        if (this.zwlistvvv.length > 4) {
          alert('最多只能选择五项')
        } else {
          if (this.zwlistvvv.indexOf(v) < 0) {
            this.zwlistvvv.push(v)
          }
        }
        this.resume.resumejobposition = JSON.stringify(this.zwlistvvv)
      } if (t === 3) {
        if (this.dqlistvvv.length > 4) {
          alert('最多只能选择五项')
        } else {
          if (this.dqlistvvv.indexOf(v) < 0) {
            this.dqlistvvv.push(v)
          }
        }
        this.resume.resumejobcity = JSON.stringify(this.dqlistvvv)
      }
    },
    categoryclear (t, v) {
      if (t === 1) {
        const newlistinfo = []
        for (let i = 0; i < this.hylistvvv.length; i++) {
          if (this.hylistvvv[i] !== v) {
            newlistinfo.push(this.hylistvvv[i])
          }
        }
        this.hylistvvv = newlistinfo
      } else if (t === 2) {
        const newlistinfo = []
        for (let i = 0; i < this.zwlistvvv.length; i++) {
          if (this.zwlistvvv[i] !== v) {
            newlistinfo.push(this.zwlistvvv[i])
          }
        }
        this.zwlistvvv = newlistinfo
      } else if (t === 3) {
        const newlistinfo = []
        for (let i = 0; i < this.dqlistvvv.length; i++) {
          if (this.dqlistvvv[i] !== v) {
            newlistinfo.push(this.dqlistvvv[i])
          }
        }
        this.dqlistvvv = newlistinfo
      }
    },
    categoryinit (t) {
      this.getcategory(t)
      if (t === 1) {
        this.dialogVisiblehy = true
      } else if (t === 2) {
        this.dialogVisiblezw = true
      } else if (t === 3) {
        this.dialogVisibledq = true
      }
    },
    getrootlit (t, rootitemid) {
      if (t === 2) {
        this.zwlistroot = []
        const arr = this.zwlist.filter(item => { return item.categoryroot === rootitemid })
        this.zwlistroot = arr
      } else if (t === 3) {
        this.dqlistroot = []
        const arr = this.dqlist.filter(item => { return item.categoryroot === rootitemid })
        this.dqlistroot = arr
      }
    },
    getzlist (t, rootitemid) {
      if (t === 2) {
        let zwlistnew = []
        zwlistnew = this.zwlist.filter(item => { return item.categoryroot === rootitemid })
        return zwlistnew
      } else {
        let dqlistnew = []
        dqlistnew = this.dqlist.filter(item => { return item.categoryroot === rootitemid })
        return dqlistnew
      }
    },
    sendresume (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.resume.resumejobstatus = parseInt(this.resume.resumejobstatus)
          this.resume.resumeusersname = this.users.usersname
          // alert(JSON.stringify(this.resume))
          rresumeput(this.resume).then(res => {
            if (res.data.status === 200) {
              this.loading = false
              this.$router.push('/')
            } else {
              this.$message({
                message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
                type: 'error'
              })
            }
          }).catch(res => {
            this.$message({
              message: '::>_<:: 出错了！联系一下管客服吧！',
              type: 'error'
            })
          })
        } else {
          return false
        }
      })
    },
    getresumes () {
      rgetresume().then(res => {
        // alert(JSON.stringify(res.data))
        if (res.data.status === 200) {
          this.resume = res.data.resultbody
        }
        if (this.resume.resumeid > 0) {
          this.$router.push('/')
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    }
  }
}
</script>
  <style scoped lang="less">
  .hender_a{
    height:60px;
    display: flex;
    justify-content: center;
    align-items: top;
    width:100%;
    background-color: #ffffff;
    margin-top: 0px;
  .henderbox{border-radius: 12px;background-color: #ffffff;height:46px;line-height: 46px;margin-top: 8px;width:1288px;max-width: 1288px; text-align: left;align-items: left;}
  }
  .common-layout
  {
   // position: fixed;
    left: 0;
    top: 68px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    min-width: 1180px;
    padding-top: 60px;
    padding-bottom: 120px;
  .regcontain{
    background-color: #ffffff;
    width: 1188px;
    border-radius: 28px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 20px;
  }
  .regcontainc{
    background-color: #ffffff;
    width: 718px;
    border-radius: 28px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-right: 20px;
  }
  .regtitle{text-align: left; font-size: 25px;padding-left: 30px; margin-bottom: 2px;}
  .regtitle1{text-align: center;line-height: 42px;}
  .regbox{
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 12px;
    padding-bottom: 2px;
    margin-top: 2px;
  }
  .regbox1{text-align: center; padding-bottom: 20px;
  a{color: #333333;}
  }
  .regbox2{text-align: center;font-size: 12px;line-height: 22px; color: #666666;
  a{text-decoration:none;}
  }
  }
  .yxbox{width:100%;padding-top:6px; padding-bottom: 3px;}
  .forminfo {
    .el-tag { margin-right: 6px; margin-top: 3px; margin-bottom: 3px; color:#222222;height:25px;cursor: pointer;}
  }
  .categoryname{
    border-top-color: #ececec;border-top-width: 1px;border-top-style: solid;line-height: 32px;
    border-bottom-color: #ececec;border-bottom-width: 1px;border-bottom-style: solid;
    margin-top: -30px; padding-top: 12px;padding-bottom: 16px;
    .el-tag { margin-right: 6px; margin-top: 3px; margin-bottom: 3px; color:#CC3333;height:25px;cursor: pointer; border-width:1px; border-color: #cc3333;background-color: #ffffff;}
  }
  .categorylist{font-size: 14px;line-height: 32px; padding-top: 12px; max-height: 418px;cursor: pointer;
    width:100%;
  }
  .rcitem{background-color: #666666;}
  .zcitem{width:32%; margin-left: 1%; float: left;}
  .zcitem2{width:9%; margin-left: 1%; float: left;}

  .zcitem3{
  cursor:pointer;width:21%; margin-left: 1%; float: left; border: 1px #f0f0f0 solid;border-radius: 8px;margin-bottom: 8px; font-size: 12px;padding:6px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
  .zcitemroot{line-height: 42px;}

  .cleartext{font-size: 16px;}

  .rootiteminfo{
    text-align: left;
    font-weight: bold;
    font-size: 16px;
  }

  .rootiteminfoa{
  font-size: 14px;
  text-align: center;
  font-size: 14px;
}
  .iteminfo{
    justify-content: center;
    align-items: center;
  }
 .itemboxa {padding-left: 8px;background-color: #f8f8f8;width:100%;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 12px;padding-top: 12px;}
 .itemboxb {padding-left: 8px;width:100%;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 12px;padding-top: 12px;}
 .itemboxaa {padding-left: 8px;background-color: #f8f8f8;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 6px;padding-top: 6px;}
.itemboxbb {padding-left: 8px;margin-top: 0px;border-bottom-width: 1px;border-bottom-color: #f0f0f0;border-bottom-style: solid;padding-bottom: 6px;padding-top: 6px;}

 </style>
