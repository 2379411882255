<template>

<!--hendebanner---->
<div class="kmzp_headerbanner2" style="background-color: #ffffff;background-image:url(/images/banner_bg3.jpg);background-position:center bottom;">
    <el-row :gutter="20" class="bannerbox" style="margin-top: 22px;">
      <el-col :span="2"></el-col>
        <el-col :span="20">
            <!--------------------------->
            <el-row :gutter="20">
                <el-col :span="4"></el-col>
                <el-col :span="16">
                  <div style="margin-top:2px;margin-bottom: 2px;border-radius: 12px;background:rgba(255,255,255,0.6);padding: 12px;">{{ ctinfo }}
                      <el-row :gutter="1" style="width: 88%;margin-left: 4%;">
                     <el-col :span="22">
                        <el-input placeholder="搜索关键词"  v-model="searchkey" size="large" style="border: #e00303 0px solid;border-radius: 8px;" />
                      </el-col>
                      <el-col :span="2"><el-button color="#e00303" size="large" @click="searchsend" style="background-color: #e00303; color: #ffffff;height: 42px;">搜索</el-button></el-col>
                      </el-row>
                      </div>
                </el-col>
            </el-row>
            <!--------------------------->
            <el-row :gutter="2">
              <el-col :span="24" class="searchkey">
                    <a href="/jobssearch?key=会计">会计</a>
                    <a href="/jobssearch?key=销售">销售</a>
                    <a href="/jobssearch?key=设计">设计</a>
                    <a href="/jobssearch?key=驾驶">驾驶</a>
                    <a href="/jobssearch?key=文员">文员</a>
                    <a href="/jobssearch?key=助理">助理</a>
                    <a href="/jobssearch?key=行政">行政</a>
                    <a href="/jobssearch?key=出纳">出纳</a>
                    <a href="/jobssearch?key=工程">工程</a>
                    <a href="/jobssearch?key=营销">营销</a>
                    <a href="/jobssearch?key=客服">客服</a>
                    <a href="/jobssearch?key=计算机">计算机</a>
              </el-col>
            </el-row>
            <!--------------------------->
        </el-col>
    </el-row>
</div>
<!--hendebanner---->

<!--list---->
<div class="kmzp_content">
  <el-row :gutter="10" class="contentbox">
    <el-col :span="24">
      <h3 class="left_title1"><el-icon><Menu /></el-icon>岗位类别</h3>
      <div  class="contentboxleft">
        <el-row :gutter="20">
            <el-col :span="24" style="padding-top: 8px;" v-for="item in categorylistroot1" :key="item.categoryid">
                <div class="categorylista">
                  <el-link :underline="false" :href="'jobssearch?cid='+item.categoryid" target="_blank" class="title1" style="font-weight: bold;font-size: 16px;">{{item.categoryname}}</el-link>
                </div>
                <div style="padding: 20px; background-color: #ffffff;">
                  <el-row :gutter="20">
                  <el-col :span="4" style="padding-top: 8px;" v-for="itemz in getcategoryzzz(item.categoryid)" :key="itemz.categoryid">
                      <div class="categorylista">
                        <el-link :underline="false" :href="'jobssearch?czid='+itemz.categoryid" target="_blank" class="title1">{{itemz.categoryname}}</el-link>
                      </div>
                  </el-col>
                </el-row>
                </div>
            </el-col>
        </el-row>
      </div>
    </el-col>
    <!-- -------------------------------------- -->
  </el-row>
</div>
<!--list---->

<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:9999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
</template>

<script>

import { getjsons, getuserprofile } from '@/api/user'
import store from '@/store/index'
import token from '@/utils/token'

export default {
  name: 'searchjobsIndex',
  components: {},
  props: {},
  data () {
    return {
      users: { usersid: 0 },
      loading: false,
      boxheight: 518,
      drawer: 'none',
      iframeSrc: '',
      searchkey: '',
      activeName: 'first',
      statusvalue: '1',
      total: 6000,
      pageSize: 12,
      baseurl: store.state.baseUrl,
      categorylist1: [],
      categorylistroot1: [],
      categorylistz1: []
    }
  },
  computed: {
  },
  watch: {},
  created () {
    this.$parent.$parent.hendersetshow(true)
    this.$parent.$parent.footsetshow(true)
    this.getusers()
    this.getcategorys()
  },
  mounted () {},
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.users = res.data.resultbody
          if (this.users.usersstatus > 0) {
            token.settoken('utoken', '')
            this.users = { usersid: 0 }
          }
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    getcategorys () {
      this.categorylist = []
      this.categorylistz1 = []
      this.categorylistroot1 = []

      getjsons('category', 1).then(res => {
        if (res.data != null) {
          for (let i = 0; i < res.data.length; i++) {
            this.categorylist1.push(res.data[i])
            if (res.data[i].categoryroot === 0) {
              this.categorylistroot1.push(res.data[i])
            }
            if (Number(res.data[i].categoryroot) === Number(this.cids) && Number(this.cids) > 0) {
              this.categorylistz1.push(res.data[i])
            }
            if (Number(res.data[i].categoryid) === Number(this.cids)) {
              this.ckeys = res.data[i].categoryname
            }
            if (Number(res.data[i].categoryid) === Number(this.czids)) {
              this.czkeys = res.data[i].categoryname
            }
          }
        }
      })
    },
    getcategoryzzz (id) {
      const categoryzzz = []
      for (let i = 0; i < this.categorylist1.length; i++) {
        if (id === this.categorylist1[i].categoryroot) {
          categoryzzz.push(this.categorylist1[i])
        }
      }
      return categoryzzz
    },
    searchsend () {
      if (this.searchkey.length === 0) {
        alert('请输入搜索关键词！')
      } else {
        top.location.href = '/jobssearch?key=' + this.searchkey
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
}
.bg-purple {
background-color: #ffffff;
}
.bg-purple-light {
  background-color: bisque;
}
.count_a {
  text-align: center;
  line-height: 22px;
  .el-card{
  color: #909399;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px;
  border-radius: 0px;
  margin-bottom: 6px;
  margin-right: 5px;
  .countstyle {
    font-size: 16px;
    color: #000000;
  }
  }}
.rightcontainer {
  border-left-width:1px;
  border-left-style: solid;
  border-radius: 0px;
  border-left-color:  #dddfe5;

  .rightyx{
    font-size: 13px;
    .el-tag{margin-bottom: 5px; margin-right: 3px;}
  }
}

.listbox
{
  font-size: 14px;line-height:22px;
  .idbox{margin-right: 12px;}
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
.el-link{font-size: 14px;font-weight: bold; color: #333333;}
.el-link2{font-size: 14px;font-weight:normal;color:#333333;}
.el-divider {
    margin-top:8px;
    margin-bottom: 12px;
  }
  .xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
}
</style>
