<template>
<!--list---->
<div class="kmzp_content">
  <el-row :gutter="10" class="contentbox">
    <el-col :span="8">
      <div :style="'min-height:' + (listheight+200) + 'px;'">
      <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>最新信息</span>
      </div>
    </template>
    <div v-for="item in articleslist.resultlist" :key="item.articleid">
      <a :href="'/articles?id='+item.articleid" class="articlelist">{{ item.articletitle }}</a>
    </div>
  </el-card>
      </div>
    </el-col>
    <el-col :span="16">
      <div  class="contentboxleft" :style="'padding:32px;min-height:' + (listheight+200) + 'px;'" v-if="articles.articleid>0">
       <h3>{{ articles.articletitle }}</h3>
       <el-divider />
       <div style="text-align: right;font-size: 12px;margin-top: 2px;margin-bottom: 20px;">{{ String(articles.articledate).replace(/T/g, " ").replace(/Z/g, "") }}</div>
       <div style="font-size: 14px;text-align: left;line-height: 25px;" v-html="String(articles.articlecontent).replace(/\n/g, '<br />')"></div>
      </div>
      <div class="contentboxleft" v-if="articles.articleid==0" :style="'padding: 80px;min-height:' + listheight + 'px;text-align: center;color:#888888;'"><img src="/images/404.png" /><br />很抱歉这条数据不在了。。</div>
    </el-col>
    <!-- -------------------------------------- -->
  </el-row>
</div>
<!--list---->
</template>

<script>

import { articleget, articlesget } from '@/api/user'
export default {
  name: 'articlesIndex',
  components: {
  },
  props: {},
  data () {
    return {
      id: 0,
      articleslist: [],
      articles: {},
      listheight: 500
    }
  },
  computed: {},
  watch: {},
  created () {
    this.listheight = document.documentElement.clientHeight - 518
    this.$parent.$parent.hendersetshow(true)
    this.$parent.$parent.footsetshow(true)
    if (this.$route.query.id !== undefined) {
      this.id = this.$route.query.id
    }
    this.articles.articleid = this.id
    const listwidth = document.documentElement.clientWidth
    if (listwidth < 1000) {
      this.$router.push('/articlesm?id=' + this.id)
    }
    this.getarticle()
  },
  mounted () {},
  methods: {
    getarticle () {
      if (this.id > 0) {
        articleget(this.id).then(res => {
          if (res.data.status === 200) {
            this.articles = res.data.resultbody
          } else {
            this.articles.articleid = 0
          }
          this.getarticles()
        })
      }
    },
    getarticles () {
      articlesget(this.articles.articlecategory, 12).then(res => {
        if (res.data.status === 200) {
          this.articleslist = res.data.resultbody
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
